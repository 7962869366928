export const MIN_WIDTH = 324
export const MIN_HEIGHT = 191
export const DEF_WIN_WIDTH = 774
export const DEF_WIN_HEIGHT = 686
export const FILE_TYPE_DESC = [
  "image",
  "PDF Document",
  "Word Document",
  "Excel Document",
  "PPT Document",
  "Google Word Document",
  "Google PPT Document",
  "Google Excel Document",
]
