import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import muralIcon from "../../images/svgs/mural-icon.svg"

import { Modal } from "react-bootstrap"
import Style from "./muralwarning.module.scss"
import refreshToken from "./refreshToken"

const MuralAuthWarning = (props) => {
  const authenticateUserOnMural = () => {
    if (props?.defaultOpenAfterRedirect) {
      localStorage.setItem(props.defaultOpenAfterRedirect, true)
    }
    refreshToken()
  }
  return (
    <Modal show={props.showMuralAuthWarning} centered>
      <Modal.Body className={`text-center pt-0 ${Style.mural_warning_modal_body}`}>
        <div className={`${Style.mural_warning_modal_body_content}`}>
          <img src={muralIcon} alt="Mural Icon" width="45" height="45" />
          <p className={`${Style.mural_warning_modal_body_text}`}>Need to authenticate from mural to take data from mural</p>
          <div className="mt-2">
            <Button
              variant={`none`}
              className={`px-3 py-1 ${Style.mural_warning_modal_body_button} mr-3`}
              onClick={() => {
                props.setShowMuralAuthWarning(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant={`none`}
              className={`px-3 py-1 noHover ${Style.mural_warning_modal_body_button} ${Style.mural_warning_modal_body_text}`}
              onClick={() => {
                authenticateUserOnMural()
              }}
            >
              Login with your credentials
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MuralAuthWarning
