import video from "../../images/svgs/videonew.svg"
import link from "../../images/svgs/linknew.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blognew.svg"
import quiz from "../../images/svgs/quiznew.svg"
import pdf from "../../images/svgs/pdf.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import MatchTheFollowing from "../../images/svgs/whiteMatchingIcon.svg"
import { faImage } from "@fortawesome/free-solid-svg-icons"

const getImageToBeRendered = (item) => {
  let imgObj = turientimage,
    itemName = "",
    typeId = 2,
    imgType = "File"

  itemName = item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name
  let typeOfFile = item?.resource_data?.file_type || 0
  if (item.resource_data?.avatar) {
    imgObj = item.resource_data.avatar
  }
  if (item.resource_type === "video") {
    imgObj = video
    imgType = "Video"
  } else if (item.resource_type === "blog") {
    imgObj = blog
    imgType = "Blog"
  } else if (item.resource_type === "link") {
    imgObj = link
    imgType = "Link"
  } else if (item.resource_type === "matchthefollowing") {
    imgObj = MatchTheFollowing
    imgType = "Match the following"
  } else if (item.resource_type === "quiz") {
    imgObj = quiz
    imgType = "Quiz"
    let dataType = item?.resource_data?.data_type || 0
    if (dataType === "1") {
      imgObj = poll
      imgType = "Poll"
    } else if (dataType === "2") {
      imgObj = popQuiz
      imgType = "Pop Quiz"
    }
  } else if (item.resource_type === "turientimage") {
    imgObj = faImage
    typeId = 1
    imgType = "Image"

    if (typeOfFile === "2" || typeOfFile === "5") {
      imgObj = doc
      typeId = 2
      imgType = "Document"
    } else if (typeOfFile === "3" || typeOfFile === "7") {
      imgObj = excel
      typeId = 2
      imgType = "SpreadSheet"
    } else if (typeOfFile === "4" || typeOfFile === "6") {
      imgObj = ppt
      typeId = 2
      imgType = "Presentation"
    } else if (typeOfFile === "1") {
      imgObj = pdf
      typeId = 2
      imgType = "PDF Document"
    } else if (typeOfFile === "0") {
      imgObj = item.resource_data.avatar
      typeId = 1
      imgType = "Image"
    }
  } else if (item.resource_type === "checklist") {
    imgObj = checklist
    imgType = "Checklist"
  } else if (item.resource_type === "casestudy") {
    imgObj = casestudy
    imgType = "Case Study"
  }
  return { typeId, imgObj, imgType }
}

export default getImageToBeRendered
