import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import Loader from "../../custom/Loader"
import Style from "./style.module.scss"
import { createAssessmentChat } from "./_api"
import CustomSunEditor from "../../common/CustomSunEditor"
import { LazyLoadImage } from "react-lazy-load-image-component"
import CheckWhite from "/src/images/svgs/check-white.svg"
import ClosePlain from "/src/images/svgs/close_plain.svg"
import ResourcesIcon from "/src/images/svgs/classroom/resource-nav-icon.svg"
import { useContext } from "react"
import { SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE } from "../../../stores/sidebar_store/SidebarActions"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { Droppable } from "react-beautiful-dnd"
import UploadResource from "../../upload-resource"
import { SET_OPENED_OVERLAY_SECTION } from "../../../stores/classroom_store/ClassroomActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { useEffect } from "react"
import ResourceListItem from "../../custom/ResourceListItem"
import { resourceTypeList } from "../../Sidebar/constants"
import { getRequest } from "../../../services/rest_service"

const CommentBox = ({ student_assessment_mural, addCommentExpanded, setAddCommentExpanded }) => {
  const [userComment, setUserComment] = useState("")
  const [loading, setLoading] = useState(false)
  const [highlight, setHighlight] = useState("unhighlight")
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)
  const [classroomStore, dispatchClassroomStore] = useContext(ClassroomContext)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [userResources, setUserResources] = useState([])
  const [files, setFiles] = useState([])
  const getListStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    outline: "none",
    // padding: grid,
    margin: `0`,
    borderRadius: "20px",
    textAlign: "center",
    color: "#fff",

    // change background colour if dragging
    background: "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const createNewComment = async () => {
    setLoading(true)
    const { success } = await createAssessmentChat(userComment, student_assessment_mural, userResources)
    if (success) {
      setUserComment("")
      setUserResources([])
      setAddCommentExpanded(false)
    }
    setLoading(false)
  }
  const onDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
  }

  const handleDrop = (e) => {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 2) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      }
    }
  }

  const removeResource = (id) => {
    let filteredResource = userResources.filter((resource) => resource.resource_id != id)
    setUserResources([...filteredResource])
  }

  const fetchVideos = async (id, itemType) => {
    let response = await getRequest(`/videos/${id}/`)
    if (response.success) {
      setUserResources([
        ...userResources,
        { resource_content_type: resourceTypeList[itemType], resource_id: id, resource_type: itemType, resource_data: response.data },
      ])
    } else {
      console.error(response.error)
    }
  }
  const fetchImages = async (id, itemType) => {
    let response = await getRequest(`/images/${id}/`)
    if (response.success) {
      setUserResources([
        ...userResources,
        { resource_content_type: resourceTypeList[itemType], resource_id: id, resource_type: itemType, resource_data: response.data },
      ])
    } else {
      console.error(response.error)
    }
  }

  useEffect(() => {
    if (sidebarStore.draggedItem) {
      let item = sidebarStore.draggedItem
      if (item.type === "resourceDraggable") {
        if (item.destination && item.source) {
          if (item.destination.droppableId !== item.source.droppableId) {
            let itemType = item.source.droppableId.split("_")[1]
            let resId = item.draggableId.split("-")[0]
            // Check if the item with the same resource_id already exists in userResources
            if (!userResources.some((resource) => resource.resource_id === resId)) {
              if (resourceTypeList[itemType] === "turientimage") {
                fetchImages(resId, itemType)
              } else {
                fetchVideos(resId, itemType)
              }
            }
          }
        }
      }
    }
  }, [sidebarStore.draggedItem])
  return (
    <React.Fragment>
      {!addCommentExpanded && (
        <React.Fragment>
          <div className="p-3">
            <span className={`pointer ${Style.comment_text}`} onClick={() => setAddCommentExpanded(true)}>
              <u>+ Add Comment</u>
            </span>
          </div>
        </React.Fragment>
      )}
      {addCommentExpanded && (
        <Droppable droppableId={`resource_notes`} type={`resourceDraggable`} isDropDisabled={false}>
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnter={onDragEnter}
              onDragOver={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
              className={`bg-transparent px-2 notes border_on_hover`}
              ref={provided.innerRef}
              style={{ ...getListStyle(snapshot.isDraggingOver), borderRadius: "5px" }}
            >
              <div className="d-flex row mx-2">
                <CustomSunEditor content={userComment} onChange={setUserComment} placeHolder={"Add a comment"} minHeight={"180px"} />
                {userResources.length > 0 &&
                  userResources.map((item, index) => {
                    return <ResourceListItem key={`res_item_${index}`} item={item} index={index} removeResource={removeResource} />
                  })}
                <div className="w-100 white-text px-2 py-2">
                  {loading && <Loader />}
                  {!loading && (
                    <div className="d-flex justify-content-between">
                      <div>
                        <Button
                          className={`bg-586886 white-text outline-none ${Style.new_comment_check_button}`}
                          size="sm"
                          disabled={!userComment && !userResources.length}
                          onClick={createNewComment}
                        >
                          <LazyLoadImage src={CheckWhite} />
                        </Button>
                        <Button
                          className={`bg-586886 white-text outline-none ${Style.new_comment_cancel_button}`}
                          size="sm"
                          onClick={() => {
                            setAddCommentExpanded(false)
                            setUserResources([])
                          }}
                        >
                          <LazyLoadImage src={ClosePlain} />
                        </Button>
                      </div>
                      <div>
                        <Button
                          className={`bg-transparent border_on_hover`}
                          size="sm"
                          onClick={() => {
                            dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "Resource" })
                            dispatchSidebarStore({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Videos" })
                            dispatchClassroomStore({ type: SET_OPENED_OVERLAY_SECTION, payload: "MuralAssessment" })
                          }}
                        >
                          <LazyLoadImage height={20} src={ResourcesIcon} />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Droppable>
      )}

      <UploadResource show={showUploadModal} Style={{ zIndex: "2000" }} />
    </React.Fragment>
  )
}

CommentBox.propTypes = {
  student_assessment_mural: PropTypes.number.isRequired,
  addCommentExpanded: PropTypes.bool.isRequired,
  setAddCommentExpanded: PropTypes.func.isRequired,
}

export default CommentBox
