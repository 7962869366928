import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import MinimizeArrow from "/src/images/svgs/minimize_arrow.svg"
import Loader from "../../custom/Loader"
import ScrollUp from "/src/images/svgs/scroll_up.svg"
import { useAssessmentMural } from "../../../stores/assessment_mural_store/AssessmentMuralStore"
import { setMuralChatWindowExpanded } from "../../../stores/assessment_mural_store/AssessmentMuralActions"
import Btn from "../../custom/Button"
const ChatWindowHeader = ({ loading, totalCount, items, setScrollUp, fetchNext }) => {
  const fetchNextComments = () => {
    setScrollUp(true)
    fetchNext()
  }
  const [state, muralDispatch] = useAssessmentMural()
  useEffect(() => {
    if (items.length === 0 && remainingItems > 0) {
      fetchNextComments()
    }
  }, [items.length])

  const remainingItems = totalCount - items.length
  return (
    <React.Fragment>
      {loading && (
        <div className="w-100 white-text px-2">
          <Loader />
        </div>
      )}
      {remainingItems > 0 && (
        <div className="position-fixed left-10px top-10px z-index-1 ">
          <div className={Style.fetch_more_reply_div} onClick={fetchNextComments}>
            <LazyLoadImage src={ScrollUp} /> +{remainingItems} more replies
          </div>
        </div>
      )}
      <Btn
        className={`${Style.minimize_button} z-index-1 d-flex align-items-center justify-content-center`}
        size="sm"
        onClick={() => muralDispatch(setMuralChatWindowExpanded(false))}
      >
        <LazyLoadImage src={MinimizeArrow} />
      </Btn>
    </React.Fragment>
  )
}

ChatWindowHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setScrollUp: PropTypes.func.isRequired,
  fetchNext: PropTypes.func.isRequired,
}

export default ChatWindowHeader
