import { deleteRequest, postRequest } from "../../../services/rest_service"

export const createAssessmentChat = async (userComment, student_assessment_mural, userResource) =>
  postRequest("/student-assessment-mural/chat/", {
    message: userComment,
    student_assessment_mural: student_assessment_mural,
    resource_data: userResource.length ? JSON.stringify(userResource) : null,
  })

export const deleteMuralAssessmentChat = async (assessment_chat_id) => deleteRequest(`/student-assessment-mural/chat/${assessment_chat_id}/`)
