import React from "react"
import Modal from "react-bootstrap/Modal"
import StylesB from "./classroom-resource-section.module.scss"
import Btn from "../custom/Button"
import Image from "../custom/Image"
import Style from "./classroom-participants-section/classroomParticipantsSection.module.scss"
import { getCountOfBadges } from "../utilities/getCountOfBadges"

import UserCircle from "../../images/svgs/user-circle.svg"

const DeleteParticipant = (props) => {
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => {
          props.setShowModal(false)
        }}
        dialogClassName={`${StylesB.delete_participant_modal}`}
        size="sm"
        contentClassName={`${StylesB.modal_content}`}
      >
        <Modal.Body className={`${StylesB.delete_participant_modal_body} text-center`}>
          <div className={`${StylesB.delete_participant_modal_div} d-flex m-1 align-items-center`}>
            <div className="mt-1">
              <Image className={`mx-2 ${Style.user_icon}`} alt={`user-circle`} src={UserCircle} />
            </div>
            <div className={`${Style.name_container_delete_participants}`}>
              <p className={`${StylesB.participant_name} mb-0 `}>{props.participantData?.owner__first_name}</p>
            </div>
            <div className=" d-inline-flex align-items-center">
              <div className={`d-inline-flex align-items-center position-relative ml-2 ${Style.badge_container_delete_participants}`}>
                {props.participantData?.badges.map((p_badge, indx) => {
                  if (indx <= 2 && p_badge.count > 0) {
                    return (
                      <Image
                        key={indx.toString()}
                        src={p_badge.icon}
                        alt={`badges`}
                        className={`my-auto pointer ${Style.badge_icon_delete_participants}`}
                        style={{
                          position: indx <= 0 ? "relative" : "absolute",
                          right: `${indx * 20}px`,
                        }}
                      />
                    )
                  }
                })}
              </div>
              {getCountOfBadges(props.participantData?.badges) > 0 ? (
                <p className={`mb-0 ml-auto pl-1 ${Style.count_of_badges}`}>+{getCountOfBadges(props.participantData?.badges)}</p>
              ) : null}
            </div>
          </div>
          <p className={`mx-2 mt-2 fs-12px ${Style.confirm_delete_participants}`}> Are you sure you want to delete the participant?</p>
          <p className={`mx-3 fs-11px ${Style.info_delete_participants}`}>Deleting a participant would remove them from the entire class</p>
          <div className="d-flex justify-content-center mb-2">
            <Btn
              className={`${StylesB.delete_button} mb-1`}
              onClick={() => {
                props.setShowModal(false)
                props.deleteParticipant(props.participantData.owner__id)
              }}
            >
              Yes, Delete
            </Btn>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DeleteParticipant
