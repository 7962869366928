import React from "react"
import PropTypes from "prop-types"
import Image from "../Image"
import Style from "./style.module.scss"

const AvatarCircle = (props) => {
  const backgroundColors = ["#cd5c5c", "#808000", "#20b2aa", "#ff00ff", "#00fa9a", "#0000FF", "#800080", "#008080", "#008000", "#808080"]
  const index = props.index ? props.index % 12 : 0
  const fontSize = parseInt(props.size) / 2

  return (
    <div className={`d-flex font-weight-bold position-relative ${props.className}`} key={"avc-" + props?.index}>
      {props.avatar ? (
        <div
          style={{
            height: props.size,
            width: props.size,
          }}
          className={`d-flex align-items-center justify-content-center `}
        >
          <Image style={{ height: props.size, width: props.size }} className={`circle`} src={props.avatar} />
          {props.online && <div className={Style.online_green_badge} />}
        </div>
      ) : (
        <div
          style={{
            height: props.size,
            width: props.size,
            backgroundColor: props?.backgroundColor ? props.backgroundColor : backgroundColors[index % backgroundColors.length],
            color: props.color,
          }}
          className={`d-flex circle align-items-center justify-content-center text-uppercase `}
        >
          <div style={{ fontSize: fontSize }}>{props.name?.[0]}</div>
          {props.online && <div className={Style.online_green_badge} />}
        </div>
      )}
    </div>
  )
}

AvatarCircle.propTypes = {
  index: PropTypes.number, //Index
  size: PropTypes.string, //Size of the circle
  backgroundColor: PropTypes.string, //Background Color of Circle
  color: PropTypes.string, //Text Color within circle
  name: PropTypes.string, //Name from which first character is taken to display the circle
  online: PropTypes.bool, //If the user is online or not
  avatar: PropTypes.string, //If the User has uploaded their avatar their url
  className: PropTypes.string, //If extra classes need to apply
}

AvatarCircle.defaultProps = {
  index: 0,
  size: "35px",
  color: "#E0E2E5",
  name: "U",
  online: false,
  avatar: "",
  className: "",
}

export default AvatarCircle
