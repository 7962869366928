import React, { useEffect, useState, useContext, useCallback } from "react"
import Col from "react-bootstrap/Col"
import _ from "lodash"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_NOTIFICATION_DATA } from "../../../stores/global_store/GlobalActions"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"
import FormControl from "react-bootstrap/FormControl"
import Loader from "../../custom/Loader"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Image from "../../custom/Image"
import DeleteParticipant from "../_delete-participant"
import { postRequest, getRequest } from "../../../services/rest_service"
import Btn from "../../custom/Button"
import FilterByBadge from "./filterByBadge"
import GiveABadge from "./giveABadge"
import ParticipantsDetailsOption from "./participantsDetailsOption"
import EngagementScoreDropdown from "./engagementScoreDropdown"
import UploadParticipantsModal from "../../custom/UploadParticipantsModal"
import ParticipantDetail from "../participant-detail"
import SeeMore from "../../custom/see_more"
import AvatarCircle from "../../custom/AvatarCircle"
import Style from "./classroomParticipantsSection.module.scss"
import SearchIcon from "../../../images/svgs/search.svg"
import UploadIcon from "../../../images/svgs/feather-upload-white.svg"
import MuralCanvas from "../../mural-canvas"
import { HasCreatePermission } from "../../../utils/PermissionDataAccess"
import { STUDENT } from "../../../constants/permissionsConstant"
import { AssessmentMuralContext } from "../../../stores/assessment_mural_store/AssessmentMuralStore"
import { setShowMuralCanvas, setShowParticipantDetailsModal } from "../../../stores/assessment_mural_store/AssessmentMuralActions"
import InsightsReportsStore from "../../../stores/insights_reports/InsightsReportsStore"

const LAST_ONLINE_TIME_DIFFERENCE = 80 * 1000 // 80 sec
const OFFLINE = 1

/**
 * Component for showing list of participants.
 * @returns {React.ReactElement} ClassroomParticipantsSection component.
 */

const ClassroomParticipantsSection = (props) => {
  const selectSection = ["All Participants", "Leaderboard"]

  const [currentSelectedSection, setCurrentSelectedSection] = useState(selectSection[0])
  const [participantsList, setParticipantsList] = useState([])
  const [next_participant_url, setNextParticipantUrl] = useState(null)
  const [participantsDataFetched, setParticipantsDataFetched] = useState(false)
  const [allBadges, setAllBadges] = useState([])
  const [company, setCompany] = useState({})
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false)
  const [showDeleteParticipantModal, setShowDeleteParticipantModal] = useState(false)
  const [badgeFilter, setBadgeFilter] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)
  const [globalState, dispatchNotification] = useContext(GlobalContext)
  const [state, assessmentMuralDispatch] = useContext(AssessmentMuralContext)

  const sortUsersByBadges = (users) => {
    users.sort((a, b) => {
      if (a.badge_count !== b.badge_count) {
        return b.badge_count - a.badge_count
      }

      return a.owner__first_name.localeCompare(b.owner__first_name)
    })

    return users
  }

  const updateParticipateList = () => {
    setParticipantsList((prevParticipantsList) => {
      const userIndex = prevParticipantsList.findIndex((user) => user.owner__id === trainingWSval?.earner?.id)
      const badgesIndex = userIndex >= 0 ? prevParticipantsList[userIndex]?.badges.findIndex((badge) => badge.id === trainingWSval?.badge_id) : -1
      let participantsCopy = [...prevParticipantsList]
      if (userIndex >= 0) {
        if (trainingWSval.assigned) {
          if (badgesIndex === -1) {
            const updatedValue = {
              ...prevParticipantsList[userIndex],
              badges: [
                ...prevParticipantsList[userIndex].badges,
                { count: 1, icon: trainingWSval.badge_icon, id: trainingWSval?.badge_id, name: trainingWSval.badge_name },
              ],
              badge_count: prevParticipantsList[userIndex]?.badge_count + 1,
            }
            participantsCopy[userIndex] = updatedValue
          } else {
            let badges_copy = [...prevParticipantsList[userIndex].badges]
            badges_copy[badgesIndex] = { ...badges_copy[badgesIndex], count: badges_copy[badgesIndex].count + 1 }
            const updatedValue = { ...prevParticipantsList[userIndex], badges: badges_copy, badge_count: prevParticipantsList[userIndex]?.badge_count + 1 }
            participantsCopy[userIndex] = updatedValue
          }
        } else if (badgesIndex >= 0) {
          let badges_copy = [...prevParticipantsList[userIndex].badges]
          badges_copy[badgesIndex] = { ...badges_copy[badgesIndex], count: badges_copy[badgesIndex].count - 1 }
          const updatedValue = { ...prevParticipantsList[userIndex], badges: badges_copy, badge_count: prevParticipantsList[userIndex]?.badge_count - 1 }
          participantsCopy[userIndex] = updatedValue
        }
      }

      return currentSelectedSection === selectSection[0] ? participantsCopy : sortUsersByBadges(participantsCopy)
    })
  }

  useEffect(() => {
    if (company.id) {
      fetchTrainingBadges()
    }
  }, [company])

  useEffect(() => {
    if (trainingWSval && trainingWSval?.event_type == "show_online") {
      updateOnlineParticipants(trainingWSval.owner, trainingWSval.last_online, trainingWSval.activity_type)
    }
    if (trainingWSval && trainingWSval?.event_type === "user_badges" && trainingWSval.component.includes("ClassroomParticipantsComponent")) {
      updateParticipateList()
    }
  }, [trainingWSval])

  useEffect(() => {
    fetchCompanies()
  }, [])

  useEffect(() => {
    if (state.activeAssessmentData.participant_id && state.activeAssessmentData.mural_assessment_id && participantsList?.length) {
      assessmentMuralDispatch(setShowParticipantDetailsModal(true))
      let selectedParticipant = participantsList.find((data) => data.owner__id == state.activeAssessmentData.participant_id)
      props.setParticipantData(selectedParticipant)
      assessmentMuralDispatch(setShowMuralCanvas(true))
    }
  }, [participantsList, state.activeAssessmentData.participant_id])
  useEffect(() => {
    fetchParticipants("", true)
  }, [currentSelectedSection, badgeFilter])

  const fetchCompanies = async () => {
    const response = await getRequest(`/fetch-company/`)
    if (response.success) {
      setCompany(response.data[0])
    }
  }

  const delayedfetchParticipants = useCallback(
    _.debounce((searchValue) => fetchParticipants(searchValue), 1000),
    []
  )

  useEffect(() => {
    if (state.muralAssessment) {
      assessmentMuralDispatch(setShowMuralCanvas(true))
    }
  }, [state.muralAssessment])

  const fetchParticipants = async (searchValue = "", showLoader = false) => {
    try {
      showLoader && setIsLoading(true)
      const response = await getRequest(
        `/training-participants/${classroomState.training_id}/?badge_data=true&paginate_by=50&search=${searchValue}&badge=${badgeFilter}&ordering=${
          currentSelectedSection !== selectSection[0] ? "badge" : "owner__first_name"
        }`
      )
      if (response.success) {
        setParticipantsList(response.data.results)
        setParticipantsDataFetched(true)
        if (response.data.next) {
          setNextParticipantUrl(response.data.next)
        }
        showLoader && setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteParticipant = async (student_id) => {
    try {
      const { data, status, success } = await postRequest(`/trainings/${classroomState.training_id}/students/${student_id}/remove/`)
      if (success) {
        let updatedParticipantsList = [...participantsList]
        let index = updatedParticipantsList.findIndex((student) => student.owner__id === student_id)
        updatedParticipantsList.splice(index, 1)
        setParticipantsList(updatedParticipantsList)
        dispatchNotification({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Participant deleted successfully" } })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchNextParticipants = (response) => {
    let updatedParticipantsList = [...participantsList, ...response.data.results]
    setParticipantsList(updatedParticipantsList)
    setNextParticipantUrl(response.data.next)
  }

  const fetchTrainingBadges = async () => {
    try {
      const response = await getRequest(`/badges/?company=${company.id}`)
      if (response.success) {
        setAllBadges(response.data.results)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const showAddContentButton = () => {
    return (
      <div>
        <Btn
          variant="none"
          className={`align-items-center d-flex text-white border_on_hover ${Style.upload_participants_button}`}
          onClick={() => {
            setShowAddStudentsModal(true)
          }}
        >
          <Image src={UploadIcon} alt={`upload button`} className={`my-auto ${Style.upload_participants_icon}`} />
          <p className="mb-0 fs-14px">Upload Participants</p>
        </Btn>
      </div>
    )
  }

  const updateOnlineParticipants = async (owner, last_online, activity_type) => {
    try {
      setParticipantsList((list) => {
        let allStudents = [...list]
        let studentIndex = allStudents.findIndex((s) => s.owner__id == owner)
        if (allStudents[studentIndex]) {
          allStudents[studentIndex]["last_online"] = last_online
          allStudents[studentIndex]["activity_type"] = activity_type
        }
        return allStudents
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getParticipants = (type) => {
    const participants = [...participantsList]
    const sortedParticipantsByOnlineStatus = participants.sort((a, b) => {
      let aIsOnline = false
      if (Date.now() - new Date(a?.last_online) <= LAST_ONLINE_TIME_DIFFERENCE && a?.activity_type !== OFFLINE) {
        aIsOnline = true
      }
      let bIsOnline = false
      if (Date.now() - new Date(b?.last_online) <= LAST_ONLINE_TIME_DIFFERENCE && b?.activity_type !== OFFLINE) {
        bIsOnline = true
      }
      // Put all last_online null values at last
      if (aIsOnline && bIsOnline) {
        return 0
      } else if (aIsOnline) {
        return -1
      } else if (bIsOnline) {
        return 1
      } else {
        return 0
      }
    })
    {
      return isLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div>
          <FilterByBadge allBadges={allBadges} setBadgeFilter={setBadgeFilter} badgeFilter={badgeFilter} />
          {participantsList.length > 0 ? (
            (currentSelectedSection !== selectSection[0] ? participantsList : sortedParticipantsByOnlineStatus).map((value, participant_indx) => {
              let isOnline = false
              if (Date.now() - new Date(value?.last_online) <= LAST_ONLINE_TIME_DIFFERENCE && value?.activity_type !== OFFLINE) {
                isOnline = true
              }
              return (
                <div
                  key={participant_indx.toString()}
                  className={`d-inline-flex px-2 py-2 mb-2 align-items-center w-100 bg-303C54 ${Style.participants_detail_container}`}
                >
                  <div className="d-inline-flex align-items-center w-100">
                    {type === "leaderboard" && <p className={`mb-0 pr-1 ${Style.leaderboard_rank} fs-18px`}>{participant_indx + 1}</p>}

                    <div
                      className="position-relative pointer"
                      onClick={() => {
                        assessmentMuralDispatch(setShowParticipantDetailsModal(true))
                        props.setParticipantData(value)
                      }}
                    >
                      <AvatarCircle name={value?.owner__first_name} size={`30px`} avatar={value?.owner__profile_pic} online={isOnline} className={`mr-2`} />
                    </div>
                    <div className={`${Style.participant_badge_container}`}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-${value.id}"`}>
                            {value.owner__first_name}
                          </Tooltip>
                        }
                      >
                        <div
                          className={`d-flex flex-column ${Style.participant_name} 
                        `}
                        >
                          <p
                            className={` fs-14px pointer pb-1 mb-0`}
                            onClick={() => {
                              assessmentMuralDispatch(setShowParticipantDetailsModal(true))
                              props.setParticipantData(value)
                            }}
                          >
                            {value.owner__first_name}
                          </p>
                        </div>
                      </OverlayTrigger>
                      <EngagementScoreDropdown value={value} />
                    </div>

                    <div className={`d-inline-flex align-items-center ml-auto `}>
                      <InsightsReportsStore>
                        {value && <GiveABadge participantsList={participantsList} setParticipantsList={setParticipantsList} value={value} />}
                      </InsightsReportsStore>
                      <ParticipantsDetailsOption
                        value={value}
                        setShowDeleteParticipantModal={setShowDeleteParticipantModal}
                        setParticipantData={props.setParticipantData}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p className={`w-100 text-center fs-16px`}>No participants found</p>
          )}
        </div>
      )
    }
  }

  const showParticipantsSection = () => {
    if (participantsDataFetched) {
      return (
        <div className={`d-inline-flex w-100 px-2`}>
          <div className={`w-100`}>
            {currentSelectedSection === selectSection[0] ? getParticipants() : getParticipants("leaderboard")}
            {next_participant_url && <SeeMore url={next_participant_url} handleData={fetchNextParticipants} />}
          </div>
        </div>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Loader />
        </Col>
      )
    }
  }

  const showSelectionSection = () => {
    return selectSection.map((section, index) => {
      return (
        <div
          key={index.toString()}
          className={`pointer ${currentSelectedSection === section ? Style.resource_nav_item_selected : Style.resource_nav_item}`}
          onClick={() => {
            setCurrentSelectedSection(section)
          }}
        >
          <p>{section}</p>
        </div>
      )
    })
  }

  return (
    <div className={`d-flex flex-column h-100`}>
      <div className="px-0 flex-grow-1 overflow-auto">
        <div className="p-3">
          <div className={`col-12 d-inline-flex bg-303C54 ${Style.search_section}`}>
            <Image src={SearchIcon} alt={`search button`} className={`my-auto ${Style.search_icon}`} />
            <FormControl
              autoFocus
              className={`border-0 px-2 form-control fs-16px bg-303C54 ${Style.search_form_control}`}
              placeholder={`Search participants`}
              onChange={(e) => {
                delayedfetchParticipants(e.target.value)
              }}
            />
          </div>
        </div>
        <div className={`${Style.resource_nav_section} bg-303C54 justify-content-around`}>{showSelectionSection()}</div>
        <div className={`${Style.resource_item_section} px-0`}>{showParticipantsSection()}</div>
      </div>
      {HasCreatePermission(STUDENT) && showAddContentButton()}
      <DeleteParticipant
        participantData={props?.participantData}
        showModal={showDeleteParticipantModal}
        setShowModal={setShowDeleteParticipantModal}
        deleteParticipant={(student_id) => {
          deleteParticipant(student_id)
        }}
      />
      <UploadParticipantsModal
        show={showAddStudentsModal}
        setShow={(arg) => {
          setShowAddStudentsModal(arg)
        }}
        refetchParticipants={() => {
          fetchParticipants()
        }}
        training_id={classroomState.training_id}
      />
      <ParticipantDetail
        participantData={props?.participantData}
        setParticipantData={props.setParticipantData}
        participantsList={participantsList}
        setParticipantsList={setParticipantsList}
      />
    </div>
  )
}

ClassroomParticipantsSection.propTypes = {}
export default ClassroomParticipantsSection
