import React, { useContext } from "react"
import PropTypes from "prop-types"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Dropdown from "react-bootstrap/Dropdown"

import { getResourceIcon } from "../../Sidebar/utils"
import { getResourceDisplayName } from "../../utilities/resourceDisplayName"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_ALL_RESOURCES } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ResourceNameForUrl, ResourceModelName, PublishResourcesUrl } from "./constants"
import Image from "../../custom/Image"
import { postRequest } from "../../../services/rest_service"

import DeleteResource from "../../../images/svgs/delete_resource.svg"
import TriggerActivity from "../../../images/svgs/trigger_resource_white_icon.svg"
import EditIcon from "../../../images/svgs/edit.svg"
import EllipsisWhite from "../../../images/svgs/ellipsis-white.svg"
import checklist from "../../../images/svgs/checklist.svg"
import casestudy from "../../../images/svgs/casestudy.svg"
import WhitePollIcon from "../../../images/svgs/polls-white-icon.svg"
import quiz from "../../../images/svgs/quiz.svg"
import popQuiz from "../../../images/svgs/popquiz.svg"

import StylesB from "../../template-resources/classroom-resource-section.module.scss"
import StylesC from "../../../modularscss/styles.module.scss"

/**
 * Component for a single resource in sidebar content.
 * @param {object} props
 * @param {object} props.item - The resource to display
 * @param {string} props.item.name - Resource file name
 * @param {string} props.item.display_name - Resource display name
 * @param {object} props.item.resource_data - Resource details
 * @returns {React.ReactElement} ResourceItem component.
 */
const ResourceItem = (props) => {
  const [sidebarState, dispatch] = useContext(ClassroomSidebarContext)
  const [classroomState, classroomDispatch] = useContext(ClassroomContext)

  function gotoSingleParticularSection(id, currentSec) {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    if (currentSec === "Polls" || currentSec === "Pop Quizzes") {
      window.open(`/app/${ResourceNameForUrl[currentSec]}/${id}/edit/`)
    } else {
      window.open(`/app/${ResourceNameForUrl[currentSec]}/edit/${id}/`)
    }
  }

  function gotoPublishResource(e, id) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    let publishUrl = PublishResourcesUrl[sidebarState.currentSelectedResourcesType]
    publishUrl = publishUrl.replace("{id}", id)
    window.open(publishUrl)
  }

  const showTooltip = (character_length) => {
    let resource_name = getResourceDisplayName(props.item)
    return resource_name?.length > character_length
  }
  const publishResource = async (resource) => {
    var formData = new FormData()
    formData.append("sequence", 0.0)
    formData.append("resource_type", ResourceModelName[sidebarState.currentSelectedResourcesType])
    formData.append("resource_id", resource.id)
    formData.append("add_to_template", classroomState.template_id)
    let response = await postRequest("/resources/create/", formData)
    if (response.success) {
      let all_resources = { ...sidebarState.allResources }
      let resource_type = sidebarState.currentSelectedResourcesType
      all_resources[resource_type][props.index].resource.push(response.data)
      dispatch({ type: SET_ALL_RESOURCES, payload: all_resources })

      props.myListActivityResource(sidebarState.currentSelectedResourcesType, props.index, props.item?.resource[0])
    }
  }

  function editAnyResource(e, doAction = false) {
    if (!doAction && props.parentComponent === "classroom") return

    let id = props.item.id
    gotoSingleParticularSection(id, sidebarState.currentSelectedResourcesType)
  }

  function showEditButton() {
    let functionName = editAnyResource,
      display = 0
    if (sidebarState.currentSelectedResourcesType === "Polls") {
      if (props.item?.resource?.length > 0 && props.item?.resource[0]?.is_triggered && !props.item?.resource[0]?.end_activity) {
        return null
      } else {
        display = 1
      }
    } else if (sidebarState.currentSelectedResourcesType === "Pop Quizzes" || props.parentComponent === "classroom") {
      display = 1
    }

    if (display) {
      return (
        <div className="d-flex pointer" onClick={(e) => functionName(e, true)} style={{ borderBottom: "1px solid #929bbd" }}>
          <img
            src={EditIcon}
            alt={`resource_edit_${props.item.id.toString()}`}
            className={`my-auto mr-1`}
            style={{ objectFit: `contain`, width: `13px`, height: `13px` }}
          />
          <p>Edit Resource</p>
        </div>
      )
    }
  }

  const showTriggeredResourceButton = () => {
    return (props.item.resource[0]?.id === classroomState.activeActivityData?.current_active_activity?.resource?.id || props.item.resource[0].is_triggered) &&
      !["pop-quiz", "quiz", "checklist", "casestudy", "poll"].includes(sidebarState.currentSelectedResourcesType) ? (
      <div
        className="d-flex pointer"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.myListActivityResource(sidebarState.currentSelectedResourcesType, props.index, props.item.resource[0], true)
        }}
        style={{ borderTop: "1px solid #929bbd" }}
      >
        <img src={TriggerActivity} alt={`resource_edit_delete`} className={`my-auto mr-1`} style={{ objectFit: `contain`, width: `13px`, height: `13px` }} />
        <p>{props.item.resource[0]?.id === classroomState.activeActivityData?.current_active_activity?.resource?.id ? `Show Resource` : `View Results`}</p>
      </div>
    ) : null
  }

  function showEllipsisMenu() {
    if (
      props.parentComponent === "classroom" ||
      sidebarState.currentSelectedResourcesType === "Polls" ||
      sidebarState.currentSelectedResourcesType === "Pop Quizzes"
    ) {
      return (
        <Dropdown className={`${props.parentComponent === "classroom" ? "pb-1" : ""} ${doc}`} style={{ lineHeight: 1 }} onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle
            as="div"
            variant="none"
            id={`resource-option-menu-${props.item.id}`}
            className={`shadow-none p-0 m-0 ${StylesC.no_caret} ${StylesB.image_with_hover} `}
          >
            <img
              src={EllipsisWhite}
              alt={`ellipsis_icon_${props.item.id.toString()}`}
              className={`my-auto pointer object-fit-contain ${StylesB.ellipsis_icon}`}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={`${StylesB.resource_option_menu}`}>
            {showEditButton()}
            {sidebarState.currentNavTab !== "Resource" && props.item.resource?.length > 0 && showTriggeredResourceButton()}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return null
    }
  }

  let imgObj = getResourceIcon(sidebarState.currentSelectedResourcesType, props.item)
  let is_published_resource = ["Quizzes", "MTFs", "Polls", "Pop Quizzes", "Checklists", "Case Studies"].includes(sidebarState.currentSelectedResourcesType)
  let doc = props.parentComponent === "classroom" && sidebarState.currentSelectedResourcesType === "Documents"
  let width = doc ? `w-88` : `w-90`
  if (
    is_published_resource &&
    (props.item?.is_published === false || (props.item?.resource?.length > 0 && props.item.resource[0]?.resource_data?.is_published === false))
  ) {
    width = props.parentComponent === "classroom" ? `w-54` : `w-56`
  }
  let is_live_class = props.parentComponent === "classroom" ? true : false

  let displayName = getResourceDisplayName(props.item)

  return (
    <div
      onClick={(e) => {
        editAnyResource(e, false)
      }}
      className={`w-100 d-flex p-2`}
    >
      <div className={`d-flex align-content-center justify-content-start mr-auto ${width}`}>
        <Image
          src={imgObj}
          alt={`${sidebarState.currentSelectedResourcesType}_${displayName}_${props.item.id}`}
          className={`align-self-center ${StylesB.resource_avatar} mb-0`}
        />
        {showTooltip(width === `w-90` || width === `w-88` ? 35 : 12) ? (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip className="event-tooltip z-10000" id={`tooltip-${displayName}`}>
                {displayName}
              </Tooltip>
            }
          >
            <p className={`text-truncate px-2 fs-12px m-0 pt-1 font-weight-bold`}>{displayName}</p>
          </OverlayTrigger>
        ) : (
          <p className={`text-truncate px-2 fs-12px m-0 pt-1 font-weight-bold`}>{displayName}</p>
        )}
      </div>
      <div className={`d-flex align-content-center pt-1 mr-1 justify-content-end`}>
        {props.item?.is_published === false ? (
          <div
            className={`fs-10px ${is_live_class ? `pt-2px` : ``} ${StylesB.not_published} mr-1`}
            onClick={(e) => gotoPublishResource(e, props.item?.resource[0]?.resource_data?.id ?? props.item.id)}
          >
            Publish Changes
          </div>
        ) : null}
        {is_live_class &&
          !["BreakoutSession", "Resource", "BreakoutGroup", "ActiveBreakoutSession", "QuestionAnswer"].includes(classroomState.openedOverlaySection) &&
          sidebarState.currentSelectedResourcesType !== "MTFs" && (
            <img
              src={TriggerActivity}
              alt={`${props.item && props.item.name ? props.item.name.toLowerCase().split(" ").join("-") : "classroom"}-delete-resource`}
              className={`${is_live_class ? `pb-1` : ""} w-18px h-18px ${StylesB.image_with_hover} align-self-center pointer object-fit-contain`}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (props.item?.resource?.length > 0) {
                  //If already resource is created
                  props.myListActivityResource(sidebarState.currentSelectedResourcesType, props.index, props.item?.resource[0])
                } else {
                  // If resource is not created
                  publishResource(props.item)
                }
              }}
            />
          )}
        {showEllipsisMenu()}
      </div>
    </div>
  )
}

ResourceItem.propTypes = {
  parentComponent: PropTypes.string,
  list_type: PropTypes.string,
  //Data of a single resource
  item: PropTypes.shape({
    display_name: PropTypes.string,
    name: PropTypes.string,
    resource_data: PropTypes.object,
    is_published: PropTypes.bool,
  }).isRequired,
}

export default ResourceItem
