import React, { useState } from "react"
import PropTypes from "prop-types"

import Image from "../../custom/Image"
import Medal from "../../../images/svgs/medal.svg"
import CircleDown from "../../../images/svgs/arrow-dropdown-white-circle.svg"
import CircleUp from "../../../images/svgs/arrow-dropup-white-circle.svg"
import CloseIcon from "../../../images/svgs/close.svg"

import Style from "./style.module.scss"
import AvatarCircle from "../../custom/AvatarCircle"
import utcDateTime from "../../../services/utcdatetime"

// !definition of component
/**
 *
 * @param props --> badgesData, action function
 * @description -->  badgesData=> Badge detaisl object
 * @returns BadgesDetails Modal
 */
// ! component

const BadgesDetails = (props) => {
  const [badgeCollapseData, setBadgeCollapseData] = useState({})

  return (
    <div className={`px-2 py-3 ${Style.badges_modal}`}>
      <div className={`d-inline-flex w-100 align-items-center`}>
        <Image className={`mr-2 mb-0 ${Style.medal_icon}`} src={Medal} />
        <p className={`mb-0 pr-5 bold-500 fs-14px white-text`}>
          {props.badgesData.total_badges}
          <span>{props.badgesData.total_badges > 1 ? ` Badges` : ` Badge`} earned</span>
        </p>
        <Image
          className={`ml-auto mb-0 ${Style.badge_earner_close_icon} pointer`}
          src={CloseIcon}
          onClick={() => {
            props.action()
          }}
        />
      </div>
      <div className={`w-100`}>
        <p className={`mb-1 pr-5 bold-500 fs-12px white-text`}>By Peers</p>
        <div className="d-flex flex-column">
          {props.badgesData.badge_data.map((badge, index) => {
            return (
              <div className={`w-100 mb-1 bg-303C54 br-9px ${Style.badges_container}`}>
                <div className={`d-inline-flex w-100 align-items-center px-2 py-1`} key={index}>
                  <Image src={badge.icon} alt={`badges`} className={`my-auto ${Style.badge_icon}`} />
                  <p className={`mb-0 pr-5 bold-500 fs-12px white-text`}>
                    <div>{badge.name}</div>
                    {badgeCollapseData?.id !== badge.id && (
                      <div className={`d-inline-flex align-items-center position-relative ml-2 w-100`}>
                        {badge?.badges_assignee.map((assignee, indx) => {
                          if (indx <= 2) {
                            return assignee.assigner.profile_pic ? (
                              <Image
                                key={indx.toString()}
                                src={assignee.assigner.profile_pic}
                                alt={`badges`}
                                className={`my-auto pointer`}
                                style={{
                                  position: indx <= 0 ? "relative" : "absolute",
                                  left: `${indx * 15}px`,
                                  height: "18px",
                                  borderRadius: "8px",
                                }}
                              />
                            ) : (
                              <div>
                                <AvatarCircle name={assignee.assigner.first_name} size={`18px`} left={`${indx * 5}px`} />
                              </div>
                            )
                          }
                        })}
                      </div>
                    )}
                  </p>
                  <Image
                    className={`ml-auto pointer ${Style.circle_down}`}
                    src={badgeCollapseData?.id === badge.id ? CircleUp : CircleDown}
                    onClick={() => {
                      if (badgeCollapseData?.id === badge.id) {
                        setBadgeCollapseData({})
                      } else {
                        setBadgeCollapseData(badge)
                      }
                    }}
                  />
                </div>
                {badgeCollapseData?.id === badge.id && (
                  <>
                    {badgeCollapseData?.badges_assignee?.map((assignee, index) => {
                      return (
                        <div className={`d-inline-flex w-100 align-items-center px-3 py-1 mb-2`}>
                          <AvatarCircle name={assignee.assigner.first_name} size={`30px`} avatar={assignee.assigner.profile_pic} />
                          <span className="fs-13px px-2 py-1">By {assignee.assigner.first_name},</span>
                          <span className="fs-13px">{utcDateTime.utcDateTime(assignee.assigned_at, "display_dt")}</span>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

BadgesDetails.prototype = {
  badgesData: PropTypes.object,
  action: PropTypes.func,
}

export default BadgesDetails
