import React from "react"

// Props : height, bgClass, colorClass, Now(Required)
export default function Progress(props) {
  return (
    <div
      style={{ width: props.width ? props.width : "100%", height: props.height ? props.height : "10px" }}
      className={`align-self-center border-none br-10px overflow-hidden ${props.bgClass}`}
    >
      <div
        className={`${props.colorClass} br-10px`}
        style={{
          width: `${props.now}%`,
          height: props.height ? (props.exactHeight ? props.height : props.height) : "8px",
        }}
      />
    </div>
  )
}
