import React, { useState, useContext, useEffect } from "react"
import _ from "lodash"
import Col from "react-bootstrap/Col"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import CancelWhite from "../../images/svgs/close.svg"
import StylesC from "./classroom-resource-section.module.scss"
import { Modal } from "react-bootstrap"
import PersonalDetails from "./PersonalDetails"
import SessionBadge from "../insights/SessionBadge"
import GiveABadge from "./classroom-participants-section/giveABadge"
import AvatarCircle from "../custom/AvatarCircle"
import QuizAndAssignments from "../QuizAndAssignments/quizAndAssignments"
import { AssessmentMuralContext } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import { setActiveFilter, setShowParticipantDetailsModal } from "../../stores/assessment_mural_store/AssessmentMuralActions"
import EngagementScoreDropdown from "./classroom-participants-section/engagementScoreDropdown"
import InsightsReportsStore from "../../stores/insights_reports/InsightsReportsStore"

const ParticipantDetail = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [state, assessmentMuralDispatch] = useContext(AssessmentMuralContext)
  useEffect(() => {
    if (state?.activeAssessmentData?.participant_id && state?.activeAssessmentData?.mural_assessment_id) {
      assessmentMuralDispatch(setActiveFilter("quizzes"))
    }
  }, [])

  const filterOptions = [
    { label: "Profile", value: "profile" },
    { label: "Scores & Badges", value: "badges" },
    { label: "Queries", value: "queries" },
    { label: "Quiz and Assignments", value: "quizzes" },
    { label: "Chats", value: "chats" },
  ]

  const getActiveFiltredData = () => {
    if (state.activeFilter === "profile") {
      return props?.participantData?.owner__id && <PersonalDetails userId={props?.participantData?.owner__id} />
    } else if (state.activeFilter === "badges") {
      return (
        <div className={`d-inline-flex h-100 px-3 py-3 w-100 bg-42506C`}>
          <SessionBadge user={props?.participantData?.owner__id} training={classroomState.training_id} />
        </div>
      )
    } else if (state.activeFilter === "quizzes") {
      return <QuizAndAssignments training_track_id={props.participantData?.id} participantData={props.participantData} />
    }
  }

  return (
    <React.Fragment>
      <Modal show={state.showParticipantDetailsModal} centered size="lg" scrollable={true} className={`modal-content-bg-transparent `}>
        <Col lg="12" md="12" sm="12" xs="12" className={` px-0 ${StylesC.participant_details}`}>
          <Modal.Header>
            <div className={` d-inline-flex w-100 ${StylesC.participant_details_header}`}>
              <p className="mb-0">Student Details</p>
              <img
                src={CancelWhite}
                onClick={() => {
                  assessmentMuralDispatch(setShowParticipantDetailsModal(false))
                  assessmentMuralDispatch(setActiveFilter("profile"))
                }}
                alt={`Cancel`}
                className={`my-auto mr-1 pointer ${StylesC.participant_details_close_icon}`}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className={`d-inline-flex w-100 align-items-center ${StylesC.participant_details_modal_body}`}>
              <div className={`d-inline-block w-100`}>
                <div className={`d-inline-flex w-100 align-items-center px-2 py-3`}>
                  <AvatarCircle
                    name={props?.participantData?.owner__first_name}
                    size={`100px`}
                    avatar={props?.participantData?.owner__profile_pic}
                    className={`mr-1`}
                  />
                  <div className={``}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip placement="top" className="classroom-tooltip" id={`"tooltip-${props?.participantData?.id}"`}>
                          {props?.participantData?.owner__first_name}
                        </Tooltip>
                      }
                    >
                      <div className={`d-flex flex-column ${StylesC.participant_name} mr-2`}>
                        <p className={`fs-20px pointer pb-1 mb-0`}>{props?.participantData?.owner__first_name}</p>
                      </div>
                      {/* <p className={`mb-0 px-2 bold-500 white-text fs-20px`}>{props?.participantData?.owner__first_name}</p> */}
                    </OverlayTrigger>
                    {props?.participantData && (
                      <InsightsReportsStore>
                        <GiveABadge
                          participantsList={props.participantsList}
                          setParticipantsList={props.setParticipantsList}
                          value={props?.participantData}
                          setParticipantData={props.setParticipantData}
                        />
                      </InsightsReportsStore>
                    )}
                  </div>
                  <EngagementScoreDropdown
                    value={{}}
                    training_id={classroomState.training_id}
                    participantData={props?.participantData}
                    showComplianceCard={true}
                  />
                </div>
                <div className={`d-inline-flex w-100 align-items-center pt-1 px-2 bg-303C54 white-text ${StylesC.participant_details_filter_container}`}>
                  {filterOptions.map((option) => (
                    <p
                      key={option.value}
                      className={`mb-0 px-3 pointer pb-1 fs-16px ${state.activeFilter === option.value ? StylesC.selected_filter : ""}`}
                      onClick={() => assessmentMuralDispatch(setActiveFilter(option.value))}
                    >
                      {option.label}
                    </p>
                  ))}
                </div>
                <div className={`${StylesC.active_section_container}`}>{getActiveFiltredData()}</div>
              </div>
            </div>
          </Modal.Body>
        </Col>
      </Modal>
    </React.Fragment>
  )
}

export default ParticipantDetail
