import React from "react"
import PropTypes from "prop-types"

import Style from "./style.module.scss"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"

import "react-circular-progressbar/dist/styles.css"
import DownArrow from "../../../images/svgs/down-arrow.svg"
import Image from "../../custom/Image"

const StrategicComplianceCard = (props) => {
  return (
    <div
      key={`strategic-compliance-detail-card-${props.index}`}
      className={`text-center fs-12px white-text px-2 py-1 bg-313C54 bold-500 ${props.index > 0 && "ml-2"} br-9px pointer`}
    >
      <div className="border-bottom-6A7476 py-1">{props?.name}</div>
      <div className={`mt-2 ${Style.progress_container}`}>
        <CircularProgressbarWithChildren
          value={Math.round(props.percentage, 2)}
          styles={buildStyles({
            textColor: "#000",
            pathColor: "#E0E2E5",
            trailColor: `rgba(88, 104, 134, 1)`,
            border: "2px solid",
          })}
          strokeWidth={5}
          counterClockwise={false}
        >
          <div className="d-flex align-items-center">
            <span className="fs-10px">{Math.round(props.percentage)}%</span>
            <Image src={DownArrow} className={`${Style.arrow_icon_size}`} />
          </div>
        </CircularProgressbarWithChildren>
      </div>

      <div className="mt-1 px-2 py-2">Strategic Compliance</div>
    </div>
  )
}

StrategicComplianceCard.prototype = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
}

export default StrategicComplianceCard
