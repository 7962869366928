import React from "react"
import Modal from "react-bootstrap/Modal"
import PropTypes from "prop-types"

import BadgesDetails from "../../../template-resources/BadgesDetails"
import Style from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> show, action function
 * @description -->  show=> boolean value to show or hide the session badge details modal
 * @returns SessionBadgeDetails Modal
 */
// ! component

const SessionBadgeDetailsModal = (props) => {
  return (
    <Modal show={props.show} centered contentClassName={`${Style.content}`}>
      <Modal.Body className={`${Style.body}`}>
        <BadgesDetails badgesData={props.data} action={props.action} />
      </Modal.Body>
    </Modal>
  )
}

SessionBadgeDetailsModal.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func,
  data: PropTypes.object,
}

export default SessionBadgeDetailsModal
