import React, { useEffect, useState } from "react"
import Style from "./classroomParticipantsSection.module.scss"
import Image from "../../custom/Image"
import PropTypes from "prop-types"
import Dropdown from "react-bootstrap/Dropdown"
import DownArrow from "../../../images/svgs/down_arrow_white.svg"

/**
 * Component for showing all the badges to filter participants.
 * @param props -->allBadges, badgeFilter, setBadgeFilter
 * @returns {React.ReactElement} FilterByBadge component.
 */

const FilterByBadge = (props) => {
  return (
    <Dropdown className={`breakout-groups pr-3 pb-2`}>
      <Dropdown.Toggle className="border-none d-inline-flex align-items-center p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
        <div className={`mr-1 fs-14px ${Style.all_badges_text}`}>{props.badgeFilter ? props.badgeFilter : "Filter By Badge"}</div>
        <Image src={DownArrow} alt={`down-arrow`} className={`${Style.down_arrow}`} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`br-9px breakout-groups fs-12px ${Style.dropdown_menu_all_badges}`}>
        <Dropdown.Item
          className={`px-2 w-100 d-inline-flex align-items-center`}
          onClick={() => {
            props.setBadgeFilter("")
          }}
        >
          <p className={`mb-0 py-2 fs-14px ${Style.all_badges_text}`}>All badges</p>
        </Dropdown.Item>
        {props.allBadges && props.allBadges.length > 0 ? (
          props.allBadges.map((badge, ind) => {
            return (
              <Dropdown.Item
                key={ind.toString()}
                className={`px-2 w-100 d-inline-flex align-items-center`}
                onClick={() => {
                  props.setBadgeFilter(badge.name)
                }}
              >
                <p className={`mb-0 fs-14px ${Style.all_badges_text}`}>{badge.name}</p>
                <Image src={badge.icon} alt={`badge`} className={`ml-auto mb-0 pointer ${Style.badge_icon}`} />
              </Dropdown.Item>
            )
          })
        ) : (
          <p className={`mb-0 py-2 w-100 text-center fs-14px`}>No badges created yet!</p>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

FilterByBadge.propTypes = {
  badgeFilter: PropTypes.string.isRequired,
  setBadgeFilter: PropTypes.func.isRequired,
  allBadges: PropTypes.array.isRequired,
}
export default FilterByBadge
