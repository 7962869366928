import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MuralAuthWarning from "../mural/mural-warning-popup"
import config from "../mural/config"
import { isBrowser } from "../../services/developer_mode"
import Loader from "../custom/Loader"
import Image from "../custom/Image"
import { Rnd } from "react-rnd"
import Style from "./style.module.scss"
import openURL from "../../services/open_url"
import openInNewTab from "../../images/svgs/open_in_new_tab.svg"
import minimizeSvg from "../../images/svgs/dark_minimize.svg"
import fullscreenSvg from "../../images/svgs/fullscreen_svg.svg"
import maximizeSvg from "../../images/svgs/minimize_svg.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"
import MuralChatWindow from "./chatWindow/chatWindow"
import { useAssessmentMural } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import {
  setMuralAssessment,
  setMuralAssessmentId,
  setMuralChatWindowExpanded,
  setShowMuralCanvas,
} from "../../stores/assessment_mural_store/AssessmentMuralActions"
import { navigate } from "gatsby"
import { useContext } from "react"
import { ClassroomSidebarContext } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../stores/sidebar_store/SidebarActions"
/**
 * BreakProgressBar is a component - which will auto click after 5 seconds if user didn't pressed the button
 * @param {object} props Props to the component
 */
const MuralCanvas = (props) => {
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)

  const maximize = { width: `${sidebarStore.currentNavTab !== "" ? "82vw" : "92vw"}`, height: "98vh" }
  const [maximizeWidth, setMaximizeWidth] = useState(sidebarStore.currentNavTab !== "" ? "72vw" : "92vw")
  const [isMinimized, setIsMinimized] = useState(false) // New state variable to track minimized state

  const minimize = { width: "26vw", height: "35vh" }
  const [state, dispatch] = useAssessmentMural()
  const [draggableHash, setDraggableHash] = useState({
    x: 0,
    y: 0,
    width: maximizeWidth,
    height: maximize.height,
  })
  const [screenSizeButtons, setScreenSizeButtons] = useState({
    fullscreen: false,
    fitToScreen: true,
    minimizeScreen: true,
  })
  const [canvasLink, setCanvasLink] = useState(null)
  const [windowState, setWindowState] = useState(null)
  const [showMuralAuthWarning, setShowMuralAuthWarning] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const HIDE_MURAL_LOGIN = process.env.NEXT_PUBLIC_HIDE_MURAL_LOGIN
  useEffect(() => {
    if (windowState === "minimize") {
      setTimeout(() => {
        setMinWindowSize()
      }, 100)
    }
  }, [windowState])

  useEffect(() => {
    if (windowState !== "minimize") {
      setMaximizeWidth(sidebarStore.currentNavTab !== "" ? "72vw" : "92vw")
      setDraggableHash({ ...draggableHash, width: sidebarStore.currentNavTab !== "" ? "72vw" : "92vw" })
    } else if (windowState === "minimize" && sidebarStore.currentNavTab !== "") {
      setDraggableHash({ ...draggableHash, width: minimize.width })
    }
  }, [sidebarStore.currentNavTab, windowState])

  const setFitToWindowSize = () => {
    if (isBrowser() && document && document?.body) {
      setDraggableHash({ x: 10, y: 10, width: maximizeWidth, height: maximize.height })
      setScreenSizeButtons((prev) => ({ ...prev, fullscreen: true, fitToScreen: false, minimizeScreen: true }))
    }
  }
  const setMinWindowSize = () => {
    if (isBrowser() && document && document.body) {
      const x =
        parseInt(window.innerWidth) - 100 - document.getElementById("muralCanvas").getBoundingClientRect().width - (sidebarStore.currentNavTab === "" ? 0 : 320)
      const y = parseInt(window.innerHeight) - document.getElementById("muralCanvas").getBoundingClientRect().height
      setDraggableHash({ x: x, y: y, width: minimize.width, height: minimize.height })
      setScreenSizeButtons((prev) => ({ ...prev, fullscreen: true, fitToScreen: true, minimizeScreen: false }))
    }
  }
  useEffect(() => {
    if (state.showMuralCanvas) {
      setScreenSizeButtons((prev) => ({ ...prev, fullscreen: true, fitToScreen: false, minimizeScreen: true }))
      setFitToWindowSize()
      if (HIDE_MURAL_LOGIN !== "true") {
        if (localStorage.getItem(config.accessTokenKey)) {
          dispatch(setShowMuralCanvas(true))
        } else {
          setShowMuralAuthWarning(true)
        }
      } else {
        dispatch(setShowMuralCanvas(true))
      }
    } else {
      dispatch(setShowMuralCanvas(false))
    }
  }, [state.showMuralCanvas])

  useEffect(() => {
    const updateCanvasPosition = () => {
      // Calculate the initial x position based on the right side of the screen
      const screenWidth = window.innerWidth
      const canvasWidth = maximizeWidth === "72vw" ? 78 : 100 // Replace 72 and 92 with the actual percentage values used for "72vw" and "92vw"
      const initialX = screenWidth - (canvasWidth * screenWidth) / 100
      const canvasElement = document.getElementById("muralCanvas")
      if (canvasElement) {
        canvasElement.style.left = `${initialX}px`
        canvasElement.style.right = "auto"
      }
    }

    updateCanvasPosition()

    // Update the canvas position when the maximizeWidth changes or when the window is resized
    window.addEventListener("resize", updateCanvasPosition)
    return () => {
      window.removeEventListener("resize", updateCanvasPosition)
    }
  }, [maximizeWidth])

  useEffect(() => {
    let mural_link = null
    if (state.muralAssessment?.workspace_details?.mural?.canvas_link) {
      mural_link = state.muralAssessment.workspace_details.mural.canvas_link
    } else if (props?.muralLink) {
      mural_link = props.muralLink
    }
    if (mural_link) {
      try {
        if (mural_link.split("https://app.mural.co/a/").length > 1) {
          setCanvasLink(mural_link)
        } else {
          let link = mural_link.split("https://app.mural.co/t/")
          if (link.length > 1) {
            link = link[1]
            let unique_id = config.clientId
            let muralCanvasUrl = `https://app.mural.co/a/${unique_id}/t/${link}`
            setCanvasLink(muralCanvasUrl)
          }
        }
      } catch (error) {
        setCanvasLink(null)
      }
    } else {
      setCanvasLink(null)
    }
  }, [state.muralAssessment?.workspace_details?.mural?.canvas_link, props.muralLink])

  useEffect(() => {
    if (isBrowser()) {
      setWinWidthHeight()
    }
  }, [isBrowser()])

  function setWinWidthHeight() {
    if (isBrowser() && document && document?.body) {
      setDraggableHash({ x: 10, y: 10, width: maximizeWidth, height: maximize.height })
    }
  }
  const changeWindowScreen = (value) => {
    if (value === "maximize") {
      setDraggableHash({ x: 10, y: 10, width: maximizeWidth, height: maximize.height })
    } else if (value === "fit_to_window") {
      setFitToWindowSize()
    } else if (value === "minimize") {
      setMinWindowSize()
    }
  }

  const closeFullWindow = () => {
    try {
      if (document?.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen()
      } else if (document?.exitFullscreen) {
        document.exitFullscreen()
      } else if (document?.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen()
      }
    } catch (error) {}
  }

  const openFullScreen = (value) => {
    setWindowState(value)
    if (value === "maximize") {
      setScreenSizeButtons((prev) => ({ ...prev, fullscreen: false, fitToScreen: true, minimizeScreen: true }))
      try {
        var elem = document?.getElementById("muralCanvas")
        if (elem?.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem?.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen()
        } else if (elem?.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen()
        }
      } catch (error) {}
    } else {
      closeFullWindow()
    }
    changeWindowScreen(value)
  }

  const hideIframLoader = () => {
    if (showLoader) {
      setShowLoader(false)
    }
  }

  const closeMuralCanvas = () => {
    setWindowState(null)
    closeFullWindow()
    navigate(window.location.pathname)
    dispatch(setShowMuralCanvas(false))
    state.muralAssessment && dispatch(setMuralAssessmentId(null))
    state.muralAssessment && dispatch(setMuralAssessment(null))
    state.muralAssessment && dispatch(setMuralChatWindowExpanded(false))
    state.muralAssessment && dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "Participants" })
    props?.onHideCallback && props.onHideCallback()
  }
  return (
    <React.Fragment>
      {HIDE_MURAL_LOGIN === "false" ? <MuralAuthWarning showMuralAuthWarning={showMuralAuthWarning} setShowMuralAuthWarning={setShowMuralAuthWarning} /> : null}
      <Rnd
        id="muralCanvas"
        disableDragging={true}
        enableResizing={false}
        minWidth={324}
        minHeight={191}
        style={{ zIndex: sidebarStore.currentNavTab == "Resource" ? 1 : 1200 }}
        bounds={"body"}
        default={{ x: draggableHash.x, y: draggableHash.y, width: 324, height: 191 }}
        position={{ x: draggableHash.x, y: draggableHash.y }}
        size={{
          width: draggableHash.width,
          height: draggableHash.height,
        }}
        onDragStop={(e, d) => {
          setDraggableHash({
            x: d.x,
            y: d.y,
            width: draggableHash.width,
            height: draggableHash.height,
          })
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setDraggableHash({
            width: ref.style.width,
            height: ref.style.height,
            x: position.x,
            y: position.y,
          })
        }}
      >
        <div
          className={`box text-dark w-100 window_screen `}
          onScroll={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{
            margin: 0,
            height: "100%",
            backgroundColor: props.theme === "light" ? "#E1E4E6" : "#212C41",
            border: props.theme === "light" ? "1px solid #BAB7B710" : "1px solid #4D5154",
            borderRadius: "8px",
            boxShadow: props.theme === "light" ? "-4px -4px 10px #4B5760" : "0px 3px 12px #00000099",
            bottom: windowState === "minimize" ? "10px" : "auto",
          }}
        >
          <div className="w-100">
            <div>
              <div className={`d-flex p-0 ${Style.options_holder}`}>
                <React.Fragment>
                  {props?.copyNewWin === true ? (
                    <Image
                      className={`mb-0 pointer mr-2 my-auto`}
                      src={props.theme === "light" ? "/open_in_new_tab.svg" : openInNewTab}
                      title={"Open in new Tab/window"}
                      style={{ width: 15, height: 15 }}
                      onClick={(e) => {
                        openURL(canvasLink)
                        setWindowState(null)
                        closeFullWindow()
                        openFullScreen(`minimize`)
                      }}
                    />
                  ) : null}
                  {screenSizeButtons.minimizeScreen ? (
                    <Image
                      className={`object_fit_contain mb-0 pointer mr-2 my-auto ${Style.img_min_max}`}
                      src={props.theme === "light" ? "/dark_minimize.svg" : minimizeSvg}
                      onClick={() => {
                        openFullScreen(`minimize`)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {screenSizeButtons.fitToScreen ? (
                    <Image
                      className={`object_fit_contain mb-0 pointer mr-2 my-auto ${Style.img_min_max}`}
                      src={props.theme === "light" ? "/dark_fitscreen.svg" : fullscreenSvg}
                      onClick={() => {
                        openFullScreen(`fit_to_window`)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {screenSizeButtons.fullscreen ? (
                    <Image
                      className={`object_fit_contain mb-0 pointer mr-2 my-auto ${Style.img_min_max}`}
                      src={props.theme === "light" ? "/dark_fullscreen.svg" : maximizeSvg}
                      onClick={() => {
                        openFullScreen(`maximize`)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {props?.showCanvasControl ? (
                    <Image
                      className={`object_fit_contain mb-0 pointer mr-2 ${Style.img_min_max}`}
                      src={props.theme === "light" ? "/dark_close.svg" : CancelIcon}
                      onClick={closeMuralCanvas}
                    />
                  ) : null}
                </React.Fragment>
              </div>
            </div>
            <div style={{ marginTop: 50 }} className={"d-flex pl-3 pr-3"}>
              <iframe
                id="muralCanvas"
                title={"Mural"}
                style={{
                  border: "none",
                  height: windowState === "minimize" ? "25vh" : "90vh",
                }}
                loading="lazy"
                src={canvasLink}
                width={"100%"}
                height={"100%"}
                onLoad={hideIframLoader()}
                seamless={true}
              ></iframe>
              {showLoader ? <Loader class="center" size={40} /> : ""}
            </div>
          </div>
          {state.muralAssessment && <MuralChatWindow />}
        </div>
      </Rnd>
    </React.Fragment>
  )
}

MuralCanvas.propTypes = {
  muralLink: PropTypes.string,
  showCanvasControl: PropTypes.bool.isRequired,
  onHideCallback: PropTypes.func,
  muralAssessment: PropTypes.object,
}

export default MuralCanvas
