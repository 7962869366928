import React, { useEffect, useState } from "react"
import Style from "./classroomParticipantsSection.module.scss"

import Dropdown from "react-bootstrap/Dropdown"
import Image from "../../custom/Image"
import PropTypes from "prop-types"
import Progress from "../../custom/progressbar"

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import DownArrowWhite from "../../../images/svgs/down_arrow_white.svg"

import "react-circular-progressbar/dist/styles.css"

import StrategicComplianceStyle from "../../StudentModeling/StrategicComplianceCard/style.module.scss"
import StrategicCompliance from "../../StudentModeling/StrategicCompliance"
import { roundNumber } from "../../../utils/utility"

/**
 * Component for showing Engagement Score of a particular participant.
 * @param props -->value
 * @returns {React.ReactElement} EngagementScoreDropdown component.
 */

const EngagementConfig = [
  {
    key: "academic_score",
    name: "Academic",
    subKey: [
      { key: "goal_completion", name: "Goal Completion" },
      { key: "promptness", name: "Promptness" },
      { key: "quality", name: "Quality" },
    ],
  },
  {
    key: "behaviour_score",
    name: "Behavioural",
    subKey: [
      { key: "behaviour_ratio", name: "Behaviour Ratio" },
      { key: "persistent_attendance_ratio", name: "Persistent Attendance Ratio" },
      { key: "punctuality", name: "Punctuality" },
    ],
  },
  {
    key: "cognitive_score",
    name: "Cognitive",
    subKey: [
      { key: "mastery", name: "Mastery" },
      { key: "purposeful", name: "Purposefully" },
      { key: "thoroughness", name: "Thoroughness" },
    ],
  },
  {
    key: "emotional_score",
    name: "Emotoional",
    subKey: [
      { key: "enjoyment", name: "Enjoyment" },
      { key: "enthusiasm", name: "Enthusiasm" },
      { key: "pride", name: "Pride" },
    ],
  },
]

const EngagementScoreDropdown = (props) => {
  const [currentDropDown, setCurrentDropDown] = useState(null)
  const [engagementData, setEngagementData] = useState({})
  useEffect(() => {
    if (props?.value?.engagement_score) {
      setEngagementData(props?.value?.engagement_score)
    }
  }, [props?.value])
  return (
    <Dropdown className={`${Style.line_height1}`}>
      <Dropdown.Toggle
        as="div"
        variant="none"
        id={`resource-option-menu-${engagementData?.id}-${props.showComplianceCard ? "detail" : "not-details"}`}
        className={`shadow-none p-0 ${!props.showComplianceCard && `h-20px`} caret-none d-flex`}
      >
        {!props.showComplianceCard ? (
          <div className={`d-flex pointer bg-286473 br-3px text-white box-shadow-00000029 w-fit-content p-1`}>
            <p className={`fs-10px mb-0`}>E {engagementData?.engagement_score ? roundNumber(engagementData?.engagement_score * 100) : 0}% </p>
          </div>
        ) : (
          <StrategicCompliance training_id={props.training_id} participantData={props?.participantData} setEngagementData={setEngagementData} />
        )}
      </Dropdown.Toggle>
      {engagementData?.engagement_score && engagementData?.engagement_score > 0 ? (
        <Dropdown.Menu className={`${Style.engagement_score_dropdown} bg-4F6080 d-flex flex-column`}>
          <div className="d-flex align-items-center px-2 pt-2">
            <div className={`${StrategicComplianceStyle.progress_container} m-0 pr-2`}>
              <CircularProgressbarWithChildren
                value={roundNumber(engagementData?.engagement_score * 100)}
                styles={buildStyles({
                  textColor: "#000",
                  pathColor: "#E0E2E5",
                  trailColor: `rgba(88, 104, 134, 1)`,
                  border: "2px solid",
                })}
                strokeWidth={5}
                counterClockwise={false}
              >
                <div className="d-flex align-items-center">
                  <span className="fs-10px">{roundNumber(engagementData?.engagement_score * 100)}%</span>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            <p className="text-E0E2E5 fs-12px mb-0 pb-2 bold-500">Engagement Score</p>
          </div>
          <div className="d-flex flex-column">
            {EngagementConfig.map((engagement_score_obj, index) => {
              let engagement_score_data = engagementData?.data?.[engagement_score_obj.key]
              let individual_score_data = roundNumber(engagementData?.[engagement_score_obj.key] * 100)
              return (
                <div className={`d-flex flex-column ${index !== EngagementConfig.length - 1 && "mb-1"}`}>
                  <div
                    className={`w-100 d-flex align-items-center text-E0E2E5 py-3 bg-303C54 pointer position-relative z-index-2 border-707070 h-48px ${
                      index === EngagementConfig.length - 1 && currentDropDown?.index !== EngagementConfig.length - 1 && Style.enagagement_last_element
                    }`}
                    onClick={() => {
                      setCurrentDropDown({ key: engagement_score_obj.key, index: index })
                    }}
                  >
                    <p className="fs-12px mb-0 px-2 z-index-3">{engagement_score_obj.name}</p>
                    <div className="d-flex align-items-center ml-auto z-index-3 pr-2">
                      <p className="fs-12px mb-0 pr-2">{individual_score_data}%</p>
                      <Image src={DownArrowWhite} alt={`down_arrow`} className={`my-auto pointer`} width={`10px`} height={`5px`} />
                    </div>
                    <div className="position-absolute bg-FFFFFF22 z-index-1 h-48px" style={{ width: `${individual_score_data}%` }}></div>
                  </div>
                  {currentDropDown?.key === engagement_score_obj.key && (
                    <div
                      className={`w-100 bg-1D2A41 d-flex flex-column border-bottom-E0E2E5 ${
                        index === EngagementConfig.length - 1 && Style.enagagement_last_element
                      }`}
                    >
                      {engagement_score_obj.subKey.map((breakdown, break_index) => {
                        let engagement_score_sub = roundNumber(engagement_score_data[breakdown.key] * 100)
                        return (
                          <div className="d-flex align-items-center w-100 pl-1 justify-content-between py-1">
                            <p className="fs-10px mb-0 w-50">{breakdown.name}</p>
                            <div className="w-35per mr-1">
                              <Progress
                                bgClass={Style.progress_bar_password_validation}
                                colorClass={Style.progress_bar_color}
                                height={6}
                                width={70}
                                now={engagement_score_sub}
                              />
                            </div>
                            <p className="fs-10px mb-0 w-15per">{engagement_score_sub}%</p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  )
}

EngagementScoreDropdown.defaultProps = {
  showComplianceCard: false,
}

EngagementScoreDropdown.propTypes = {
  value: PropTypes.object.isRequired,
  training_id: PropTypes.number,
  participantData: PropTypes.object,
  showComplianceCard: PropTypes.bool,
}
export default EngagementScoreDropdown
