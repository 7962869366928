import React, { useState } from "react"
import PropTypes from "prop-types"
import { getRequest, putRequest } from "../../../services/rest_service"
import Btn from "../../custom/Button"
import config from "../config"
import Loader from "../../custom/Loader"
import muralWidgestData from "../muralWidgestFetching"

/**
 * MuralOutlineSync is a component - which will auto sync mural outline data
 * @param {object} props Props to the component
 */

const MuralOutlineSync = ({ breakoutGroupList, setBreakoutGroupList, templateId, trainingId }) => {
  const [loader, setLoader] = useState(false)

  const getMuralCredentials = async (template, training_id) => {
    let res = await getRequest(`/murals/get_mural_credentials/?template=${templateId ? templateId : ""}&training=${trainingId ? trainingId : ""}`)
    if (res.success) {
      syncMuralOutline(res.data?.auth_id)
    }
  }

  const syncMuralOutline = async (auth_id) => {
    setLoader(true)
    breakoutGroupList.forEach(async (group, index) => {
      let workspace_link = group.workspace_link.split("/").length > 6 ? `${group.workspace_link.split("/")[6]}.${group.workspace_link.split("/")[7]}` : null
      if (group.workspace_link.includes(config.clientId)) {
        workspace_link = `${group.workspace_link.split("/")[8]}.${group.workspace_link.split("/")[9]}`
      }
      await getMuralData(workspace_link, group.id, index, auth_id)
    })
  }

  const getMuralData = async (muralId, group_id, index, auth_id) => {
    let widgestData = {}
    if (muralId) {
      try {
        let data = await muralWidgestData(muralId, [], "", auth_id)
        if (data?.length > 0) {
          data.forEach((element) => {
            if (element.title && element.presentationIndex > -1) {
              widgestData[element.title] = element.id
            }
          })
          let tempBreakoutGroupList = [...breakoutGroupList]
          tempBreakoutGroupList[index].workspace_widgets = widgestData
          setBreakoutGroupList(tempBreakoutGroupList)
        } else {
          setLoader(false)
        }
      } catch (error) {
        setLoader(false)
        await getMuralData(muralId, group_id)
      }
    } else {
      setLoader(false)
    }
    if (Object.keys(widgestData).length > 0) {
      let response = await putRequest(`/breakoutgroups/${group_id}/`, { workspace_widgets: widgestData })
      if (response.success) {
        setLoader(false)
      }
    }
  }

  return (
    <React.Fragment>
      <Btn
        className={`mr-3 py-1 ml-auto ${loader && "disabled"}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          getMuralCredentials(templateId, trainingId)
        }}
      >
        Sync Mural {loader && <Loader />}
      </Btn>
    </React.Fragment>
  )
}

MuralOutlineSync.propTypes = {
  breakoutGroupList: PropTypes.array.isRequired,
  setBreakoutGroupList: PropTypes.func.isRequired,
}

export default MuralOutlineSync
