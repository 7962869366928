import React, { useEffect, useState, useContext } from "react"
import { createElement } from "react"

// import { ResponsiveLine } from "@nivo/line"
// import { useTooltip } from "@nivo/tooltip"
import { getRequest } from "../../../services/rest_service"
import SessionBadgeDetailsModal from "./SessionBadgeDetails"
import { TrainingSocketContext } from "../../../stores/socket/training_socket/trainingSocketProvider"

const BadgeMappingIcon = ({ x, y, size, fill, opacity = 1, borderWidth = 0, borderColor = "transparent", point }) => {
  if (x < 0) {
    x = 10
  }
  if (y < 0) {
    y = 10
  }
  if (point?.data?.is_last) {
    x = x - 10
  }
  return point.data?.badge_data?.map((data, index) => {
    return (
      <>
        <svg id={`graph-${data.id}`}>
          <defs>
            <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 20 20">
              <image x="0%" y="0%" width="20" height="20" href={data?.icon}></image>
            </pattern>
          </defs>
          <circle id="sd" class="medium" cx={x + size / 2 + index * 10 + 10} cy={y + size / 2} r="2%" fill="url(#image)" />
        </svg>
      </>
    )
  })
}

const CustomPointMapping = ({ pointSize = 0, points }) => {
  const mappedPoints = points.reverse().map((point) => ({
    id: point.id,
    symbol: BadgeMappingIcon,
    x: point.x - pointSize / 2,
    y: point.y - pointSize / 2,
    fill: point.color,
    borderColor: point.borderColor,
    point: point,
  }))

  return <g>{mappedPoints.map(({ symbol, ...point }) => createElement(symbol, { ...point, size: pointSize, key: point.id }))}</g>
}

// const ShowCustomTooltip = (props) => {
//   const { showTooltipFromEvent, hideTooltip } = useTooltip()

//   return (
//     <>
//       {props.slices.map((slice) => (
//         <rect
//           x={slice.x0}
//           y={slice.y0}
//           width={slice.width}
//           height={slice.height}
//           stroke="red"
//           strokeWidth={0}
//           strokeOpacity={0.75}
//           fill="red"
//           fillOpacity={0}
//           onMouseEnter={() => props.setCurrentSlice(slice)}
//           onClick={(event) => {
//             showTooltipFromEvent(
//               React.createElement(props.sliceTooltip, {
//                 slice,
//                 axis: props.enableSlices,
//                 hideTooltip: hideTooltip(),
//                 setCurrentSlice: props.setCurrentSlice(null),
//               }),
//               event,
//               "right"
//             )
//           }}
//           onMouseMove={(event) => {}}
//           onMouseLeave={() => {
//             hideTooltip()
//             props.setCurrentSlice(null)
//           }}
//         />
//       ))}
//     </>
//   )
// }

const SessionBadge = (props) => {
  const [data, setData] = useState([])
  const [showBadgeDetailModal, setBadgeDetailModal] = useState(false)
  const [badgeDetailsData, setBadgeDetailsData] = useState({})
  const [trainingWSready, trainingWSval, trainingWSsend] = useContext(TrainingSocketContext)

  useEffect(() => {
    getStudentBadgeData()
  }, [])

  useEffect(() => {
    if (trainingWSval && trainingWSval?.event_type === "user_badges") {
      getStudentBadgeData()
    }
  }, [trainingWSval])

  const getStudentBadgeData = async () => {
    let res = await getRequest(`/badges/user_session_badges/user/${props.user}/training/${props.training}/`)
    if (res.success) {
      setData(res.data)
    }
  }

  const showModal = (slice) => {
    setBadgeDetailModal(true)
    setBadgeDetailsData(slice.points[0].data)
  }

  return (
    <div style={{ height: "auto", width: "100%" }}>
      <SessionBadgeDetailsModal
        show={showBadgeDetailModal}
        onHide={() => {}}
        action={() => {
          setBadgeDetailModal(false)
        }}
        data={badgeDetailsData}
      />
      {/* <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        layers={["grid", "markers", "axes", "areas", "crosshair", "lines", CustomPointMapping, ShowCustomTooltip, "mesh"]}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sessions",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: "No. Of. Badges",
          legendOffset: -40,
          legendPosition: "middle",
          format: (e) => Math.floor(e) === e && e,
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "#4f5c76",
                strokeWidth: 3,
              },
            },
          },
          grid: {
            line: {
              stroke: "#4f5c76",
              strokeWidth: 5,
            },
          },
          background: "#42506c",
          textColor: "#8a94a8",
          fontSize: 12,
        }}
        enableArea={true}
        areaOpacity={0.2}
        colors={["#A8BDC3"]}
        colorBy="index"
        enableSlices="x"
        gridYValues={1}
        gridXValues={1}
        sliceTooltip={({ slice, hideTooltip, setCurrentSlice }) => {
          showModal(slice)
          return null
        }}
      /> */}
    </div>
  )
}

export default SessionBadge
