import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { getRequest } from "../../../services/rest_service"
import StrategicComplianceCard from "../StrategicComplianceCard"

const StrategicComplianceList = [{ name: "Engagement Score", key: "engagement_score" }]

const StrategicCompliance = (props) => {
  const [complianceData, setComplianceData] = useState({})
  const [showStrategicCompliance, setShowStrategicCompliance] = useState(false)
  useEffect(() => {
    getStrategicComplianceData()
  }, [])
  const getStrategicComplianceData = async () => {
    let res = await getRequest(`/student-engagement-score/student_engagement_score/?training=${props.training_id}&owner=${props?.participantData?.owner__id}`)
    if (res.success) {
      setComplianceData(res.data)
      setShowStrategicCompliance(true)
      props.setEngagementData(res.data)
    }
  }
  return showStrategicCompliance ? (
    <div className="d-flex">
      {StrategicComplianceList.map((cardListItem, index) => {
        return (
          <StrategicComplianceCard
            index={index}
            key={`strategic-compliance-card-${index}`}
            name={cardListItem.name}
            percentage={complianceData[cardListItem.key] * 100}
            value={complianceData}
          />
        )
      })}
    </div>
  ) : (
    ""
  )
}

StrategicCompliance.prototype = {
  training_id: PropTypes.number.isRequired,
  participantData: PropTypes.object.isRequired,
}

export default StrategicCompliance
