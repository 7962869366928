export const grid = "0px 0px 0px 4px"
export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  margin: `0`,
  borderRadius: "8px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "transparent" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})
export const getMoveModalItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  outline: "none",
  // padding: grid,
  margin: `0`,
  borderRadius: "20px",
  textAlign: "center",
  color: "#fff",

  // change background colour if dragging
  background: isDragging ? "transparent" : "#12172C",

  // styles we need to apply on draggables
  ...draggableStyle,
})

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "transparent" : "#12172c",
  padding: "12px",
  height: "280px",
  overflowY: "auto",
})
export const getGroupListStyle = (isDraggingOver) => ({
  background: "#303C54",
  height: "270px",
  overflow: "auto",
})
export const getMoveModalListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#303C54" : "#303C54",
  padding: "8px",
  height: "calc(100% - 50px)",
  overflow: "auto",
  marginTop: "8px",
  borderRadius: "8px",
  boxShadow: "0px 3px 6px #00000029",
})
export const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
