import React, { createContext, useReducer } from "react"

import InsightsReportsReducer from "./InsightsReportsReducer"

const initalState = {
  activeTab: 0,
  allCoursesData: null,
  activeCourseData: null,
  fetchedCoursesData: false,
  allClassesData: {}, // { course_${id}: [],  }
  activeClassData: null,
  fetchedClassesData: false,
  allSchoolsData: null,
  activeSchoolData: null,
  fetchedSchoolsData: false,
  schoolLevelTimePeriod: "3-Month",
  allEducatorsData: null,
  activeEducatorData: null,
  fetchedEducatorsData: false,
  educatorLevelTimePeriod: "3-Month",
  classLevelReportsData: {}, // { course_${id}_class_${class_id}: { stageWiseEngagement: null,  } }
  schoolLevelReportsData: {}, // { school_${id}_period_${schoolLevelTimePeriod}: data }
  educatorLevelReportsData: {}, // { educator_${id}_period_${educatorLevelTimePeriod}: data }
  inClassOrOverall: "In-Class",
}

const InsightsReportsStore = ({ children }) => {
  const [state, dispatch] = useReducer(InsightsReportsReducer, initalState)

  return <InsightsReportsContext.Provider value={[state, dispatch]}>{children}</InsightsReportsContext.Provider>
}

export const InsightsReportsContext = createContext()
export default InsightsReportsStore
