import React, { useState, useEffect, useContext } from "react"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import Style from "./classroomParticipantsSection.module.scss"
import Image from "../../custom/Image"
import PropTypes from "prop-types"
import Dropdown from "react-bootstrap/Dropdown"
import { postRequest, getRequest } from "../../../services/rest_service"
import Loader from "../../custom/Loader"
import { getCountOfBadges } from "../../utilities/getCountOfBadges"

import Circle from "../../../images/svgs/circle.svg"
import CheckCircle from "../../../images/svgs/check-circle.svg"
import DownArrow from "../../../images/svgs/down_arrow_white.svg"

import { InsightsReportsContext } from "../../../stores/insights_reports/InsightsReportsStore"

/**
 * Component for giving badges to  participants.
 * @param props -->participantsList,setParticipantsList,value,setParticipantData
 * @returns {React.ReactElement} GiveABadge component.
 */

const GiveABadge = (props) => {
  const [badgesDataFetched, setBadgesDataFetched] = useState(false)
  const [activeParticipantBadges, setActiveParticipantBadges] = useState([])
  const [activeParticipant, setActiveParticipant] = useState(null)
  const [filteredBadgesData, setFilteredBadgesData] = useState([])
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [reportsState, reportsDispatch] = useContext(InsightsReportsContext)

  useEffect(() => {
    if (activeParticipant) {
      setBadgesDataFetched(false)
      fetchStudentGivenBadges()
    }
  }, [activeParticipant])

  useEffect(() => {
    if (props?.value?.badges?.length > 0) {
      setFilteredBadgesData(props.value.badges.filter((badge) => badge.count > 0))
    }
  }, [props?.value?.badges])

  const badgeCount = getCountOfBadges(props.value.badges)
  const fetchStudentGivenBadges = async () => {
    try {
      const response = await getRequest(
        `/badges/user_badges_given/user/${activeParticipant.owner__id}/training/${classroomState.training_id ? classroomState.training_id : props.training_id}/`
      )
      if (response.success) {
        setActiveParticipantBadges(response.data)
        setBadgesDataFetched(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const givebadgeToStudent = async (badge, badge_id, bagde_indx) => {
    try {
      let formData = new FormData()
      formData.append("earner", activeParticipant.owner__id)
      formData.append("training", classroomState.training_id ? classroomState.training_id : props.training_id)
      if (classroomState?.activeSession?.id) {
        formData.append("module", classroomState?.activeSession?.id)
      }
      const response = await postRequest(`/badges/${badge_id}/toggle_badge_assignment/`, formData)
      if (response.success) {
        let current_badge_status = [...activeParticipantBadges]
        current_badge_status[bagde_indx].assigned = !current_badge_status[bagde_indx].assigned
        setActiveParticipantBadges(current_badge_status)
        let participantsCopy = [...props.participantsList]
        let userIndex = participantsCopy.findIndex((value) => value.owner__id == props.value.owner__id)

        let badgeIndex = userIndex !== -1 && participantsCopy[userIndex].badges.findIndex((current_badge) => current_badge.id === badge_id)
        if (userIndex !== -1) {
          let updatedUser
          if (badge.assigned) {
            if (badgeIndex === -1) {
              updatedUser = {
                ...participantsCopy[userIndex],
                badges: [...participantsCopy[userIndex].badges, { count: 1, icon: badge.icon, id: badge?.id, name: badge.name }],
                badge_count: participantsCopy[userIndex]?.badge_count + 1,
              }
            } else {
              let badges_copy = [...participantsCopy[userIndex].badges]
              badges_copy[badgeIndex] = { ...badges_copy[badgeIndex], count: badges_copy[badgeIndex].count + 1 }
              updatedUser = { ...participantsCopy[userIndex], badges: badges_copy, badge_count: participantsCopy[userIndex]?.badge_count + 1 }
            }
          } else if (badgeIndex >= 0) {
            let badges_copy = [...participantsCopy[userIndex].badges]
            badges_copy[badgeIndex] = { ...badges_copy[badgeIndex], count: badges_copy[badgeIndex].count - 1 }
            updatedUser = { ...participantsCopy[userIndex], badges: badges_copy, badge_count: participantsCopy[userIndex]?.badge_count - 1 }
          }

          //This condition is for updating data if we give badge in classlevel reports
          if (props.location === "class-level-report") {
            let copyData = {
              ...reportsState.classLevelReportsData[`course_${reportsState.activeCourseData?.id}_class_${reportsState.activeClassData?.id}`]
                .student_wise_engagement,
            }
            let index = copyData.results.findIndex((data) => data.id === updatedUser.id)
            copyData.results[index] = updatedUser
            props.setParticipantsList(copyData)
          }

          props.setParticipantData && props.setParticipantData(updatedUser)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dropdown
      onClick={(e) => {
        e.preventDefault()
        setActiveParticipant(props.value)
      }}
      className={`breakout-groups`}
    >
      <Dropdown.Toggle
        size="lg"
        className={`border-0 px-1 py-1 d-inline-flex align-items-center fs-14px br-4px`}
        id="dropdown_list"
        style={{
          background: filteredBadgesData?.length > 0 && badgeCount + 3 > 0 ? "transparent" : "linear-gradient(90deg, #7F4F6D 0%, #608FD9 100%) 0% 0%",
        }}
      >
        {filteredBadgesData?.length > 0 && badgeCount + 3 > 0 ? (
          <div className={`d-inline-flex w-100 align-items-center`}>
            <div className={`d-inline-flex align-items-center position-relative ml-2 ${Style.badge_container}`}>
              {filteredBadgesData?.map((p_badge, indx) => {
                if (indx <= 2 && p_badge.count > 0) {
                  return (
                    <Image
                      key={indx.toString()}
                      src={p_badge.icon}
                      alt={`badges`}
                      className={`my-auto pointer ${Style.shadow_img}`}
                      style={{
                        position: indx <= 0 ? "relative" : "absolute",
                        right: `${indx * 20}px`,
                      }}
                    />
                  )
                }
              })}
            </div>
            {badgeCount > 0 ? <p className={`mb-0 ml-auto pl-1 fs-14px ${Style.count_of_badges}`}>+{badgeCount}</p> : null}
          </div>
        ) : (
          <div className={`pr-2 fs-12px`}>Give a Badge</div>
        )}
        <Image src={DownArrow} alt={`down-arrow`} className={`mx-1 ${Style.down_arrow}`} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`bg py-0 ${Style.badges_dropdown_menu}`}>
        {badgesDataFetched ? (
          activeParticipantBadges.length > 0 ? (
            activeParticipantBadges.map((badge, badge_index) => {
              return (
                <Dropdown.Item className={`px-2 py-1 fs-12px d-inline-flex align-items-center ${Style.badges_dropdown_item}`} key={badge_index.toString()}>
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      givebadgeToStudent(badge, badge.id, badge_index)
                    }}
                    className={`d-inline-flex w-100 align-items-center`}
                  >
                    <Image src={badge.icon} alt={`badge`} className={`my-auto mr-2 pointer ${Style.badge_icon}`} />
                    {badge.name}
                    <Image className={`mb-0 ml-auto ${Style.select_badge}`} src={badge.assigned ? CheckCircle : Circle} alt={`check`} />
                  </div>
                </Dropdown.Item>
              )
            })
          ) : (
            <p className={`mb-0 py-2 w-100 text-center fs-14px`}>No badges created yet!</p>
          )
        ) : (
          <p className={`mb-0 py-2 w-100 text-center`}>
            <Loader />
          </p>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

GiveABadge.propTypes = {
  setParticipantsList: PropTypes.func.isRequired,

  participantsList: PropTypes.array.isRequired,

  value: PropTypes.object.isRequired,

  setParticipantData: PropTypes.func,
}
export default GiveABadge
