import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import QuizAndAssignmentTabs from "./_tabs"
import MuralAssignments from "./assignments"
import { AssessmentMuralContext } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import { setMuralChatWindowExpanded, setShowMuralCanvas } from "../../stores/assessment_mural_store/AssessmentMuralActions"

const QuizAndAssignments = ({ training_track_id, participantData }) => {
  const tabs = ["Mural Assignments", "Quizzes"]
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [state, assessmentMuralDispatch] = useContext(AssessmentMuralContext)
  useEffect(() => {
    if (state?.activeAssessmentData?.mural_assessment_id) {
      assessmentMuralDispatch(setShowMuralCanvas(true))
      assessmentMuralDispatch(setMuralChatWindowExpanded(true))
    }
  }, [])

  const renderActiveTab = () => {
    if (activeTab === "Mural Assignments") {
      return <MuralAssignments training_track_id={training_track_id} participantData={participantData} />
    }
    return <div className="px-3 white-text">Yet to implement</div>
  }

  return (
    <div>
      <QuizAndAssignmentTabs tabs={tabs} activeTab={activeTab} onTabSelected={setActiveTab} />
      {renderActiveTab()}
    </div>
  )
}

MuralAssignments.propTypes = {
  training_track_id: PropTypes.number.isRequired,
}

export default QuizAndAssignments
