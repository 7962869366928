import React from "react"
import { useContext } from "react"
import { Dropdown } from "react-bootstrap"
import StylesA from "../../../modularscss/upload.module.scss"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import Elipsis from "/src/images/svgs/threedots.svg"
import DeleteIcon from "/src/images/svgs/delete.svg"
import StylesM from "../../../components/upload-resource.module.scss"
import Image from "../Image"
import { getResourceIcon } from "../../Sidebar/utils"
import { trimText } from "../../utilities/utils"
import { resourceTypeList } from "../../Sidebar/constants"
import PropTypes from "prop-types"
import { useState } from "react"
import ResourcePopup from "../../resource/ResourcePopup"

const ResourceListItem = (props) => {
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)
  const { resource_content_type, resource_type } = props.item
  const [openResource, setOpenResource] = useState(false)
  let correspondingKey
  if (!resource_type && (resource_content_type === "turient image" || resource_content_type === "student document")) {
    const fileType = props.item?.file_type || props.item?.resource_data?.file_type
    if (fileType === "0") {
      correspondingKey = "Images"
    } else {
      correspondingKey = "Documents"
    }
  } else {
    correspondingKey = Object.keys(resourceTypeList).find((key) => resourceTypeList[key] === resource_content_type)
  }
  const fileObj =
    props.item?.name || props?.item?.resource_data?.name
      ? props.item
      : sidebarStore.allResources[resource_type].filter((resource) => resource.id == (props.item?.resource_id || props?.item?.id))[0]
  let imgObj
  if (resource_type) {
    imgObj = getResourceIcon(resource_type, fileObj)
  } else {
    imgObj = getResourceIcon(correspondingKey, fileObj)
  }
  return (
    <>
      <div
        className="d-flex flex-row align-items-center justify-content-between text-left p-2 col-12 d-inline-flex w-98 bg-4F6080 my-1 br-9px "
        key={fileObj.name + "_" + props.index}
      >
        <div className="d-flex flex-row min-w-15rem pointer" onClick={() => setOpenResource(true)}>
          <Image src={imgObj} alt={fileObj.resource_data.name} className="w-1_5rem" />
          <p className="fs-14px turient-header-text-color pl-2 mb-0">{trimText(fileObj.resource_data.name, 40)}</p>
        </div>
        {props?.removeResource && (
          <Dropdown className="breakout-groups pointer float-right mr-3 pointer">
            <Dropdown.Toggle className="border-none p-0 shadow-none bg-transparent" id="dropdown-basic">
              <div>
                <Image src={Elipsis} alt="option" className="turient-header-text-color h-1rem object-fit-contain" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`br-9px breakout-groups min-w-4rem ${StylesA.min_fit_width}`}>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault()
                  props.removeResource(fileObj.resource_data.id)
                }}
                className={`${StylesM.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
              >
                <Image src={DeleteIcon} alt={`topic-delete`} className={`my-auto object-fit-contain h-13px pr-2`} />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {openResource && (
        <ResourcePopup
          data={{
            ...props.item,
            resource_type: undefined,
          }}
          onHide={() => {
            setOpenResource(false)
          }}
        />
      )}
    </>
  )
}

ResourceListItem.propTypes = {
  item: PropTypes.shape({
    resource_id: PropTypes.string,
    id: PropTypes.string,
    resource_content_type: PropTypes.string,
    resource_type: PropTypes.string,
    name: PropTypes.string,
    file_type: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeResource: PropTypes.func, // If the prop is optional and a function.
}

export default ResourceListItem
