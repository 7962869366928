import { muralGetRequest } from "./mural-api-service"

const fetchMuralWidgest = async (url, auth_id) => {
  let res = await muralGetRequest(url, auth_id)
  return res.data
}

const muralWidgestData = async (muralId, data, next, auth_id) => {
  let res = await fetchMuralWidgest(`api/public/v1/murals/${muralId}/widgets?type=areas&next=${next}`, auth_id)
  data = data.concat(res.value)
  if (res.next) {
    return muralWidgestData(muralId, data, res.next, auth_id)
  } else {
    return data
  }
}

export default muralWidgestData
