import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyLoadImage } from "react-lazy-load-image-component"
import getImageToBeRendered from "../../utilities/resourceIcons"
import Style from "../style.module.scss"

/**
 * Component to show resource in reply.
 * @param {object} props.item - resource object
 * @returns {React.ReactElement} Reply Resource box
 */

const ReplyResource = ({ item }) => {
  let { typeId, imgObj } = getImageToBeRendered(item)
  return (
    <div className={`pr-2 d-flex mb-1 ${Style.resource_item}`}>
      <div className="d-flex align-items-center">
        {item.resource_data ? (
          item.resource_data.thumbnail_url ? (
            <LazyLoadImage className={`my-auto ${Style.resource_thumbnail}`} src={item.resource_data.thumbnail_url} alt={item.name} />
          ) : (
            <>
              {typeId === 1 && false ? (
                <FontAwesomeIcon icon={imgObj} className={`my-0 ${Style.fa_res_icon}`} />
              ) : (
                <LazyLoadImage className={`my-auto ${Style.image_res_icon}`} src={imgObj} alt={item.name} />
              )}
            </>
          )
        ) : (
          ``
        )}
        <p>{item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}</p>
      </div>
    </div>
  )
}

ReplyResource.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ReplyResource
