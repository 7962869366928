import {
  SET_ACTIVE_REPORT_TAB,
  SET_ALL_COURSES_DATA,
  SET_ACTIVE_COURSE_DATA,
  SET_FETCHED_COURSES_DATA,
  SET_ALL_CLASSES_DATA,
  SET_ACTIVE_CLASS_DATA,
  SET_FETCHED_CLASSES_DATA,
  SET_FETCHED_SCHOOLS_DATA,
  SET_ALL_SCHOOLS_DATA,
  SET_ACTIVE_SCHOOL_DATA,
  SET_SCHOOL_LEVEL_TIME_PERIOD,
  SET_FETCHED_EDUCATORS_DATA,
  SET_ALL_EDUCATORS_DATA,
  SET_ACTIVE_EDUCATOR_DATA,
  SET_EDUCATOR_LEVEL_TIME_PERIOD,
  SET_CLASS_LEVEL_REPORTS_DATA,
  SET_SCHOOL_LEVEL_REPORTS_DATA,
  SET_EDUCATOR_LEVEL_REPORTS_DATA,
  RESET_ALL_REPORTS_DATA,
  SET_INCLASS_OR_OVERALL,
} from "./InsightsReportsActions"

const InsightsReportsReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_REPORT_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }
    case SET_ALL_COURSES_DATA:
      return {
        ...state,
        allCoursesData: { ...action.payload },
      }
    case SET_ACTIVE_COURSE_DATA:
      return {
        ...state,
        activeCourseData: { ...action.payload },
      }
    case SET_FETCHED_COURSES_DATA:
      return {
        ...state,
        fetchedCoursesData: action.payload,
      }
    case SET_ALL_CLASSES_DATA:
      let res_data = { ...state }
      res_data.allClassesData[action.payload.key] = { ...action.payload.value }
      return res_data
    case SET_ACTIVE_CLASS_DATA:
      return {
        ...state,
        activeClassData: { ...action.payload },
      }
    case SET_FETCHED_CLASSES_DATA:
      return {
        ...state,
        fetchedClassesData: action.payload,
      }
    case SET_ALL_SCHOOLS_DATA:
      return {
        ...state,
        allSchoolsData: { ...action.payload },
      }
    case SET_ACTIVE_SCHOOL_DATA:
      return {
        ...state,
        activeSchoolData: { ...action.payload },
      }
    case SET_FETCHED_SCHOOLS_DATA:
      return {
        ...state,
        fetchedSchoolsData: action.payload,
      }
    case SET_SCHOOL_LEVEL_TIME_PERIOD:
      return {
        ...state,
        schoolLevelTimePeriod: action.payload,
      }
    case SET_ALL_EDUCATORS_DATA:
      return {
        ...state,
        allEducatorsData: [...action.payload],
      }
    case SET_ACTIVE_EDUCATOR_DATA:
      return {
        ...state,
        activeEducatorData: { ...action.payload },
      }
    case SET_FETCHED_EDUCATORS_DATA:
      return {
        ...state,
        fetchedEducatorsData: action.payload,
      }
    case SET_EDUCATOR_LEVEL_TIME_PERIOD:
      return {
        ...state,
        educatorLevelTimePeriod: action.payload,
      }
    case SET_CLASS_LEVEL_REPORTS_DATA:
      let data = { ...state }
      let report_type = action.payload.report_type
      data.classLevelReportsData[action.payload.key] = data.classLevelReportsData[action.payload.key]
        ? { ...data.classLevelReportsData[action.payload.key] }
        : {}
      data.classLevelReportsData[action.payload.key][report_type] = { ...action.payload.value }
      return data
    case SET_SCHOOL_LEVEL_REPORTS_DATA:
      let school_data = { ...state }
      let school_report_type = action.payload.report_type
      school_data.schoolLevelReportsData[action.payload.key] = school_data.schoolLevelReportsData[action.payload.key]
        ? { ...school_data.schoolLevelReportsData[action.payload.key] }
        : {}
      school_data.schoolLevelReportsData[action.payload.key][school_report_type] = { ...action.payload.value }
      return school_data
    case SET_EDUCATOR_LEVEL_REPORTS_DATA:
      let educator_data = { ...state }
      let educator_report_type = action.payload.report_type
      educator_data.educatorLevelReportsData[action.payload.key] = educator_data.educatorLevelReportsData[action.payload.key]
        ? { ...educator_data.educatorLevelReportsData[action.payload.key] }
        : {}
      if (
        educator_report_type === "classes_engagement_list" ||
        educator_report_type === "sessions_engagement_list" ||
        educator_report_type === "topics_engagement_list"
      ) {
        educator_data.educatorLevelReportsData[action.payload.key][educator_report_type] = [...action.payload.value]
      } else {
        educator_data.educatorLevelReportsData[action.payload.key][educator_report_type] = { ...action.payload.value }
      }
      return educator_data
    case RESET_ALL_REPORTS_DATA:
      return {
        activeTab: 0,
        allCoursesData: null,
        activeCourseData: null,
        fetchedCoursesData: false,
        allClassesData: {},
        activeClassData: null,
        fetchedClassesData: false,
        allSchoolsData: null,
        activeSchoolData: null,
        fetchedSchoolsData: false,
        schoolLevelTimePeriod: "3-Month",
        allEducatorsData: null,
        activeEducatorData: null,
        fetchedEducatorsData: false,
        educatorLevelTimePeriod: "3-Month",
        classLevelReportsData: {},
        schoolLevelReportsData: {},
        educatorLevelReportsData: {},
        inClassOrOverall: "In-Class",
      }
    case SET_INCLASS_OR_OVERALL:
      return {
        ...state,
        inClassOrOverall: action.payload,
      }
    default:
      return state
  }
}

export default InsightsReportsReducer
