import React, { useState, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import FormControl from "react-bootstrap/FormControl"

import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import Image from "../../custom/Image"

import SearchIcon from "../../../images/svgs/search.svg"

import StylesB from "../../template-resources/classroom-resource-section.module.scss"

/**
 * Component for searching resources.
 * @param {object} props -->  setSearchValueInParent
 * @returns {React.ReactElement} ResourceSearchBar component.
 */
const ResourceSearchBar = (props) => {
  const [sidebarState, dispatch] = useContext(ClassroomSidebarContext)
  const [searchValue, setSearchValue] = useState("")

  const delayedQuery = useCallback(
    _.debounce((value, section) => {
      // props.fetchAllResources(true, value, section)
      // props.fetchPublishedResources(true, value, section)
      props.setSearchValueInParent(value)
    }, 1000),
    []
  )

  return (
    <div className={`col-12 d-inline-flex ${StylesB.search_section}`}>
      <Image src={SearchIcon} alt={`sidebar resource search icon`} className={`my-auto`} />
      <FormControl
        autoFocus
        className={`${StylesB.search_input} border-0 py-0 px-2 shadow-none`}
        placeholder={`Search resources`}
        onChange={(e) => {
          setSearchValue(e.target.value)
          props.setLoaderforSearch()
          delayedQuery(e.target.value, sidebarState.currentSelectedResourcesType)
        }}
        value={searchValue}
      />
    </div>
  )
}

ResourceSearchBar.propTypes = {
  //To set search value in parent to check when fetching resources.
  setSearchValueInParent: PropTypes.func.isRequired,
}

export default ResourceSearchBar
