import React, { useState, useRef, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import Dropdown from "react-bootstrap/Dropdown"
import Loader from "../../custom/Loader"

import { putRequest } from "../../../services/rest_service"

import AcivityWorkSpaceStyle from "../../../components/acitvity-workspace/acitivity-workspace.module.scss"
import muralIcon from "../../../images/svgs/mural-icon.svg"
import editSvg from "../../../images/svgs/edit.svg"
import linkIcon from "../../../images/svgs/link.svg"
import SearchIcon from "../../../images/svgs/search.svg"
import validateMuralUrl from "../../../components/mural/validateMuralUrl"
import AppIntegrationModal from "../../app-integration/"
import config from "../config"
import { muralGetRequest } from "../mural-api-service"
import { copyToClipboard } from "../../../services/common"
import CryptoJS from "crypto-js"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { SET_APP_DATA, SET_NOTIFICATION_DATA } from "../../../stores/global_store/GlobalActions"
import muralWidgestData from "../muralWidgestFetching"

const titleConfiguration = {
  group: {
    title: "Add Team WorkSpace",
    placehoder: "Add Team Workspace Link (optional)",
    name: "Mural",
  },
  topic: {
    title: "Add Mural",
    placehoder: "Add Mural (optional)",
    name: "Mural",
  },
  course: {
    title: "Add Mural Workspace",
    placehoder: "Add Mural Workspace Link (optional)",
    name: "Mural WorkSpace",
  },
}

const ActivityRoomWokSpace = (props) => {
  const [globalStore, disptachGlobalStore] = useContext(GlobalContext)

  const [activityWorkspaceData, setactivityWorkspaceData] = useState(props.activity_workspace)
  const [workspaceList, setWorkspaceList] = useState([])
  const [roomSearchWorkspaceList, setRoomSearchWorkspaceList] = useState([])
  const [workspaceNextLink, setWorkspaceNextLink] = useState(null)
  const [isCopied, setIsCopied] = useState(null)
  const [loader, setLoader] = useState(false)
  const [searchLoader, setSearchLoader] = useState(false)
  const [searchValue, setSerachValue] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const isSearchData = useRef(false)

  const updateCourseDefault = async (link, id, name, workspace_type) => {
    setLoader(true)
    let payload = {
      activity_workspace: {
        link: link,
        id: id,
        workspace_type: workspace_type,
        name: name,
      },
      name: activityWorkspaceData.name,
    }
    if (props?.type === "topic") {
      payload.requested_module = props.module_id
      payload.stage_id = props.selectedStage
      let current_data = { ...props.modulesData }
      current_data[props.selectedStage][`module_${props.module_id}`].topics[props.topicIndex]["activity_workspace"] = payload.activity_workspace
      let data = await putRequest(`/topics/${activityWorkspaceData.id}/`, payload)
      if (data.success) {
        props.setModulesData(current_data)
        setactivityWorkspaceData((prev) => ({ ...prev, activity_workspace: payload.activity_workspace }))
      }
    } else if (props?.type === "group") {
      if (workspace_type === "mural") {
        let widgestData = {}

        if (id) {
          try {
            let mural_widgets = await muralWidgestData(id, [], "", props.mural_account)
            mural_widgets.forEach((element) => {
              if (element.title && element?.presentationIndex > -1) {
                widgestData[element.title] = element.id
              }
            })

            payload.workspace_link = link
            payload.workspace_widgets = widgestData
            let data = await putRequest(`/breakoutgroups/${activityWorkspaceData.id}/`, payload)
            if (data.success) {
              let group_data = [...props.breakoutGroups]
              group_data[props.groupIndex].activity_workspace = payload.activity_workspace
              group_data[props.groupIndex].workspace_link = payload.activity_workspace.link
              props.setBreakoutGroupList(group_data)
            }
          } catch (error) {
            await updateCourseDefault(link, id, name, workspace_type)
          }
        }
      }
    } else {
      let data = await putRequest(`/default-course-templates/${activityWorkspaceData.id}/`, payload)
      if (data.success) {
        let activity_data = { ...activityWorkspaceData }
        activity_data.activity_workspace = payload.activity_workspace
        props.setCourseTemplate(activity_data)
        setactivityWorkspaceData((prev) => ({ ...prev, activity_workspace: payload.activity_workspace }))
      }
    }

    setLoader(false)
  }

  const removeRoomLink = async () => {
    setLoader(true)
    let payload = {
      activity_workspace: {},
      name: activityWorkspaceData.name,
    }

    if (props?.type === "topic") {
      payload.requested_module = props.module_id
      payload.stage_id = props.selectedStage
      let current_data = { ...props.modulesData }
      current_data[props.selectedStage][`module_${props.module_id}`].topics[props.topicIndex]["activity_workspace"] = payload.activity_workspace
      let data = await putRequest(`/topics/${activityWorkspaceData.id}/`, payload)
      if (data.success) {
        props.setModulesData(current_data)
        setactivityWorkspaceData((prev) => ({ ...prev, activity_workspace: payload.activity_workspace }))
      }
    } else if (props?.type === "group") {
      payload.workspace_link = ""
      let data = await putRequest(`/breakoutgroups/${activityWorkspaceData.id}/`, payload)
      if (data.success) {
        let group_data = [...props.breakoutGroups]
        group_data[props.groupIndex].workspace_link = payload.workspace_link
        group_data[props.groupIndex].activity_workspace = {}
        props.setBreakoutGroupList(group_data)
      }
    } else {
      let data = await putRequest(`/default-course-templates/${activityWorkspaceData.id}/`, payload)
      if (data.success) {
        activityWorkspaceData.activity_workspace = payload.activity_workspace
        props.setCourseTemplate(activityWorkspaceData)
        setactivityWorkspaceData((prev) => ({ ...prev, activity_workspace: payload.activity_workspace }))
      }
    }
    setLoader(false)
  }

  const debouncedSearchMural = useCallback(
    _.debounce((url) => fetchMuralData(url), 1000),
    []
  )

  const handleSearchInput = (e) => {
    let searchText = e.target.value
    setSerachValue(searchText)
    if (props?.mural_workspace?.room?.id) {
      let data = workspaceList.filter((mural) => {
        let title = mural?.title?.toLowerCase()
        return title.includes(searchText.toLowerCase())
      })
      if (searchText) {
        setRoomSearchWorkspaceList(data)
      }
    } else {
      if (searchText.length >= 3) {
        let url = `api/public/v1/search/${props?.mural_workspace?.id}/murals?title=${e.target.value}`
        setSearchLoader(true)
        isSearchData.current = true
        debouncedSearchMural(url)
      } else {
        if (isSearchData.current) {
          isSearchData.current = false
          let url = `api/public/v1/workspaces/${props?.mural_workspace?.id}/murals`
          debouncedSearchMural(url)
        }
      }
    }
  }

  const fetchMuralData = async (url) => {
    try {
      setLoader(true)
      let response = await muralGetRequest(url, props.mural_account)
      if (response.type === "error") {
        throw response.message
      }
      if (url.includes("next=")) {
        setWorkspaceList([...workspaceList, ...response.data.value])
      } else {
        setWorkspaceList(response.data.value)
      }
      setWorkspaceNextLink(response.data.next)
    } catch (error) {
      setWorkspaceList([])
      setWorkspaceNextLink(null)
      disptachGlobalStore({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error } })
    }
    setLoader(false)
    setSearchLoader(false)
  }

  const handleSeeMoreClick = () => {
    if (!loader) {
      if (searchValue.length >= 3) {
        fetchMuralData(`api/public/v1/search/${props?.mural_workspace?.id}/murals?title=${searchValue}&next=${workspaceNextLink}`)
      } else {
        let url = `api/public/v1/workspaces/${props?.mural_workspace?.id}/murals/?next=${workspaceNextLink}`
        if (props?.mural_workspace?.room?.id) {
          url = `api/public/v1/rooms/${props?.mural_workspace?.room?.id}/murals/?next=${workspaceNextLink}`
        }
        fetchMuralData(url)
      }
    }
  }

  const workspaceClickHandler = (id, name, link) => {
    if (!loader) {
      let link_type = "other"
      if (validateMuralUrl(link)) {
        if (props?.type === "course") {
          link_type = "mural_workspace"
        } else {
          link_type = "mural"
        }
        updateCourseDefault(link, id, name, link_type)
      } else {
        updateCourseDefault(link, "", name, link_type)
      }
    }
  }

  function afterCopy(id = 1) {
    setIsCopied(id)
    setTimeout(() => {
      setIsCopied(null)
    }, 1500)
  }

  const addMuralClickHandler = (show) => {
    setShowDropdown(show)
    if (show) {
      setSerachValue("")
      if (props.mural_account) {
        isSearchData.current = false
        if (props.type === "course") {
          fetchMuralData(`api/public/v1/workspaces/`)
        } else if (props.type === "group" || props.type === "topic") {
          if (props?.mural_workspace?.id) {
            let url = `api/public/v1/workspaces/${props?.mural_workspace?.id}/murals`
            if (props?.mural_workspace?.room?.id) {
              url = `api/public/v1/rooms/${props?.mural_workspace?.room?.id}/murals/?limit=100`
            }
            fetchMuralData(url)
          } else {
            disptachGlobalStore({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: "Please select workspace at course level." } })
            setShowDropdown(false)
          }
        }
      } else {
        disptachGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      }
    }
  }

  const generateMuralLink = (mural) => {
    if (mural?._canvasLink && props.setCanvasLink) {
      return mural?._canvasLink
    }
    let [wsId, muralId] = mural?.id.split(".")
    let randomStr = CryptoJS.lib.WordArray.random(32).toString().substring(0, 40)
    return `${config.baseURL}/t/${wsId}/m/${wsId}/${muralId}/${randomStr}`
  }

  const handleCopyToClipboard = (event) => {
    activityWorkspaceData?.workspace_link
      ? copyToClipboard(event, activityWorkspaceData?.workspace_link, afterCopy)
      : copyToClipboard(event, activityWorkspaceData?.activity_workspace?.link, afterCopy)
  }

  return (
    <React.Fragment>
      <div className={`${AcivityWorkSpaceStyle.activity_room_workspace_container}`}>
        {/* Mural List Select */}
        {!activityWorkspaceData?.activity_workspace?.link && !activityWorkspaceData?.workspace_link ? (
          <Dropdown className={`${AcivityWorkSpaceStyle.dropdown_width} `} onToggle={addMuralClickHandler} show={showDropdown}>
            <Dropdown.Toggle className={`${AcivityWorkSpaceStyle.dropdown_toggle}  underline pointer fs-14px mr-5px`} id="dropdown-mural-list">
              {titleConfiguration[props?.type]?.title}
            </Dropdown.Toggle>
            <Dropdown.Menu className={`py-0 overflow-auto ${AcivityWorkSpaceStyle.add_activity_room_workspace}`}>
              {props.type !== "course" && (
                <div className={`col-12 d-inline-flex py-1 ${AcivityWorkSpaceStyle.activity_menu_devider}`}>
                  <img src={SearchIcon} alt={`search button`} className={`my-auto object-fit-contain w-16px h-16px `} />
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={handleSearchInput}
                    placeholder={"Search Murals (Min 3 characters)"}
                    className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                  />
                  {searchLoader && <Loader />}
                </div>
              )}

              {(props?.mural_workspace?.room?.id && searchValue ? roomSearchWorkspaceList : workspaceList).map((mural, index) => {
                let link = props.type === "course" ? `${config.baseURL}/t/${mural?.id}/home` : generateMuralLink(mural)
                let title = props.type === "course" ? `${mural.name} Workspace` : mural?.title
                let name = props.type === "course" ? mural?.name : mural?.title
                return (
                  <Dropdown.Item
                    onClick={(e) => {
                      workspaceClickHandler(mural.id, name, link)
                    }}
                    className={`col-12 d-inline-flex pointer d-flex align-items-center ${
                      index !== workspaceList.length - 1 && AcivityWorkSpaceStyle.activity_menu_devider
                    } ${AcivityWorkSpaceStyle.activity_menu_item}`}
                    key={mural?.id}
                  >
                    <div className="flex-shrink-0">
                      <img src={muralIcon} alt={`Activity Workspace Icon`} className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`} />
                    </div>
                    <div className={`mx-2 d-flex flex-column ellipsis`}>
                      <div className={`font-weight-bold ellipsis`}>{title}</div>
                      <div className={`ellipsis`}>{link}</div>
                    </div>
                    <div
                      onClick={(e) => {
                        copyToClipboard(e, link, () => {
                          afterCopy(mural.id)
                        })
                      }}
                      className={`underline pointer ml-auto flex-shrink-0 pl-2`}
                    >
                      {isCopied === mural.id ? "Copied" : "Copy link"}
                    </div>
                  </Dropdown.Item>
                )
              })}
              {workspaceNextLink && (
                <div className={`underline pointer p-2`} onClick={handleSeeMoreClick}>
                  See more
                </div>
              )}
              {loader && (
                <div className={`w-100 text-center py-2`}>
                  <Loader />
                </div>
              )}
              {!loader && workspaceList.length < 1 && <div className={`w-100 text-center py-2`}>No items found</div>}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        {/* Mural Existing */}
        {activityWorkspaceData?.activity_workspace?.link || activityWorkspaceData?.workspace_link ? (
          <Dropdown className={`${AcivityWorkSpaceStyle.dropdown_width}`}>
            <div
              className={`border-0 px-1 py-1 d-flex align-items-center ${AcivityWorkSpaceStyle.btn_focus} ${AcivityWorkSpaceStyle.existing_activity_button} ${AcivityWorkSpaceStyle.existing_activity_box}`}
            >
              <div className="flex-shrink-0">
                <img
                  src={
                    activityWorkspaceData?.activity_workspace?.link?.includes("app.mural") || activityWorkspaceData?.workspace_link?.includes("app.mural")
                      ? muralIcon
                      : linkIcon
                  }
                  alt={`Activity Workspace Icon`}
                  className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                />
              </div>

              <div className="px-1">{titleConfiguration[props?.type]?.name} </div>

              <div className={`${AcivityWorkSpaceStyle.mural_workspace_name}`}>
                {props.type === "group"
                  ? activityWorkspaceData?.activity_workspace?.link || activityWorkspaceData?.workspace_link
                  : activityWorkspaceData?.activity_workspace?.name}
              </div>
              <Dropdown.Toggle className={`${AcivityWorkSpaceStyle.dropdown_toggle}  flex-shrink-0 ml-1`} id="dropdown-mural-exisiting">
                <div className={`d-flex align-items-center`}>
                  <img alt="edit" src={editSvg} className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`} />
                </div>
              </Dropdown.Toggle>
            </div>

            <Dropdown.Menu className={`py-0 overflow-auto ${AcivityWorkSpaceStyle.add_activity_room_workspace}`}>
              <div
                className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.selected_activity_workspace} ${AcivityWorkSpaceStyle.activity_menu_devider}`}
              >
                <img
                  src={
                    activityWorkspaceData?.activity_workspace?.link?.includes("app.mural") || activityWorkspaceData?.workspace_link?.includes("app.mural")
                      ? muralIcon
                      : linkIcon
                  }
                  alt={`Activity Workspace Icon`}
                  className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                />
                <div className={`ml-2`}>
                  {`${props.type !== "group" ? activityWorkspaceData?.activity_workspace?.name : ""} ${props.type === "course" ? "WorkSpace" : ""}`}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  className={`ml-2 border-0 ellipsis`}
                >
                  {activityWorkspaceData?.workspace_link ? activityWorkspaceData?.workspace_link : activityWorkspaceData?.activity_workspace?.link}
                </div>
                <div onClick={handleCopyToClipboard} className={`underline pointer ml-auto flex-shrink-0 pl-2`}>
                  {isCopied ? "Copied" : "Copy link"}
                </div>
                {loader ? (
                  <span>
                    <Loader />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.activity_last_menu}`}>
                <span
                  className={`pointer ${AcivityWorkSpaceStyle.activity_workspace_title} ${!props?.isPermitted && "disable-content"}`}
                  onClick={removeRoomLink}
                >
                  Remove Link
                </span>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </div>
      <AppIntegrationModal />
    </React.Fragment>
  )
}

export default ActivityRoomWokSpace

ActivityRoomWokSpace.propTypes = {
  mural_workspace: PropTypes.object,
  activity_workspace: PropTypes.object.isRequired,
  setModulesData: PropTypes.func,
  modulesData: PropTypes.object,
  type: PropTypes.string.isRequired,
  selectedStage: PropTypes.number,
  module_id: PropTypes.number,
  topicIndex: PropTypes.number,
}
