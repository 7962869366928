import React, { useContext, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { SET_DISCUSSION_BOARD, SET_OPENED_OVERLAY_SECTION } from "../../../stores/classroom_store/ClassroomActions"
import Image from "../../custom/Image"
import { LeftNavItems } from "./constants"
import SidebarContent from "./sidebarContent"

import TurientLogo from "../../../images/logo.png"
import PollIcon from "../../../images/svgs/classroom/polls-nav-icon.svg"
import ResourcesIcon from "../../../images/svgs/classroom/resource-nav-icon.svg"
import LessonPlanIcon from "../../../images/svgs/classroom/lessonplan-nav-icon.svg"
import BreakoutSessionIcon from "../../../images/svgs/classroom/breakout-session_nav-icon.svg"
import BreakoutGroupsIcon from "../../../images/svgs/classroom/breakout-groups-nav-icon.svg"
import ParticipantsIcon from "../../../images/svgs/classroom/participants-nav-icon.svg"
import DiscussionIcon from "../../../images/svgs/classroom/discussionnav.svg"
import TeaCup from "../../../images/svgs/classroom/tea_cup.svg"
import ReportIssueIcon from "../../../images/report_an_issue.svg"
import StylesA from "../../template-resources/classroom-left-nav.module.scss"
import Styles from "../../Sidebar/desktop-left-navigation.module.scss"
import { SET_COMPANY_DETAILS } from "../../../stores/sidebar_store/SidebarActions"
import { HasReadPermission } from "../../../utils/PermissionDataAccess"
import { STUDENT } from "../../../constants/permissionsConstant"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import {
  setMuralAssessment,
  setMuralChatWindowExpanded,
  setShowMuralCanvas,
  SET_MURAL_ASSESSMENT_ID,
  SET_PARTICIPANT_ID,
} from "../../../stores/assessment_mural_store/AssessmentMuralActions"
import { AssessmentMuralContext } from "../../../stores/assessment_mural_store/AssessmentMuralStore"

const LeftNavTooltips = [
  "Class Content",
  "Resources",
  "Breakout Groups",
  "Breakout Sessions",
  "Assessment Toolkit",
  "Live Discussion Board",
  "Participants",
  "Break",
  "Report An Issue",
]
const LeftNavIcons = [
  LessonPlanIcon,
  ResourcesIcon,
  BreakoutGroupsIcon,
  BreakoutSessionIcon,
  PollIcon,
  DiscussionIcon,
  ParticipantsIcon,
  TeaCup,
  ReportIssueIcon,
]
const PollAndPopQuizzes = ["Case Studies", "Checklists", "MTFs", "Polls", "Pop Quizzes", "Quizzes"]

/**
 * Component for live class sidebar navigation.
 * @param {object} props
 * @param {string} props.parentComponent - Parent page from which the component is accessed.
 * @returns {React.ReactElement} ClassroomSidebar component.
 */

const ClassroomSidebar = (props) => {
  const [sidebarState, dispatch] = useContext(ClassroomSidebarContext)
  const [classroomState, classroomDispatch] = useContext(ClassroomContext)
  const [globalState, dispatchGlobal] = useContext(GlobalContext)
  const myRef = useRef()
  const [state, dispatchMural] = useContext(AssessmentMuralContext)
  let open_mural_assessment_flag = false
  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const participant_id = urlSearchParams.get("student_id")
      const mural_assessment_id = urlSearchParams.get("mural_assessment_id")
      open_mural_assessment_flag = urlSearchParams.get("open_mural_assessment")

      if (open_mural_assessment_flag) {
        dispatchMural({ type: SET_MURAL_ASSESSMENT_ID, payload: mural_assessment_id })
        dispatchMural({ type: SET_PARTICIPANT_ID, payload: participant_id })
      }
    }
  }, [])

  useEffect(() => {
    if (open_mural_assessment_flag == "true") {
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "Participants" })
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("company_logo") || localStorage.getItem("company_name")) {
      dispatch({
        type: SET_COMPANY_DETAILS,
        payload: {
          logo: localStorage.getItem("company_logo"),
          name: localStorage.getItem("company_name"),
        },
      })
    }
  }, [])

  useEffect(() => {
    if (sidebarState.currentNavTab === "Poll") {
      let activeTab = choseActiveTab()
      dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: activeTab })
    }
  }, [sidebarState.disabledResources])

  const choseActiveTab = () => {
    for (let i = 0; i < PollAndPopQuizzes.length; i++) {
      const resource = PollAndPopQuizzes[i]
      if (!sidebarState.disabledResources?.includes(resource)) {
        return resource
      }
    }
  }

  const handleNavClick = (item) => {
    if (sidebarState.currentNavTab === item) {
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
      if (item === "Resource" || item === "Poll") {
        dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "" })
      } else if (item === "BreakoutGroup" || item === "BreakoutSession") {
        classroomDispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: "" })
      }
    } else {
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: item })
      if (item === "Resource") {
        dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Videos" })
      } else if (item === "Poll") {
        let activeTab = choseActiveTab()
        dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: activeTab })
      } else if (item === "BreakoutGroup" || item === "BreakoutSession") {
        classroomDispatch({ type: SET_OPENED_OVERLAY_SECTION, payload: item })
      } else if (item === "DiscussionBoard") {
        if (!classroomState.discussionBoard.active) {
          classroomDispatch({ type: SET_DISCUSSION_BOARD, payload: { ...classroomState.discussionBoard, modal: true } })
        }
      }
    }
  }

  const displayNavTabs = () => {
    return LeftNavItems.map((item, index) => {
      let tab_icon = LeftNavIcons[index]
      if (!(props.parentComponent === "classroom-dashboard" && ["DiscussionBoard", "Break"].includes(item))) {
        return (
          ((item === "Participants" && HasReadPermission(STUDENT)) || item !== "Participants") && (
            <React.Fragment key={index.toString()}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip placement="right" className="classroom-tooltip" id={`"tooltip-${item}"`}>
                    {LeftNavTooltips[index]}
                  </Tooltip>
                }
              >
                <div
                  id={"nav" + index}
                  key={index.toString()}
                  onClick={() => {
                    if (item !== "Report An Issue") {
                      handleNavClick(item, index)
                    } else {
                      document.getElementById("reportAnIssue").click()
                    }
                  }}
                  className={`${item === sidebarState.currentNavTab ? Styles.active_nav_tab : Styles.inactive_nav_tab} ${
                    item === "Poll" && sidebarState.disabledResources?.includes(item) ? `disabled-div opacity-20` : ``
                  } py-2 pointer text-center my-2 ${item === "Report An Issue" ? `mt-auto` : ``}`}
                >
                  <Image src={tab_icon} alt={item} className={`my-auto object-fit-contain ${Styles.sidebar_icon}`} />
                </div>
              </OverlayTrigger>
            </React.Fragment>
          )
        )
      }
    })
  }

  const displayTurientLogo = () => {
    return (
      <Link to="/app/dashboard">
        <div
          onClick={() => {
            dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
            dispatchMural(setShowMuralCanvas(false))
            dispatchMural(setMuralChatWindowExpanded(false))
            dispatchMural(setMuralAssessment(null))
          }}
          className={`${Styles.turient_logo_div} d-flex-centered mx-auto my-2`}
        >
          <Image
            className={`${Styles.turient_logo_div}`}
            src={sidebarState.companyDetails?.logo && sidebarState.companyDetails?.logo !== "null" ? sidebarState.companyDetails?.logo : TurientLogo}
            alt={sidebarState.companyDetails?.name ? sidebarState.companyDetails?.name : "Turient"}
          />
        </div>
      </Link>
    )
  }

  return (
    <div ref={myRef} className={`${Styles.sidebar_main_div}`}>
      <div className={`${Styles.nav_icons_section} d-flex pb-5`}>
        {displayTurientLogo()}
        {displayNavTabs()}
      </div>
      {["Resource", "Poll", "LessonSessionPlan", "Participants"].includes(sidebarState.currentNavTab) ? (
        <div
          className={`${sidebarState.currentNavTab ? `${StylesA.left_nav_opened}` : `${StylesA.left_nav_collapsed}`} ${Styles.sidebar_content_section} mb-3`}
        >
          <SidebarContent parentComponent={props.parentComponent} participantData={props?.participantData} setParticipantData={props.setParticipantData} />
        </div>
      ) : null}
    </div>
  )
}

ClassroomSidebar.propTypes = {
  // To identity from which page this component is accessed.
  parentComponent: PropTypes.string.isRequired,
}

export default ClassroomSidebar
