import React from "react"
import PropTypes from "prop-types"
import SingleComment from "./_singleComment"

const CommentList = ({ items }) => {
  return (
    <React.Fragment>
      {items.map((comment) => (
        <SingleComment key={comment.id} userChat={comment} />
      ))}
    </React.Fragment>
  )
}

CommentList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default CommentList
