export const SET_ACTIVE_REPORT_TAB = "SET_ACTIVE_REPORT_TAB"
export const SET_ALL_COURSES_DATA = "SET_ALL_COURSES_DATA"
export const SET_ACTIVE_COURSE_DATA = "SET_ACTIVE_COURSE_DATA"
export const SET_FETCHED_COURSES_DATA = "SET_FETCHED_COURSES_DATA"
export const SET_ALL_CLASSES_DATA = "SET_ALL_CLASSES_DATA"
export const SET_ACTIVE_CLASS_DATA = "SET_ACTIVE_CLASS_DATA"
export const SET_FETCHED_CLASSES_DATA = "SET_FETCHED_CLASSES_DATA"
export const SET_FETCHED_SCHOOLS_DATA = "SET_FETCHED_SCHOOLS_DATA"
export const SET_ALL_SCHOOLS_DATA = "SET_ALL_SCHOOLS_DATA"
export const SET_ACTIVE_SCHOOL_DATA = "SET_ACTIVE_SCHOOL_DATA"
export const SET_SCHOOL_LEVEL_TIME_PERIOD = "SET_SCHOOL_LEVEL_TIME_PERIOD"
export const SET_FETCHED_EDUCATORS_DATA = "SET_FETCHED_EDUCATORS_DATA"
export const SET_ALL_EDUCATORS_DATA = "SET_ALL_EDUCATORS_DATA"
export const SET_ACTIVE_EDUCATOR_DATA = "SET_ACTIVE_EDUCATOR_DATA"
export const SET_EDUCATOR_LEVEL_TIME_PERIOD = "SET_EDUCATOR_LEVEL_TIME_PERIOD"
export const SET_CLASS_LEVEL_REPORTS_DATA = "SET_CLASS_LEVEL_REPORTS_DATA"
export const SET_SCHOOL_LEVEL_REPORTS_DATA = "SET_SCHOOL_LEVEL_REPORTS_DATA"
export const SET_EDUCATOR_LEVEL_REPORTS_DATA = "SET_EDUCATOR_LEVEL_REPORTS_DATA"
export const RESET_ALL_REPORTS_DATA = "RESET_ALL_REPORTS_DATA"
export const SET_INCLASS_OR_OVERALL = "SET_INCLASS_OR_OVERALL"
