export const validateMobileNumber = (number) => {
  const pattern = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/
  return pattern.test(number)
}
export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}
export const validateUrl = (url) => {
  const regex = /^((https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?)$/
  return regex.test(url)
}
export const trimText = (text, len = 15) => {
  if (text?.length > len) {
    return text.substring(0, len).trim() + "..."
  } else {
    return text
  }
}
