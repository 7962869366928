import React, { useState } from "react"
import PropTypes from "prop-types"

import Btn from "../../custom/Button"
import Loader from "../../custom/Loader"
import { getRequest } from "../../../services/rest_service"

/**
 * Component to show see more button for a section.
 * @param {object} props
 * @param {string} props.buttonText
 * @param {func} props.handleData
 * @returns {React.ReactElement} Classroom component
 */

const SeeMore = (props) => {
  const [showLoader, setShowLoader] = useState(false)

  const fetchData = async () => {
    setShowLoader(true)
    const response = await getRequest(props.url)
    if (response.success) {
      setShowLoader(false)
      props.handleData(response)
    } else {
      // TODO: add global error handling
    }
  }
  return (
    <Btn type="small" disabled={showLoader ? true : false} onClick={fetchData}>
      <div className="d-flex">
        {showLoader ? <Loader /> : null}
        {props.buttonText || "See More"}
      </div>
    </Btn>
  )
}

SeeMore.propTypes = {
  // Default value will be See More.
  buttonText: PropTypes.string,

  // Function to update data in parent component.
  handleData: PropTypes.func.isRequired,
}

export default SeeMore
