import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeWellFormedURL } from "../../utilities/makeWellFormedUrl"
import { getRequest } from "../../../services/rest_service"
import Image from "../../custom/Image"
import Style from "./style.module.scss"

import LinkedInIcon from "../../../images/svgs/linkedinicon.svg"
import FBIcon from "../../../images/svgs/fbicon.svg"
import TwitterIcon from "../../../images/svgs/twittericon.svg"
import InstagramIcon from "../../../images/svgs/instagram.svg"

/**
 * Component for showing personal details of participants in a card.
 * @param {number} props.userId
 * @returns {React.ReactElement} PersonalDetails component.
 */

const PersonalDetails = (props) => {
  const [userDataProfile, setUserDataProfile] = useState({})

  let userData = [
    { label: "Name :", value: userDataProfile?.first_name },
    { label: "Email id :", value: userDataProfile?.email },
    { label: "Location :", value: userDataProfile?.city },
    { label: "Phone no :", value: userDataProfile?.phone_number },
  ]

  let socialMediaArr = { linkedIn: LinkedInIcon, facebook: FBIcon, twitter: TwitterIcon, instagram: InstagramIcon },
    existingSocialMediaArr = [],
    socialMediaProfiles = userDataProfile?.userprofile?.social_media_profiles

  for (let key in socialMediaProfiles) {
    if (socialMediaProfiles[key]) {
      existingSocialMediaArr.push({ [key]: socialMediaProfiles[key] })
    }
  }

  useEffect(() => {
    if (props.userId) {
      getUserProfile(props.userId)
    }
  }, [props.userId])

  const getUserProfile = async (userId) => {
    const response = await getRequest(`/user/user-profile/${userId}/`)
    if (response.success) {
      setUserDataProfile(response.data)
    }
  }

  return (
    <div className={`d-inline-flex px-3 py-3 w-100 align-items-center`}>
      <div className={`px-3 pb-2 bg-303C54 br-9px`}>
        <div className={`w-100`}>
          <p className={`mb-0 py-2 white-text fs-16px`}>Personal details</p>
        </div>
        <div className={`py-2 ${Style.personal_details_border_top}`}>
          {userData.map((info) => {
            if (info.value) {
              return (
                <div className="d-flex white-text fs-16px" key={info.label}>
                  <p className={`mb-0`}>{info.label}</p>
                  <p className={`mb-0 pl-3`}>{info.value}</p>
                </div>
              )
            }
          })}
        </div>
        {existingSocialMediaArr.length > 0 ? (
          <div className="my-auto">
            <div className={`white-text fs-16px pt-2 ${Style.personal_details_border_top}`}>Social Media :</div>
            <div className="pb-2 justify-content-center">
              {existingSocialMediaArr.map((socialMedia) => {
                let key = Object.keys(socialMedia)[0]
                let link = makeWellFormedURL(socialMedia[key])
                if (link) {
                  return (
                    <a className={`py-1 mr-2 mb-0 pointer`} href={link} target={`turient_${key}`} key={key}>
                      <Image src={socialMediaArr[key]} className={`align-self-center ${Style.social_media_icon}`} alt={`${key}`} />
                    </a>
                  )
                }
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PersonalDetails

PersonalDetails.propTypes = {
  userId: PropTypes.number.isRequired,
}
