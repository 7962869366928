import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Style from "./style.module.scss"
import formatDate from "../../../services/format_date"
import { isBrowser } from "../../../services/developer_mode"
import { deleteMuralAssessmentChat } from "./_api"
import DeleteIcon from "/src/images/svgs/delete.svg"
import DefaultUser from "/src/images/svgs/DefaultUser.svg"
import ResourceListItem from "../../custom/ResourceListItem"

const USER_ID = isBrowser() && window.localStorage.getItem("student_id")

const SingleComment = ({ userChat }) => {
  const isOwner = USER_ID == userChat?.sent_by?.id

  return (
    <div className={`d-flex ${Style.comment_main_div}`}>
      <div>
        <LazyLoadImage
          className={`mb-0 mr-2 ${Style.comment_user_image}`}
          size="sm"
          src={userChat?.sent_by?.profile_pic || DefaultUser}
          alt={userChat?.sent_by?.first_name}
        />
      </div>
      <div className="d-flex flex-column overflow-auto flex-grow-1">
        <div className="d-flex w-100">
          <span className={Style.comment_reply_user_name}>{isOwner ? "You" : userChat?.sent_by?.first_name} replied </span>
          <span className={Style.comment_reply_datetime}>&nbsp;| {formatDate.toLocalDateFormatDateAndTime(userChat?.sent_at)}</span>
          {isOwner && <LazyLoadImage className="pointer ml-auto" size="sm" src={DeleteIcon} onClick={() => deleteMuralAssessmentChat(userChat.id)} />}
        </div>
        {userChat.message.length > 0 && <div className={Style.comment_text} dangerouslySetInnerHTML={{ __html: userChat.message }}></div>}
        {userChat.resources.length > 0 && (
          <div className={Style.resource_wrapper}>
            {userChat.resources.map((chat) => {
              return (
                <ResourceListItem
                  key={`res_item_${chat.id}`}
                  item={{ resource_data: chat.resource_data, resource_content_type: chat.resource_content_type }}
                  index={chat.id}
                  className={Style.resource_item}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

SingleComment.propTypes = {
  userChat: PropTypes.object.isRequired,
}

export default SingleComment
