import React from "react"
import Loadable from "@loadable/component"
import PropTypes from "prop-types"
import "./videocard.scss"

const YoutubeReactPlayer = Loadable(() => import("react-player/youtube"))
const VimeoReactPlayer = Loadable(() => import("react-player/vimeo"))
const FileReactPlayer = Loadable(() => import("react-player/file"))

const VideoCard = (props) => {
  const displayPlayer = () => {
    let propsWithDefault = {
      ref: props?.videoPlayerRef,
      url: props.url,
      width: props?.width ?? "100%",
      height: props?.height ?? "100%",
      style: props?.style ?? {},
      pip: false,
      playing: props?.playing ?? true,
      controls: props?.controls ?? true,
      volume: props?.volume ?? 0.5,
      muted: props?.muted ?? true,
      onReady: props?.onReady ?? void 0,
      onStart: props?.onStart ?? void 0,
      onPlay: props?.onPlay ?? void 0,
      onEnablePIP: props?.onEnablePIP ?? void 0,
      onDisablePIP: props?.onDisablePIP ?? void 0,
      onPause: props?.onPause ?? void 0,
      onBuffer: props?.onBuffer ?? void 0,
      onSeek: props?.onSeek ?? void 0,
      onEnded: props?.onEnded ?? void 0,
      onError: props?.onError ?? void 0,
      onProgress: props?.onProgress ?? void 0,
      onDuration: props?.onDuration ?? void 0,
    }

    if (props.channel === "vimeo") {
      return (
        <div className="embed-responsive">
          <VimeoReactPlayer {...propsWithDefault} config={{ vimeo: { playerOptions: { controls: false, keyboard: false } } }} />
        </div>
      )
    } else if (props.channel === "youtube") {
      return (
        <div className="embed-responsive">
          <YoutubeReactPlayer {...propsWithDefault} />
        </div>
      )
    } else {
      return (
        <div className="embed-responsive">
          <FileReactPlayer {...propsWithDefault} />
        </div>
      )
    }
  }

  return displayPlayer()
}

VideoCard.propTypes = {
  url: PropTypes.string.isRequired, //URL of Video
  channel: PropTypes.string.isRequired, //Channel of Video (Youtube/Vimeo/File)
  playing: PropTypes.bool.isRequired, //Initial Playing state of video player
  controls: PropTypes.bool.isRequired, //Video Control to be displayed of not
  volume: PropTypes.number.isRequired, //Volume of the video, it is decimal digits, by how much (0-1)
  muted: PropTypes.bool.isRequired, //Whether video is muted
  width: PropTypes.number.isRequired, //Width of video player
  height: PropTypes.number.isRequired, //Height of Video Player
  videoPlayerRef: PropTypes.any, //@Todo: Correct PropType needs to be identified. Reference of video player is set in this & to used by external components,
  style: PropTypes.object, //Optional Style if passed
  pip: PropTypes.bool.isRequired, //PIP (pic-in-pic) of Video
  loop: PropTypes.bool.isRequired, //Whether after playing video, should play from start
  onPlay: PropTypes.func.isRequired, //Func to call when Played
  onPause: PropTypes.func.isRequired, //Func to call when Paused
  onProgress: PropTypes.func.isRequired, //Func to call when Progressing
  onDuration: PropTypes.func.isRequired, //Func to call when Duration is changed
  onReady: PropTypes.func.isRequired, //Func to call when ready
  onEnded: PropTypes.func.isRequired, //Func to call when ended,
}

export default VideoCard
