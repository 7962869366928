import React, { useState, useEffect } from "react"
import { getRequest } from "../../services/rest_service"

const usePaginator = (url, appendAtStart = false, reverseAndAppend = false) => {
  const [totalCount, setTotalCount] = useState(0)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const [fetchError, setFetchError] = useState(false)

  const fetchNext = () => {
    if (nextUrl) fetchData(nextUrl)
  }

  const removeItem = (filterFunction) => {
    setItems((prevItems) => prevItems.filter(filterFunction))
    setTotalCount((prevTotalCount) => prevTotalCount - 1)
  }

  const fetchData = async (requestUrl) => {
    setLoading(true)
    const { data, success } = await getRequest(requestUrl)
    if (success) {
      setTotalCount(data?.count)
      appendItems(data?.results)
      setNextUrl(data?.next)
    } else {
      setFetchError(true)
    }
    setLoading(false)
  }

  const appendItems = (newItems) => {
    if (reverseAndAppend) {
      newItems.reverse()
    }
    if (appendAtStart) {
      setItems([...newItems, ...items])
    } else {
      setItems([...items, ...newItems])
    }
  }

  const appendItem = (item, start = false) => {
    if (start) {
      setItems([item, ...items])
    } else {
      setItems([...items, item])
    }
  }

  useEffect(() => {
    fetchData(url)
  }, [])

  return { totalCount, items, loading, fetchError, appendItem, fetchNext, removeItem }
}

export default usePaginator
