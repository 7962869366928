import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { fetchAssignments } from "./_api"
import Loader from "../custom/Loader"
import MuralAssignmentRow from "./_muralAssignmentRow"

const MuralAssignments = ({ training_track_id, participantData }) => {
  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const { data, success } = await fetchAssignments(training_track_id)
      if (success) {
        setAssignments(data)
      } else {
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div className="w-100 bg-303C54 p-3 br-9px">
      <div className="white-text mb-2">
        <span className="font-weight-bolder">Mural Assignments</span>
      </div>
      {loading && <Loader size={30} />}
      {!loading && assignments.length === 0 && <span className="white-text">No assignments found for the user</span>}
      {assignments.length > 0 &&
        assignments.map((assignment) => (
          <MuralAssignmentRow key={assignment.id} muralData={assignment} setMuralDataList={setAssignments} participantData={participantData} />
        ))}
    </div>
  )
}

MuralAssignments.propTypes = {
  training_track_id: PropTypes.number.isRequired,
}

export default MuralAssignments
