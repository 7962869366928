import React from "react"
import PropTypes from "prop-types"
import Image from "../../custom/Image"
import { FILE_TYPE_DESC } from "../constants"
import Style from "./style.module.scss"
import { GOOGLE_EXCEL_DOCUMENT_URL, GOOGLE_PPT_DOCUMENT_URL, GOOGLE_WORD_DOCUMENT_URL, MS_OFFICE_URL } from "../../../constants/resources"

/**
 * Component to show resources of type turientImage.
 * @param {object} props.data - resource_data field of resource
 * @returns {React.ReactElement} Display all resources of type turienimage (image, pdf supported for now)
 */

const TurientImage = (props) => {
  return (
    <div className={`${Style.w_fill} d-flex justify-content-around`}>
      {FILE_TYPE_DESC[props.data?.file_type] === "image" && <Image className={Style.image} src={props.data.avatar} />}
      {FILE_TYPE_DESC[props.data?.file_type] === "PDF Document" && <iframe className={Style.document} src={props.data.avatar} />}
      {"Word Document Excel Document PPT Document".includes(FILE_TYPE_DESC[props.data?.file_type]) && (
        <iframe src={MS_OFFICE_URL + `?src=${props.data?.avatar}`} loading="lazy" referrerPolicy="no-referrer" className={`mb-0 ${Style.document}`} />
      )}
      {FILE_TYPE_DESC[props.data.file_type] === "Google Word Document" && (
        <iframe
          src={GOOGLE_WORD_DOCUMENT_URL + `${props.data?.google_doc_id}/pub?embedded=true`}
          loading="lazy"
          referrerPolicy="no-referrer"
          className={`mb-0 ${Style.document}`}
        />
      )}
      {FILE_TYPE_DESC[props.data.file_type] === "Google Excel Document" && (
        <iframe
          src={GOOGLE_EXCEL_DOCUMENT_URL + `${props.data?.google_doc_id}/pub?embedded=true`}
          loading="lazy"
          referrerPolicy="no-referrer"
          className={`mb-0 ${Style.document}`}
        />
      )}
      {FILE_TYPE_DESC[props.data.file_type] === "Google PPT Document" && (
        <iframe
          src={GOOGLE_PPT_DOCUMENT_URL + `${props.data?.google_doc_id}/embed?loop=true&slide=1`}
          loading="lazy"
          referrerPolicy="no-referrer"
          className={`mb-0 ${Style.document}`}
        />
      )}
    </div>
  )
}

TurientImage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TurientImage
