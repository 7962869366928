import React, { useEffect, useState } from "react"

import axios from "axios"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"

import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"

import SunEditor, { buttonList } from "suneditor-react"
import StylesM from "../../components/upload-resource.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import CancelWhite from "../../images/svgs/close.svg"

const readingTime = require("read-time-estimate")

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const CreateEditBlog = (props) => {
  const [blog, setBlog] = useState({ description: "" })
  const [blog_description, setBlogDescription] = useState("")
  const [addToResLib, setAddToResLib] = useState(true)

  useEffect(() => {
    setAddToResLib(true)
  }, [])

  useEffect(() => {
    if (props.id > 0) {
      fetchBlog()
    }
  }, [props.id])

  async function fetchBlog() {
    axios.get(process.env.GATSBY_REACT_APP_API_HOST + `/blogs/${props.id}/`, { headers: login_credentials }).then((res) => {
      setBlog(res.data)
      setBlogDescription(res.data.description)
    })
  }

  function handleChange(event) {
    event.preventDefault()
    blog[event.target.name] = event.target.value
  }

  function handleSubmit(event) {
    event.preventDefault()
    var formData = new FormData()
    let id = props.properties.mainId
    for (var k in blog) {
      if (k === "description") {
        formData.append(k, blog_description)
      } else {
        formData.append(k, blog[k])
      }
    }
    let d = Math.ceil(stat.duration)
    formData.append("points", d)
    formData.append("duration", d)
    if (props.properties && props.properties.mainId) {
      for (let p in props.properties) {
        formData.append(p, props.properties[p])
      }
    }
    formData.append("is_added_to_library", addToResLib)

    let url = "/blogs/create/",
      axiosCall = "post"
    if (props.id && props.id > 0) {
      url = "/blogs/" + props.id + "/"
      axiosCall = "put"
    }
    axios[axiosCall](process.env.GATSBY_REACT_APP_API_HOST + url, formData, { headers: login_credentials })
      .then((res) => {
        //console.log("Blog",id)
        props.setShowBlog(false)
        props.fetchTopicResources && props.fetchTopicResources(id)
        props.refetchLeftSideResourcesBar && props.refetchLeftSideResourcesBar()
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  let stat = readingTime(blog_description)
  /*buttonList12 = [ ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    '/', // Line break
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save'] ]
    ;*/

  /*let title = "Create Blog",
    buttonList12 = [['undo', 'redo', 'font', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat', 'fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table', 'link', 'image', 'video','print']];
  if ((props.id && props.id > 0) || blog.id > 0) {
    title = "Edit Blog", 
    buttonList12 = [['fontColor', 'indent', 'align','font', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic','link', 'image', 'video'] ];
  }*/

  return (
    <Modal
      show={props.showBlog}
      onHide={() => {
        props.setShowBlog(false)
      }}
      size="lg"
      contentClassName={StylesM.blog_modal}
      className={`modal-content-bg-transparent on-top`}
      centered={true}
      backdrop="static"
      keyboard={false}
      style={{ zIndex: "999999" }} // should be removed once Q&A brach is merged
    >
      <Modal.Header className={`p-2`} style={{ backgroundColor: "#212C42", borderBottom: "1px solid #929BBD", color: "#fff" }}>
        <div className={`w-100 d-flex justify-content-between`}>
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>Create Blog</div>
          <div>
            <FontAwesomeIcon
              className={`pointer`}
              onClick={(e) => {
                e.preventDefault()
                props.setShowBlog(false)
              }}
              icon={faTimesCircle}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#212C42" }}>
        {/*<img onClick={(e) => {e.preventDefault();props.setShowBlog(false)} } src={CancelWhite} alt={`Cancel`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `15px`,position:'absolute',top:'10px',right:'5px' }}/>
         */}
        <Col lg="12" md="12" sm="12" xs="12" className={`add-links justify-content-center text-white`} style={{ alignItems: "center", padding: 0 }}>
          {/*<Col>
        <p className={`font-weight-bold`} style={{color:'#E0E2E5',fontSize:'14px'}} >Create Blog</p>
        </Col>*/}
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Col xs="12" sm="6" md="12">
              <InputGroup className="mb-3" style={{ border: "1px solid #2F3C54", background: "#2F3C54", borderRadius: 8 }}>
                {/*<InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1" style={{border: '1px solid #2F3C54', background: '#2F3C54', borderRadius: 8}}><FontAwesomeIcon icon={faFile} style={{background: '#2F3C54', color: '#a9adba', marginBottom: '5px', width: `20px`, height: `20px`}} /></InputGroup.Text>
              </InputGroup.Prepend>
              */}
                <FormControl
                  style={{ color: "#fff", fontSize: 14, border: "1px solid #2F3C54", background: "#2F3C54", borderRadius: 8 }}
                  placeholder="Title for this Blog"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  defaultValue={""}
                  autoComplete="off"
                  name="name"
                  id="name"
                />
              </InputGroup>
            </Col>
            <input type="hidden" name="sequence" defaultValue={1} id="sequence" placeholder="Enter Blog sequence" required />
            <Col xs="12" sm="6" md="12" className="mb-3">
              {/*<div className="badge badge-success">{`${stat.humanizedDuration}`}</div>*/}
              <SunEditor
                setContents={blog.description}
                onChange={setBlogDescription}
                placeholder="Content of the Article here"
                setOptions={{
                  mode: "classic", //balloon-always
                  defaultStyle: "font-family: 'Arial'; font-size: 14px",
                  height: 200,
                  maxHeight: 450,
                  buttonList: [
                    [
                      "undo",
                      "redo",
                      "font",
                      "fontSize",
                      "formatBlock",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "removeFormat",
                      "fontColor",
                      "hiliteColor",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "table",
                      "link",
                      "image",
                      "video",
                      "print",
                    ],
                  ],
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="badge badge-secondary">System calculated reading time: {stat.humanizedDuration}</div>{" "}
            </Col>
            <Col>
              <Form.Check
                className="mt-2"
                style={{ fontSize: 14 }}
                type={"checkbox"}
                id={`default-checkbox`}
                label={`Add this blog to the resource library`}
                checked={addToResLib}
                onChange={() => setAddToResLib((p) => !p)}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <button
                type="submit"
                className="border_on_hover"
                style={{
                  backgroundColor: "#586886",
                  border: "1px solid #586886",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "4px",
                  paddingTop: "5px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "23px",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                Save
              </button>
              &nbsp;&nbsp;
              <button
                className="border_on_hover"
                onClick={() => {
                  setBlog({ description: "", name: "" })
                }}
                style={{
                  backgroundColor: "#586886",
                  border: "1px solid #586886",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "4px",
                  paddingTop: "5px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "23px",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                Clear
              </button>
            </Col>
          </Form>
        </Col>
      </Modal.Body>
    </Modal>
  )
}
export default CreateEditBlog
