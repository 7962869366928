import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import FormControl from "react-bootstrap/FormControl"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { QuestionContext } from "../../../stores/question_store/QuestionStore"

import { putRequest } from "../../../services/rest_service"

import Style from "../style.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import SearchIcon from "../../../images/svgs/search.svg"
import Image from "../../custom/Image"
import arrow from "../../../images/svgs/down_arrow_white.svg"

/**
 * Component for Topic select dropdown
 * @param {object} props.questionData - question data
 * @returns {React.ReactElement} Topic Select Dropdown
 */

const TopicsDropdown = ({ questionData }) => {
  const [classroomStore, classroomDispatch] = useContext(ClassroomContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)

  const [selectedOption, setSelectedOption] = useState(false)
  const [originalList, setOriginalList] = useState([])
  const [displayList, setDisplayList] = useState([])

  let topicName = questionData.content_type_name !== "training" ? questionData?.content_object_name : "Unassigned"
  let topicId = questionData.content_type_name !== "training" ? questionData.object_id : 0

  useEffect(() => {
    if (questionStore.all_topics) {
      setOriginalList(questionStore.all_topics)
      setDisplayList(questionStore.all_topics)
    }
  }, [questionStore.all_topics])

  const handleSearch = (e) => {
    let str = e.target.value.toLowerCase()
    let data = originalList.filter(
      (session) => session.name.toLowerCase().includes(str) || session.topics.some((topic) => topic.name.toLowerCase().includes(str))
    )
    setDisplayList(data)
  }

  const handleSelectTopic = async (id, name) => {
    try {
      setSelectedOption(false)
      let selectedTopic = { id: id, name: name }
      let type
      let prev_object_id = questionData.object_id
      let prev_resource_type = questionData.content_type_name
      if (selectedTopic.id > 0) {
        questionData.object_id = selectedTopic.id
        questionData.content_object_name = selectedTopic.name
        questionData.content_type_name = "topic"
        type = "topic"
      } else {
        questionData.object_id = classroomStore.training_id
        questionData.content_object_name = "Unassigned"
        type = "training"
      }
      let formData = new FormData()
      formData.append("object_id", selectedTopic.id > 0 ? selectedTopic.id : classroomStore.training_id)
      formData.append("resource_type", type)
      formData.append("training", classroomStore.training_id)
      formData.append("title", questionData.title)
      formData.append("prev_object_id", prev_object_id)
      formData.append("prev_resource_type", prev_resource_type)

      let response = await putRequest(`/queries/${questionData.id}/`, formData)
      if (!response.success) {
        throw "Something went wrong"
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleDropdown = () => {
    setSelectedOption((prev) => !prev)
  }

  return (
    <div className={`position-relative`}>
      <button
        className={`${Style.button_select} ${selectedOption ? Style.button_select_open : ""} d-inline-flex align-items-center mt-1`}
        onClick={handleToggleDropdown}
      >
        {selectedOption ? (
          <span>Select a Topic</span>
        ) : (
          <>
            <span>Topic : </span>
            <p className={`my-auto ml-1 mr-auto ${Style.ellipsis} ${topicName === "Unassigned" ? Style.yellow_text : ""}`}>{topicName}</p>
          </>
        )}
        <Image style={selectedOption ? { transform: "rotate(180deg)" } : null} className={`ml-auto pointer ${Style.icon_square}`} src={arrow} />
      </button>
      {selectedOption ? (
        <div
          onScroll={(e) => {
            e.stopPropagation()
          }}
          className={`${Style.select_topic_modal}`}
        >
          <div className={`w-100 ${Style.search_bar_select_topic_div} px-2 d-flex`}>
            <Image src={SearchIcon} alt={`search button`} className={`my-auto object-fit-contain ${Style.icon_square}`} />
            <FormControl
              className={`w-100 pl-1 ${Style.search_bar_select_topic}`}
              placeholder="Search"
              autoComplete="randomAnyString"
              onChange={handleSearch}
            />
          </div>
          <div>
            {displayList?.map((session, index_session) => {
              if (session.stage === "Workshop")
                return (
                  <div key={index_session} className={`mb-2`}>
                    <p className={`m-0 pl-2 ${Style.ellipsis} py-1 ${Style.text1}`} title={session?.name ?? `Session ${index_session + 1}`}>
                      {session?.name ?? `Session ${index_session + 1}`}
                    </p>
                    <div>
                      {session?.topics.map((topic, index) => {
                        return (
                          <div key={`ot-${topic?.id}`} className={`${Style.list_item} ${topicId === topic?.id ? Style.list_item_hover : ""}`}>
                            <div className="d-flex align-items-center" onClick={() => handleSelectTopic(topic?.id, topic?.name)}>
                              <p
                                className={`my-auto pl-2 mr-auto ${Style.text_gradient} ${Style.text2} ${Style.ellipsis}`}
                                title={topic?.name ?? `Topic ${index + 1}`}
                              >
                                {topic?.name ?? `Topic ${index + 1}`}
                              </p>
                              <div className="ml-auto pr-2">
                                <FontAwesomeIcon icon={topicId === topic?.id ? faCheckSquare : faSquare} />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
            })}
            <div className={`${Style.list_item}`}>
              <div className="d-flex align-items-center my-1" onClick={() => handleSelectTopic(0, "")}>
                <p className={`my-auto pl-2 mr-auto ${Style.yellow_text} ${Style.ellipsis}`}>{`Unassigned`}</p>
                <div className="ml-auto pr-2">
                  <FontAwesomeIcon icon={topicId === 0 || topicName === "Unassigned" ? faCheckSquare : faSquare} className={`${Style.yellow_text}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

TopicsDropdown.propTypes = {
  questionData: PropTypes.object.isRequired,
}

export default TopicsDropdown
