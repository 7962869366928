let CryptoJS = require("crypto-js")
const openURL = (url, name = null, callbackFunc = null) => {
  if (!url || url.length <= 0) {
    return null
  }
  if (!name || name.length <= 0) {
    name = CryptoJS.MD5(url)
  }
  let newWin = window.open(url, name)
  callbackFunc && callbackFunc()
  newWin.focus()
  return newWin
}

export default openURL
