import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import Style from "./style.module.scss"
import CancelWhite from "../../../images/svgs/close.svg"
import Image from "../../custom/Image"
import TurientImage from "./TurientImage"
import VideoCard from "../../videocards/videocards"

/**
 * Component to display resource popup.
 * @param {object} props.data - resource object (will handle student uploaded resources)
 * @param {function} props.onHide -  function to execute on hide
 * @returns {React.ReactElement} Modal popup to display resources
 */

const ResourcePopup = (props) => {
  const [type, setType] = useState()
  useEffect(() => {
    let res_type = props?.data?.resource_type
    if (!res_type) {
      if (
        props?.data?.resource_content_type === "student document" ||
        props?.data?.resource_content_type === "turient image" ||
        props?.data?.resource_content_type === "turientimage"
      ) {
        res_type = "turientimage"
      } else if (props?.data?.resource_content_type === "student video" || props?.data?.resource_content_type === "video") {
        res_type = "video"
      }
    }
    setType(res_type)
  }, [props.data])

  return (
    <>
      <Modal
        show={true}
        onHide={props.onHide}
        centered
        className={`${Style.modal} zIndex-9999`}
        dialogClassName={`${Style.dialog}`}
        contentClassName={`${Style.content}`}
      >
        <div className={`${Style.header}`}>
          <div>{props?.data?.resource_data?.name}</div>
          <div>
            <Image onClick={props.onHide} src={CancelWhite} alt={`Cancel`} className={`${Style.close_icon}`} />
          </div>
        </div>
        <Modal.Body className={`justify-content-center overflow-auto ${Style.body}`}>
          {type === "turientimage" && <TurientImage data={props?.data?.resource_data} />}
          {type === "video" && (
            <VideoCard
              url={props?.data?.resource_data?.url === "" ? props?.data?.resource_data?.video_file : props?.data?.resource_data?.url}
              channel={props?.data?.resource_data?.video_file ? `turient` : props?.data?.resource_data?.channel}
              width="400px"
              height="200px"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

ResourcePopup.propTypes = {
  data: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default ResourcePopup
