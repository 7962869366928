import React, { createContext, useContext } from "react"

import PropTypes from "prop-types"
import { WebsocketContext } from "../socketProvider"

//default value passed to create context :- ready, value, send
export const BreakoutGroupContext = createContext(false, null, () => {})

// The component tree than any consumers.
const BreakoutGroupProvider = ({ children }) => {
  const [ready, val, send] = useContext(WebsocketContext)

  return <BreakoutGroupContext.Provider value={[ready, val, send]}>{children}</BreakoutGroupContext.Provider>
}

BreakoutGroupProvider.propTypes = {
  // Children node need to pass to provider component
  children: PropTypes.node.isRequired,
}

export default BreakoutGroupProvider
