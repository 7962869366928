import React from "react"
import Style from "./classroomParticipantsSection.module.scss"

import Dropdown from "react-bootstrap/Dropdown"
import Image from "../../custom/Image"
import PropTypes from "prop-types"

import DeleteResource from "../../../images/svgs/delete.svg"
import EllipsisWhite from "../../../images/svgs/ellipsis-white.svg"
import WhitePollIcon from "../../../images/svgs/polls-white-icon.svg"
import { HasDeletePermission, HasReadPermission } from "../../../utils/PermissionDataAccess"
import { STUDENT } from "../../../constants/permissionsConstant"
import { useAssessmentMural } from "../../../stores/assessment_mural_store/AssessmentMuralStore"
import { setShowParticipantDetailsModal } from "../../../stores/assessment_mural_store/AssessmentMuralActions"

/**
 * Component for showing option to view deatils and delete participants.
 * @param props -->value, setShowDeleteParticipantModal,setParticipantData,setShowParticipantDetailsModal
 * @returns {React.ReactElement} ParticipantsDetailsOption component.
 */

const ParticipantsDetailsOption = (props) => {
  const [state, muralStateDispatch] = useAssessmentMural()
  return (
    <Dropdown className={`${Style.line_height1}`}>
      <Dropdown.Toggle as="div" variant="none" id={`resource-option-menu-${props.value.id}`} className={`shadow-none p-0 caret-none`}>
        <Image src={EllipsisWhite} alt={`ellipsis_icon`} className={`my-auto pointer ${Style.ellipse_icon}`} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${Style.resource_option_menu} bg-303C54`}>
        {HasReadPermission(STUDENT) && (
          <div
            className={`d-flex pointer ${Style.resource_option_hover}`}
            onClick={() => {
              muralStateDispatch(setShowParticipantDetailsModal(true))
              props.setParticipantData(props.value)
            }}
          >
            <Image src={WhitePollIcon} alt={`poll_icon`} className={`my-auto mr-1 ${Style.poll_icon}`} />
            <p className={`fs-13px mb-0`}>View Details </p>
          </div>
        )}
        {HasDeletePermission(STUDENT) && (
          <div
            className={`d-flex pointer ${Style.resource_option_hover}`}
            onClick={() => {
              props.setShowDeleteParticipantModal(true)
              props.setParticipantData(props.value)
            }}
          >
            <Image src={DeleteResource} alt={`delete_icon`} className={`my-auto mr-1 ${Style.poll_icon}`} />
            <p className={`fs-13px mb-0`}>Delete</p>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

ParticipantsDetailsOption.propTypes = {
  setShowDeleteParticipantModal: PropTypes.func.isRequired,

  setParticipantData: PropTypes.func.isRequired,

  setShowParticipantDetailsModal: PropTypes.func,

  value: PropTypes.object.isRequired,
}
export default ParticipantsDetailsOption
