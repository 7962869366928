import React, { useContext } from "react"
import PropTypes from "prop-types"
import muralIcon from "../../images/svgs/mural-icon.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import CopyToClipBoardWithIcon from "../common/CopyToClipBoard"
import { useEffect } from "react"
import { AssessmentMuralContext } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import {
  setMuralAssessment,
  setMuralChatWindowExpanded,
  setNewMessageFlag,
  setShowMuralCanvas,
  setShowParticipantDetailsModal,
} from "../../stores/assessment_mural_store/AssessmentMuralActions"
import Dropdown from "react-bootstrap/Dropdown"
import ChevronCircleDown from "../../images/svgs/down_arrow_white.svg"
import Image from "../custom/Image"
import { postRequest } from "../../services/rest_service"
import { statusList } from "../Sidebar/constants"
import chatImage from "../../images/svgs/chat.svg"
import { UserSocketContext } from "../../stores/socket/user_socket/userSocketProvider"
import { ClassroomSidebarContext } from "../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../stores/sidebar_store/SidebarActions"
import { isBrowser } from "../../services/developer_mode"
import timediff from "../utilities/timeDifference"
import formatDate from "../../services/format_date"
import rightArrow from "../../images/svgs/right.svg"
import WarningModal from "../custom/WarningModal"
import { useState } from "react"
import passIcon from "../../images/svgs/passIcon.svg"
import failIcon from "../../images/svgs/failIcon.svg"
const MuralAssignmentRow = ({ muralData, setMuralDataList, participantData }) => {
  const [state, assessmentMuralDispatch] = useContext(AssessmentMuralContext)
  const [userWSready, userWSval, userWSsend] = useContext(UserSocketContext)
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)
  const [warning, setWarning] = useState({})
  const [loader, setLoader] = useState(null)
  const openMuralModal = () => {
    assessmentMuralDispatch(setMuralAssessment(muralData))
    dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "" })
    assessmentMuralDispatch(setShowMuralCanvas(true))
    assessmentMuralDispatch(setShowParticipantDetailsModal(false))
  }
  const USER_ID = isBrowser() && window.localStorage.getItem("student_id")

  const openMuralModalWithChat = () => {
    openMuralModal()
    assessmentMuralDispatch(setMuralChatWindowExpanded(true))
    const updatedFlags = { ...state.newMessageFlag }
    delete updatedFlags[muralData.id]
    assessmentMuralDispatch(setNewMessageFlag(updatedFlags))
  }

  const setStatus = (id) => {
    updateStatusForId(muralData.id, id)
  }
  let open_mural_assessment_flag = false

  const closeWarningModalHandler = () => {
    //Close warning modal
    setWarning({})
  }
  const statushandler = (status) => {
    setStatus(status.id)
    setWarning({})
  }

  useEffect(() => {
    const handleNewMuralAssessmentMessage = () => {
      if (
        userWSval?.event_type === "mural-assessment" &&
        userWSval?.data?.student_assessment_mural === muralData.id &&
        userWSval?.action === "new-mural-assessment-message"
      ) {
        const updatedFlags = { ...state.newMessageFlag, [muralData.id]: true }
        if (parseInt(USER_ID) !== userWSval.data?.sent_by?.id) {
          assessmentMuralDispatch(setNewMessageFlag(updatedFlags))
        }
      }
    }

    const handleMuralAssessmentStatusChanged = () => {
      if (userWSval?.event_type === "mural-assessment" && userWSval?.data?.id === muralData.id && userWSval?.action === "mural-assessment-status-changed") {
        setMuralDataList((prevMuralData) =>
          prevMuralData.map((item) => (item.id === muralData.id ? { ...item, status: userWSval.data.status, checked_on: userWSval.data.checked_on } : item))
        )
      }
    }

    handleNewMuralAssessmentMessage()
    handleMuralAssessmentStatusChanged()
  }, [userWSval])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const participant_id = urlSearchParams.get("student_id")
      const mural_assessment_id = urlSearchParams.get("mural_assessment_id")
      open_mural_assessment_flag = urlSearchParams.get("open_mural_assessment")

      if (open_mural_assessment_flag) {
        updateStatusForId(mural_assessment_id, "0")
      }
    }
  }, [])

  const updateStatusForId = async (muralId, id) => {
    let checked = true
    const response = await postRequest(`/student-certification-data-details/${muralId}/change_assessment_status/`, { status: id, checked })
    if (response.success) {
      setMuralDataList((prevMuralData) => {
        return prevMuralData.map((item) => {
          if (item.id === muralData.id) {
            return response?.data
          } else {
            return item
          }
        })
      })
    } else {
      console.error(response?.data?.msg)
    }
  }
  useEffect(() => {
    if (state?.activeAssessmentData?.mural_assessment_id) {
      openMuralModal()
      dispatchSidebarStore({ type: SET_CURRENT_NAV_TAB, payload: "" })
    }
  }, [])

  const handleDropdownSelect = (status) => {
    if (muralData.status !== status.label) {
      if (status.label == "Passed") {
        setWarning({
          p1: `Are you sure , You want to mark `,
          p2: `${participantData?.owner__first_name} as pass for  ${muralData.workspace_details.mural.name} ?`,
          okayBtnName: "Yes",
          cancelBtnName: "No",
          cancel: closeWarningModalHandler,
          action: () => statushandler(status),
          icon: passIcon,
        })
      } else if (status.label == "Failed") {
        setWarning({
          p1: `Are you sure , You want to mark `,
          p2: `${participantData?.owner__first_name}  as fail the ${muralData.workspace_details.mural.name} ?`,
          okayBtnName: "Yes",
          cancelBtnName: "No",
          cancel: closeWarningModalHandler,
          action: () => statushandler(status),
          icon: failIcon,
        })
      } else {
        setStatus(status.id)
      }
    }
  }

  return (
    <>
      <div className="d-flex w-100 bg-42506C justify-content-between white-text align-items-center br-9px p-2 mb-2">
        <div className="d-flex bg-transparent align-items-center white-text ">
          <div className="mr-1">
            <LazyLoadImage src={muralIcon} className="w-24px h-24px" />
          </div>
          <span className="underline pointer fs-16px" onClick={openMuralModal}>
            {muralData.workspace_details.mural?.name}
          </span>
          <div className="ml-1">
            <CopyToClipBoardWithIcon textToBeCopied={muralData?.workspace_details?.mural?.link} resetTime={3000} />
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="fs-13px px-2 d-flex flex-row align-items-center " onClick={openMuralModalWithChat}>
            <div className="w-14px mr-2 position-relative">
              {state.newMessageFlag[muralData.id] && <div className="bg-1699A2 rounded-circle w-6px p-1 position-absolute top-0 left--5px" />}
              <LazyLoadImage src={chatImage} />
            </div>
            <p className="mb-0 w-5rem text-left underline pointer">{`${muralData.message_count} ${muralData.message_count > 1 ? "messages" : "message"}`}</p>
          </div>
          <div className="fs-14px px-2 w-7rem text-center">
            {muralData.status === "Submit For Review" ? (
              <p
                className="text-color-FDA701 fs-13px bold-500 mb-0 pointer bg-transparent"
                onClick={() => {
                  setStatus("5")
                  openMuralModal()
                }}
              >
                {`Review Now`}
                <Image src={rightArrow} className="ml-1" width={16} height={16} />
              </p>
            ) : muralData.status === "On Going" ? (
              <p className="turient-inactive-text-color fs-13px bold-500  mb-0 pointer bg-transparent">{`Not Submitted`}</p>
            ) : (
              <Dropdown className={`breakout-groups text-center`}>
                <Dropdown.Toggle className="border-none d-inline-flex align-items-center p-0 shadow-none bg-transparent" id="dropdown-basic">
                  <div
                    className={`mr-1 fs-13px bold-500 `}
                    style={{
                      color: statusList.find((status) => status.label === muralData.status)?.textcolor || "#E0E2E5",
                    }}
                  >
                    {muralData.status}
                  </div>
                  <Image src={ChevronCircleDown} className="pointer" width={10} height={10} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`br-9px breakout-groups fs-12px turient-header-text-color`}>
                  {statusList &&
                    statusList.length > 0 &&
                    statusList.map((status, ind) => {
                      return (
                        <Dropdown.Item
                          key={ind}
                          className={`px-2 w-100 d-inline-flex align-items-center`}
                          style={{ color: status.textcolor }}
                          onClick={() => handleDropdownSelect(status)}
                        >
                          <p className={`mb-0 fs-13px bold-500`}>{status.label}</p>
                        </Dropdown.Item>
                      )
                    })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div className="fs-13px px-2 w-11rem text-center">
            {muralData.checked_on !== null ? (
              <>
                {muralData.status === "Failed" || muralData.status === "Passed" ? (
                  <>
                    Reviewed on {formatDate.displayDate(muralData.checked_on)}-{formatDate.displayMonth(muralData.checked_on)}-
                    {formatDate.displayFullYear(muralData.checked_on)}
                  </>
                ) : muralData.status === "Submit For Review" ? (
                  <>Submitted {timediff.getHowMuchTimeAgo(muralData.checked_on)} ago</>
                ) : (
                  <>Last checked {timediff.getHowMuchTimeAgo(muralData.checked_on)} ago</>
                )}
              </>
            ) : (
              // Render an empty div when muralData.checked_on is null
              <div></div>
            )}
          </div>
        </div>
      </div>
      <WarningModal
        show={warning}
        onHide={closeWarningModalHandler}
        action={warning?.action}
        okayBtnName={warning?.okayBtnName}
        cancelBtnName={warning?.cancelBtnName}
        loader={loader === "warning"}
        icon={warning?.icon}
      >
        <div className={`d-flex flex-column`}>
          <div>{warning?.p1}</div>
          <div>{warning?.p2}</div>
        </div>
      </WarningModal>
    </>
  )
}

MuralAssignmentRow.propTypes = {
  muralData: PropTypes.object.isRequired,
}

export default MuralAssignmentRow
