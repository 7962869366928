import React from "react"
import PropTypes from "prop-types"
import SunEditor from "suneditor-react"

const CustomSunEditor = ({ content, onChange, placeHolder, defaultStyle, buttonList }) => {
  return (
    <SunEditor
      setContents={content}
      onChange={onChange}
      placeholder={placeHolder}
      setDefaultStyle={defaultStyle}
      setOptions={{
        buttonList: buttonList,
      }}
    />
  )
}

CustomSunEditor.defaultProps = {
  defaultStyle: "font-family: helvetica; font-size: 18px; height: 150px;opacity: 0.8",
  buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
}

CustomSunEditor.propTypes = {
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string.isRequired,
  defaultStyle: PropTypes.string,
  buttonList: PropTypes.array,
}

export default CustomSunEditor
