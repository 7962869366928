import React from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"

const QuizAndAssignmentTabs = ({ tabs, activeTab, onTabSelected }) => {
  return (
    <div className="d-inline-flex px-3 py-3 w-100 ">
      {tabs.map((tab) => {
        return (
          <div key={tab} className={`${Style.tabs_div} ${tab === activeTab ? Style.active : ""}`} onClick={() => onTabSelected(tab)}>
            <span>{tab}</span>
          </div>
        )
      })}
    </div>
  )
}

QuizAndAssignmentTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabSelected: PropTypes.func.isRequired,
}

export default QuizAndAssignmentTabs
