import React from "react"

import Dropdown from "react-bootstrap/Dropdown"
import Image from "../../custom/Image"

import DownArrow from "../../../images/svgs/down_arrow_white.svg"
import StylesB from "../../template-resources/classroom-resource-section.module.scss"

const FilterDropDown = (props) => {
  return (
    <Dropdown className={`${StylesB.document_type_dropdown} breakout-groups pr-3 pb-2`}>
      <Dropdown.Toggle className={`${StylesB.document_type_toggle} border-none d-inline-flex align-items-center p-0 shadow-none`} id="dropdown-basic">
        <div className={`mr-1`}>{props.value}</div>
        <Image src={DownArrow} alt={`resource filter toggle`} className={``} />
        {/* <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" className={`${StylesB.toggle_icon}`} /> */}
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${StylesB.document_type_dropdown_menu} br-9px breakout-groups`}>
        {props.listItems.map((item) => {
          return (
            <Dropdown.Item
              key={item}
              className={`${StylesB.document_type_dropdown_item}`}
              onClick={() => {
                props.onClick(item)
              }}
            >
              {item}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FilterDropDown
