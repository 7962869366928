import React, { useState, useEffect, useContext, useRef } from "react"
import { Rnd } from "react-rnd"
import CommentBox from "./_commentBox"
import CommentList from "./_commentList"
import { UserSocketContext } from "../../../stores/socket/user_socket/userSocketProvider"
import usePaginator from "../../paginator"
import Style from "./style.module.scss"
import ChatWindowHeader from "./_header"
import { useAssessmentMural } from "../../../stores/assessment_mural_store/AssessmentMuralStore"
import { setMuralChatWindowExpanded } from "../../../stores/assessment_mural_store/AssessmentMuralActions"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"

const MuralChatWindow = () => {
  const chatRef = useRef()

  const [defaultChatBoxPosition, setDefaultChatBoxPosition] = useState({
    x: 10,
    y: 10,
  })
  const [defaultChatPosWhenLeftNavCollapsed, setDefaultChatPosWhenLeftNavCollapsed] = useState(null)
  const [defaultChatPosWhenLeftNavExpanded, setDefaultChatPosWhenLeftNavExpanded] = useState(null)

  const [addCommentExpanded, setAddCommentExpanded] = useState(false)
  const [scrollUp, setScrollUp] = useState(false)
  const [state, dispatch] = useAssessmentMural()
  const assessmentId = state.activeAssessmentData?.mural_assessment_id || state.muralAssessment.id
  const [sidebarStore, dispatchSidebarStore] = useContext(ClassroomSidebarContext)

  const { totalCount, items, loading, fetchError, appendItem, fetchNext, removeItem } = usePaginator(
    `/student-assessment-mural/chat/?student_assessment_mural=${assessmentId}&ordering=-sent_at&limit=5`,
    true,
    true
  )
  const [userWSready, userWSval, userWSsend] = useContext(UserSocketContext)
  const topOfComment = useRef()
  const bottomOfComment = useRef()

  //This is for positioning chat box at right bottom of the modal
  useEffect(() => {
    let ele = document.getElementById("muralCanvas")
    if (ele) {
      setDefaultChatBoxPosition({
        x: parseInt(ele.getBoundingClientRect().right) - 270,
        y: parseInt(ele.getBoundingClientRect().bottom) - 80,
      })

      if (!defaultChatPosWhenLeftNavCollapsed) {
        setDefaultChatPosWhenLeftNavCollapsed({ x: parseInt(ele.getBoundingClientRect().right) - 270, y: parseInt(ele.getBoundingClientRect().bottom) - 80 })
      }
      if (!defaultChatPosWhenLeftNavExpanded && sidebarStore.currentNavTab !== "") {
        setDefaultChatPosWhenLeftNavExpanded({
          x: parseInt(ele.getBoundingClientRect().right) - 270 - 320,
          y: parseInt(ele.getBoundingClientRect().bottom) - 80,
        })
      }
    }
  }, [sidebarStore.currentNavTab])

  useEffect(() => {
    if (state.muralChatWindowExpand) {
      let ele = document.getElementById("muralCanvas")
      if (ele) {
        ele.getBoundingClientRect()
        let heightValInPixel = parseInt((60 * document.body.scrollHeight) / 100)
        let chatWidhtInPixel = parseInt((30 * document.body.scrollWidth) / 100)
        if (
          defaultChatBoxPosition.y + heightValInPixel > ele.getBoundingClientRect().height &&
          defaultChatBoxPosition.x + chatWidhtInPixel > ele.getBoundingClientRect().width
        ) {
          setDefaultChatBoxPosition({
            x: ele.getBoundingClientRect().width - chatWidhtInPixel,
            y: ele.getBoundingClientRect().height - heightValInPixel,
          })
        } else if (defaultChatBoxPosition.y + heightValInPixel > ele.getBoundingClientRect().height) {
          setDefaultChatBoxPosition({
            x: chatRef.current.props.position.x,
            y: ele.getBoundingClientRect().height - heightValInPixel,
          })
        } else if (defaultChatBoxPosition.x + chatWidhtInPixel > ele.getBoundingClientRect().width) {
          setDefaultChatBoxPosition({
            x: ele.getBoundingClientRect().width - chatWidhtInPixel,
            y: chatRef.current.props.position.y,
          })
        }
      }
    }
  }, [state.muralChatWindowExpand, defaultChatBoxPosition])

  useEffect(() => {
    if (chatRef.current.props.position?.x !== defaultChatPosWhenLeftNavCollapsed?.x) {
      setDefaultChatBoxPosition({ ...defaultChatPosWhenLeftNavCollapsed })
    }
    if (chatRef.current.props.position?.x !== defaultChatPosWhenLeftNavExpanded?.x && sidebarStore.currentNavTab !== "") {
      setDefaultChatBoxPosition({ ...defaultChatPosWhenLeftNavExpanded })
    }
  }, [sidebarStore.currentNavTab, defaultChatPosWhenLeftNavCollapsed, defaultChatPosWhenLeftNavExpanded])

  useEffect(() => {
    if (userWSval && userWSval?.event_type === "mural-assessment" && userWSval?.data?.student_assessment_mural == assessmentId) {
      if (userWSval?.action === "new-mural-assessment-message") {
        appendItem(userWSval.data)
      } else if (userWSval?.action === "mural-assessment-message-deleted") {
        removeItem((chatMessage) => chatMessage.id !== userWSval?.data?.id)
      }
    }
  }, [userWSval])

  useEffect(() => {
    if (scrollUp) {
      if (topOfComment?.current) {
        topOfComment.current.scrollIntoView({ behavior: "smooth", block: "end" })
        setScrollUp(false)
      }
    } else if (bottomOfComment?.current) {
      bottomOfComment.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [items, addCommentExpanded])

  return (
    <Rnd
      ref={chatRef}
      default={defaultChatBoxPosition}
      position={{ x: defaultChatBoxPosition.x, y: defaultChatBoxPosition.y }}
      onDragStop={(e, d) => {
        setDefaultChatBoxPosition({
          x: d.x,
          y: d.y,
        })
      }}
      bounds="parent"
      enableResizing={false}
      maxWidth={"30vw"}
      maxHeight={"60vh"}
      style={{ display: "flex", margin: "5px", overflow: "hidden" }}
      enableUserSelectHack={false}
    >
      {state.muralChatWindowExpand && (
        <div className={`w-100 bg-42506C br-9px overflow-y-scroll ${addCommentExpanded ? Style.add_comment_expanded : Style.add_comment_minimized}`}>
          <ChatWindowHeader loading={loading} totalCount={totalCount} items={items} setScrollUp={setScrollUp} fetchNext={fetchNext} />
          <div className={`${addCommentExpanded ? Style.margin_bottom_290 : Style.margin_bottom_63} ${Style.margin_top_46px}`}>
            <div ref={topOfComment}></div>
            <CommentList items={items} />
            <div ref={bottomOfComment}></div>
          </div>
          <div className="w-100 bg-42506C br-9px position-fixed bottom-0">
            <CommentBox student_assessment_mural={assessmentId} addCommentExpanded={addCommentExpanded} setAddCommentExpanded={setAddCommentExpanded} />
          </div>
        </div>
      )}
      {!state.muralChatWindowExpand && (
        <div className="bg-42506C br-9px p-10px">
          <span className=" white-text pointer underline" onClick={() => dispatch(setMuralChatWindowExpanded(true))}>
            View Conversations
          </span>
        </div>
      )}
    </Rnd>
  )
}

export default MuralChatWindow
