import axios from "axios"
import refreshToken from "../refreshToken"
import config from "../config"

export const muralGetRequest = async (url, account_id) => {
  try {
    const refreshResponse = await refreshToken(account_id)
    if (refreshResponse && !refreshResponse.msg) {
      let data = await axios
        .request({
          url: url,
          method: "get",
          baseURL: "https://app.mural.co",
          headers: { Authorization: `Bearer ${localStorage.getItem(config.accessTokenKey)}` },
        })
        .then((response) => response)
        .catch((error) => {
          throw "Something went wrong"
        })
      return data
    } else {
      throw "Error : " + refreshResponse.msg
    }
  } catch (error) {
    return { type: "error", message: error }
  }
}

export const muralPostRequest = async (url, payload) => {
  if (await refreshToken()) {
    try {
      let data = await axios
        .request({
          url: url,
          method: "post",
          baseURL: "https://app.mural.co",
          headers: { Authorization: `Bearer ${localStorage.getItem(config.accessTokenKey)}` },
          data: payload,
        })
        .then((response) => response)

      return data
    } catch (error) {
      return { message: `Something went wrong - ${url}` }
    }
  }
}
