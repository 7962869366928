import React, { useState, useEffect, useRef, useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/Dropdown"

import { deleteRequest, getRequest, putRequest } from "../../../services/rest_service"
import { isBrowser } from "../../../services/developer_mode"
import Common from "../../../services/common"
import { getAddResourceButtonText, getFileTypeForDoc } from "../../Sidebar/utils"
import { ResourceNavTabs, ResourcesFetchUrl, DocumentTypes, ViewAllResourcesUrl, ResourceNameForUrl, ResourceModelName } from "./constants"
import { Droppable, Draggable } from "react-beautiful-dnd"
import {
  SET_CURRENT_NAV_TAB,
  SET_SELECTED_RESOURCE_TYPE,
  SET_OPEN_UPLOAD_MODAL,
  SET_REFETCH_RESOURCES,
  SET_ALL_RESOURCES,
  SET_SHOW_POLL_MODAL,
  SET_SHOW_POPQUIZ_MODAL,
  SET_DATA_TO_CREATE_RESOURCE,
  SET_NAV_AND_RESOURCE_TYPE,
} from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { ClassroomSidebarContext } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarStore"
import {
  SET_ACTIVE_ACTIVITY_DATA,
  SHOW_TRIGGERED_RESOURCE,
  SHOW_ACTIVE_ACTIVITY,
  VIEW_MODERATION_RESOURCE,
} from "../../../stores/classroom_store/ClassroomActions"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import Loader from "../../custom/Loader"
import Image from "../../custom/Image"
import Btn from "../../custom/Button"
import ClassRoomPoll from "../../class-room-training/classroom-poll"
import ClassroomPopquiz from "../../class-room-training/classroom-popquiz"
import HorizontalScroll from "../../custom/HorizontalScroll"
import UploadResource from "../../upload-resource"
import ResourceItem from "./resourceItem"
import ResourceSearchBar from "./resourceSearchBar"
import FilterDropDown from "./filterDropDown"

import UploadIcon from "../../../images/svgs/feather-upload-white.svg"
import RightArrow from "../../../images/svgs/arrowdropright.svg"
import AngleDown from "../../../images/svgs/down_arrow_white.svg"
import Upload from "../../../images/svgs/upload.svg"

import StylesB from "../../template-resources/classroom-resource-section.module.scss"
import StylesC from "../../../modularscss/styles.module.scss"
import ClassroomParticipantsSection from "../../template-resources/classroom-participants-section/classroomParticipantsSection"

const getItemStyle = (isDragging, draggableStyle) => ({
  borderRadius: "5px",
  color: "rgb(224, 226, 229)",
  border: "1px solid transparent",
  background: "rgb(48, 60, 84)",
  position: "relative",
  ...draggableStyle,
})
const ITEM_HEIGHT = 50

/**
 * Component for displaying content once left nav is opened.
 * @param {object} props
 * @param {string} props.parentComponent - Parent page from which the component is accessed.
 * @returns {React.ReactElement} SidebarContent component.
 */
const SidebarContent = (props) => {
  const [sidebarState, dispatch] = useContext(ClassroomSidebarContext)
  const [classroomState, classroomDispatch] = useContext(ClassroomContext)

  const [initialState, setinitialState] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(true)
  const [fetchingNextResources, setFetchingNextResources] = useState(false)
  const [createNewPoll, setCreateNewPoll] = useState(true)
  const [createNewPopquiz, setCreateNewPopquiz] = useState(true)
  const [allPublishedResources, setAllPublishedResources] = useState({})

  const [showResourceModal, setShowResourceModal] = useState(false)
  const [docType, setDocType] = useState("All Documents")
  const [uploadFileModalSection, setUploadFileModalSection] = useState("upload_files")

  const PageSize = useRef(10)

  useEffect(() => {
    if (window?.screen?.height > 0) {
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [isBrowser() && window?.screen?.height])

  // This is for opening resource upload modal from outside the sidebar.
  useEffect(() => {
    if (sidebarState.openUploadModal) {
      setShowResourceModal(true)
      dispatch({ type: SET_OPEN_UPLOAD_MODAL, payload: false })
    }
  }, [sidebarState.openUploadModal])

  useEffect(() => {
    if (sidebarState.refetchResources) {
      //setTimeout because some resources takes time in the backend to save
      setTimeout(() => {
        fetchAllResources(true)
        dispatch({ type: SET_REFETCH_RESOURCES, payload: false })
      }, 500)
    }
  }, [sidebarState.refetchResources])

  useEffect(() => {
    if (sidebarState.currentSelectedResourcesType) {
      if (classroomState.openedOverlaySection) {
        fetchAllResources(true)
      }
    }
  }, [classroomState.openedOverlaySection])

  useEffect(() => {
    if (sidebarState.currentSelectedResourcesType) {
      let refetch = searchValue ? true : false
      fetchAllResources(refetch)
      // For quizzes and mtf need to scroll to the right to see the tab headings.
      if (sidebarState.currentSelectedResourcesType === "Quizzes" || sidebarState.currentSelectedResourcesType === "Pop Quizzes") {
        scrollResourcesNavTab("right")
      } else {
        scrollResourcesNavTab("left")
      }
    }
  }, [sidebarState.currentSelectedResourcesType])

  useEffect(() => {
    fetchResources()
  }, [docType])

  useEffect(() => {
    fetchResources()
  }, [searchValue])

  const fetchResources = () => {
    if (sidebarState.currentSelectedResourcesType && !initialState) {
      fetchAllResources(true)
    }
  }

  async function fetchAllResources(refetch = false) {
    // When called from search functionality, resource type will be passed in section parameter since state value won't accessible here in that scenario.
    // Otherwise resource type is taken from state.
    let resource_type = sidebarState.currentSelectedResourcesType
    if (refetch || allResources[resource_type] === undefined) {
      setAllResourcesFetched(false)
      let data_type = getDataTypeForResourceType(resource_type)
      let type = ResourcesFetchUrl[resource_type]

      // When called from search functionality, search value will be passed in search_value parameter since state won't accessible here in that scenario.
      // Otherwise search value is taken from state.
      let search = searchValue ? `&search=${searchValue}` : ""
      let live_class = props.parentComponent === "classroom" ? `&live_class=true&template_id=${classroomState.template_id}` : ""

      let response = await getRequest(`/${type}/?adminsite=true${search}${live_class}${data_type}`)
      if (response.success) {
        // Resources are set in global state to help drag drop in other components.
        let all_resources = { ...sidebarState.allResources }
        all_resources[resource_type] = response.data.results
        dispatch({ type: SET_ALL_RESOURCES, payload: all_resources })
        Common.SetValForID(setAllResourcesNext, resource_type, response.data.next === null ? "" : response.data.next)
        setAllResourcesFetched(true)
        if (initialState) {
          setinitialState(false)
        }
      }
    } else {
      setAllResourcesFetched(true)
    }
  }

  async function fetchNextAllResources(next_url) {
    if (next_url) {
      setFetchingNextResources(true)
      let response = await getRequest(next_url)
      if (response.success) {
        let resource_type = sidebarState.currentSelectedResourcesType
        let all_resources = { ...sidebarState.allResources }
        if (response.data.results.length > 0) {
          all_resources[resource_type] = [...all_resources[resource_type], ...response.data.results]
          dispatch({ type: SET_ALL_RESOURCES, payload: all_resources })
        }
        Common.SetValForID(setAllResourcesNext, resource_type, response.data.next === null ? "" : response.data.next)
        setFetchingNextResources(false)
      }
    }
  }

  const showTriggeredResource = (currentItem, index) => {
    let res
    let all_resources = { ...sidebarState.allResources }
    let resource = all_resources[sidebarState.currentSelectedResourcesType][index].resource[0]
    if (index > -1) {
      let triggered_datam = {}
      triggered_datam.version_id = resource.version_id
      triggered_datam.triggered_time = resource.triggered_time
      triggered_datam.triggered_count = resource.triggered_count
      triggered_datam.resource_data = resource.resource_data
      triggered_datam.resource_type = resource.resource_type
      triggered_datam.id = resource.id
      triggered_datam.resource_id = resource.resource_id
      triggered_datam.sequence = resource.sequence

      res = { resource: triggered_datam, triggered_data: {} }
    } else {
      res = { resource: currentItem, triggered_data: {} }
    }
    if (resource.id === classroomState.activeActivityData?.current_active_activity?.resource?.id) {
      classroomDispatch({ type: SET_ACTIVE_ACTIVITY_DATA, payload: { current_active_activity: res } })
    } else {
      classroomDispatch({ type: VIEW_MODERATION_RESOURCE, payload: { resource: resource } })
    }
    classroomDispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
    dispatch({ type: SET_NAV_AND_RESOURCE_TYPE, payload: { navTab: "", resourceType: "" } })
  }

  const handleSave = async (currentItem, type, value, show_results, index) => {
    if (currentItem) {
      // setLoadingResource({
      //   resource_id: currentItem.id,
      //   loading: true,
      // })
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("template", classroomState.template_id)
      formData.append("is_triggered", value)
      formData.append("resource_triggered", true)
      if (!show_results || currentItem.resource_type === "casestudy" || currentItem.resource_type === "quiz") {
        formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      }
      formData.append("update_version", true)
      formData.append("show_results", show_results)
      if (value || show_results) {
        formData.append("created", new Date().toISOString())

        let all_resources = { ...sidebarState.allResources }
        let resource_type = sidebarState.currentSelectedResourcesType

        if (!show_results) {
          all_resources[resource_type][index].resource[0].is_triggered = "loading"
        } else {
          all_resources[resource_type][index].resource[0].show_results = "loading"
        }
        dispatch({ type: SET_ALL_RESOURCES, payload: all_resources })
      }

      let res = await putRequest(`/resources/${currentItem.id}/`, formData)
      if (res.success) {
        let all_resources = { ...sidebarState.allResources }
        let resource_type = sidebarState.currentSelectedResourcesType

        all_resources[resource_type][index].resource[0].is_triggered = value
        if (!show_results || currentItem.resource_type === "casestudy" || currentItem.resource_type === "quiz") {
          all_resources[resource_type][index].resource[0].end_activity = res.data.end_activity
          all_resources[resource_type][index].resource[0]["triggered_count"] = res.data.triggered_count
          all_resources[resource_type][index].resource[0]["triggered_time"] = res.data.triggered_time
          all_resources[resource_type][index].resource[0]["version_id"] = res.data.version_id
        }
        all_resources[resource_type][index].resource[0].show_results = show_results
        await triggerResource(currentItem.id, all_resources)
      } else {
        //setLoadingResource({})
        classroomDispatch({
          type: SET_ACTIVE_ACTIVITY_DATA,
          payload: { current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session },
        })
        classroomDispatch({ type: SHOW_ACTIVE_ACTIVITY, payload: true })
      }
    } else {
      //setLoadingResource({})
    }
  }

  const triggerResource = async (resourceID, resources) => {
    let res = await getRequest(`/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${classroomState.template_id}`)
    if (res.success) {
      let triggered_datam = {}
      triggered_datam.version_id = res.data.results[0].version_id
      triggered_datam.triggered_time = res.data.results[0].triggered_time
      triggered_datam.triggered_count = res.data.results[0].triggered_count
      triggered_datam.resource_data = res.data.results[0].resource_data
      triggered_datam.resource_type = res.data.results[0].resource_type
      triggered_datam.sequence = res.data.results[0].sequence
      triggered_datam.resource_id = res.data.results[0].resource_id
      triggered_datam.id = resourceID

      dispatch({ type: SET_ALL_RESOURCES, payload: resources })

      let resource = { ...res.data.current_active_activity.resource, ...triggered_datam }
      let currAct = { resource }
      classroomDispatch({
        type: SET_ACTIVE_ACTIVITY_DATA,
        payload: { current_active_activity: currAct, active_breakout_session: res.data.active_breakout_session },
      })
      //setLoadingResource({})
      classroomDispatch({ type: SHOW_TRIGGERED_RESOURCE, payload: true })
      dispatch({ type: SET_NAV_AND_RESOURCE_TYPE, payload: { navTab: "", resourceType: "" } })
    }
  }

  const myListActivityResource = (type, index, resource, showResource = false) => {
    if (props.parentComponent === "classroom") {
      if (showResource) {
        showTriggeredResource(resource, index)
      } else {
        handleSave(resource, "update", true, false, index)
      }
    }
  }
  const deletePublishedResource = (type, index, id) => {
    let all_published_resources = { ...allPublishedResources }
    let published_resource = all_published_resources[type]
    published_resource.splice(index, 1)
    all_published_resources[type] = published_resource
    setAllPublishedResources(all_published_resources)
    deleteResource(id)
  }

  const deleteResource = async (id) => {
    let response = await deleteRequest(`/resources/${id}/`)
  }

  function returnCorrectResults(resType, data) {
    let newData = data
    if (resType === "Documents") {
      let type = getFileTypeForDoc()
      let docTypeStr1 = "",
        lcDocType = docType == "" ? "" : docType.toLowerCase()
      if (lcDocType === "all documents") {
        docTypeStr1 = ""
      } else if (lcDocType === "excel") {
        docTypeStr1 = "xls"
      } else {
        docTypeStr1 = lcDocType
      }
      let fileType = 1
      if (docTypeStr1 !== "") {
        fileType = getFileTypeForDocLocal(docTypeStr1)
      }

      //Only Allow these values in My List of Documents
      newData = data.filter((elem) => {
        if (docTypeStr1 === "") {
          return elem.resource_data?.file_type == 0 ? false : true
        } else {
          return elem.resource_data?.file_type == fileType ? true : false
        }
      })
    } else if (resType === "Images") {
      newData = data.filter((elem) => {
        return elem.resource_data?.file_type == 0 ? true : false
      })
    }
    return newData
  }

  function getFileTypeForDocLocal(docTypeStr) {
    let fileType
    docTypeStr = docTypeStr.toUpperCase()
    switch (docTypeStr) {
      case "PDF":
        fileType = "1"
        break
      case "DOC":
        fileType = "2"
        break
      case "EXCEL":
      case "XLS":
        fileType = "3"
        break
      case "PPT":
        fileType = "4"
        break
      default:
        break
    }
    return fileType
  }

  const getDataTypeForResourceType = (resource_type, list_type = "All") => {
    let data_type = ""
    if (resource_type === "Images") {
      data_type = `&file_type=0`
    } else if (resource_type === "Quizzes") {
      data_type = `&data_type=0`
    } else if (resource_type === "Polls") {
      data_type = `&data_type=1`
    } else if (resource_type === "Pop Quizzes") {
      data_type = `&data_type=2`
    } else if (resource_type === "Documents") {
      data_type = `&file_type=` + getFileTypeForDoc(docType)
    } else if (resource_type === "MTFs" && list_type === "All") {
      data_type = `&ordering=-created_at`
    }
    if (!["Images", "Documents"].includes(resource_type)) {
      data_type = `${data_type}&published=true`
    }
    if (["Checklists", "Case Studies", "MTFs"].includes(resource_type) && list_type === "All") {
      data_type = `${data_type}&limit=${PageSize.current}`
    } else {
      data_type = `${data_type}&paginate_by=${PageSize.current}`
    }
    return data_type
  }

  const handleResize = () => {
    let height = window?.screen?.height ?? 480
    PageSize.current = Math.ceil(height / ITEM_HEIGHT)
  }

  function handleViewAllResources() {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    navigate(ViewAllResourcesUrl[sidebarState.currentSelectedResourcesType])
  }

  function showAddContentButton() {
    let button_text = getAddResourceButtonText(sidebarState.currentSelectedResourcesType)

    return (
      <div className={`d-flex ${StylesB.upload_resource_section}`}>
        <Btn variant="none" onClick={() => handleAddContent()} className={`${StylesB.resource_upload_button} px-1`}>
          <Image src={UploadIcon} alt={`resource upload button icon`} className={`mr-1 ${StylesB.upload_resource_icon}`} />
          {button_text}
        </Btn>
        <Btn variant="none" onClick={() => handleViewAllResources()} className={`${StylesB.resource_upload_button} px-1`}>
          View All resources
          <Image src={RightArrow} alt={`view resources button icon`} className={`ml-1 ${StylesB.view_resource_icon}`} />
        </Btn>
      </div>
    )
  }

  function handleAddContent() {
    // dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    let resource_type = sidebarState.currentSelectedResourcesType
    if (resource_type === "Polls") {
      setCreateNewPoll(true)
      dispatch({ type: SET_SHOW_POLL_MODAL, payload: true })
    } else if (resource_type === "Pop Quizzes") {
      dispatch({ type: SET_SHOW_POPQUIZ_MODAL, payload: true })
      setCreateNewPopquiz(true)
    } else if (resource_type === "Links") {
      setShowResourceModal(true)
      setUploadFileModalSection("upload_links")
    } else if (resource_type === "Videos") {
      setShowResourceModal(true)
      setUploadFileModalSection("upload_files")
    } else if (resource_type === "Documents" || resource_type === "Images") {
      setShowResourceModal(true)
    } else {
      navigate(`/app/${ResourceNameForUrl[resource_type]}/create`)
    }
    // dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "" })
  }

  const showDocumentTypeDropdown = () => {
    return (
      <Dropdown className={`${StylesB.document_type_dropdown} breakout-groups pr-3 pb-2`}>
        <Dropdown.Toggle className={`${StylesB.document_type_toggle} border-none d-inline-flex align-items-center p-0 shadow-none`} id="dropdown-basic">
          <div className={`mr-1`}>{docType}</div>
          <Image src={AngleDown} className={`${StylesB.toggle_icon} w-10px`} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${StylesB.document_type_dropdown_menu} br-9px breakout-groups`}>
          {DocumentTypes.map((doc_type) => {
            return (
              <Dropdown.Item
                key={doc_type}
                className={`${StylesB.document_type_dropdown_item}`}
                onClick={() => {
                  setDocType(doc_type)
                }}
              >
                {doc_type}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const showSeeMore = (next_url, resource_type) => {
    let loader_type = fetchingNextResources
    if (next_url) {
      return (
        <div className="text-center">
          {loader_type ? (
            <Loader />
          ) : (
            <div onClick={() => fetchNextAllResources(next_url)} className={`${StylesC.font_12} ${StylesB.resources_see_more} pointer pb-2`}>
              See More
            </div>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  function showResources(resources, next_url, resource_type = "All") {
    let is_published_resource = ["Quizzes", "MTFs", "Polls", "Pop Quizzes", "Checklists", "Case Studies"].includes(sidebarState.currentSelectedResourcesType)
    if (resources.length > 0) {
      return (
        <React.Fragment>
          {resources.map((item, index) => {
            let not_published = false
            if (is_published_resource && (item?.is_published === false || item?.resource_data?.is_published === false)) {
              not_published = true
            }
            return (
              <Draggable
                key={item.id + `_resource_name_` + index}
                draggableId={item.id.toString() + `-resource` + "_" + index.toString()}
                isDragDisabled={
                  ["BreakoutSession", "Resource", "BreakoutGroup", "ActiveBreakoutSession", "QuestionAnswer", "MuralAssessment"].includes(
                    classroomState.openedOverlaySection
                  )
                    ? false
                    : true
                }
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    className={`${not_published ? StylesB.not_published_highlight : ""} ${
                      item?.resource?.length > 0 && item?.resource[0]?.id === classroomState.activeActivityData?.current_active_activity?.resource?.id
                        ? StylesB.poll_live_green_border
                        : ""
                    }
                ${
                  props.parentComponent === "classroom" && item?.resource?.length > 0 && item.resource[0]?.triggered_count > 0
                    ? StylesB.triggered_item_green_border
                    : StylesB.border_on_hover
                } ${props.parentComponent && index === 0 ? "mt-2" : ""} mb-2 d-flex align-items-center pointer`}
                  >
                    {props.parentComponent === "classroom" &&
                    item?.resource?.length > 0 &&
                    item.resource[0]?.id === classroomState.activeActivityData?.current_active_activity?.resource?.id ? (
                      <div className={`mr-2 w-15px h-15px rounded-circle bg-46C99E position-absolute left--8px top--8px`}></div>
                    ) : (
                      ""
                    )}
                    <ResourceItem
                      item={item}
                      index={index}
                      parentComponent={props.parentComponent}
                      list_type={resource_type}
                      myListActivityResource={myListActivityResource}
                    />
                  </div>
                )}
              </Draggable>
            )
          })}
          {showSeeMore(next_url, resource_type)}
        </React.Fragment>
      )
    } else {
      return <p className="text-center fs-12px">No resource to show!</p>
    }
  }

  function showResourcesSection(type = "resource") {
    let resource_type = sidebarState.currentSelectedResourcesType
    let all_resources = sidebarState.allResources[resource_type] ? sidebarState.allResources[resource_type] : []
    let next_resource_url = allResourcesNext[resource_type]
    let button_text = getAddResourceButtonText(resource_type)
    if (allResourcesFetched) {
      if (all_resources.length === 0 && searchValue) {
        let no_content_message = searchValue ? "No resource found for searched keyword!" : "No content has been uploaded yet!"
        return (
          <Col lg="12" md="12" sm="12" xs="12" className={`${StylesB.no_content_message_section} text-center mt-4`}>
            <p className={`${StylesB.no_content_message}`}>{no_content_message}</p>
            <p>Everything you upload can be accessed from here. Drag and drop to add it your courses.</p>
            <Btn
              className={`${StylesB.upload_button} px-4`}
              onClick={() => {
                handleAddContent()
              }}
            >
              <Image className="mr-2 w-16px h-16px mb-1" src={Upload} />
              {button_text}
            </Btn>
            {["Videos", "Blogs", "Images", "Documents"].includes(resource_type) ? (
              <p className={`${StylesB.upload_message} pt-2`}>Upload videos. Images, Documents, blogs, etc</p>
            ) : null}
          </Col>
        )
      } else {
        return (
          <React.Fragment>
            <Droppable droppableId={type + `_` + resource_type} type={`resourceDraggable`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {showResources(all_resources, next_resource_url, "All")}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </React.Fragment>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Loader />
        </Col>
      )
    }
  }

  const scrollResourcesNavTab = (type) => {
    let element = document.getElementById("br-session-scrollable-resources-nav-tabs")
    if (element !== null) {
      let scroll_amount = 0
      if (type === "right") {
        scroll_amount = element.scrollWidth
      }
      element.scrollTo({ top: 0, left: scroll_amount, behavior: "smooth" })
    }
  }

  const showResourceTopNav = () => {
    return ResourceNavTabs[sidebarState.currentNavTab].map((tab_name, index) => {
      if (sidebarState.disabledResources.includes(tab_name)) {
        return
      }
      return (
        <div
          key={index.toString()}
          className={`pointer ${sidebarState.currentSelectedResourcesType === tab_name ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
          onClick={() => {
            dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: tab_name })
          }}
        >
          <p>{tab_name}</p>
        </div>
      )
    })
  }

  const setLoaderforSearch = () => {
    setAllResourcesFetched(false)
  }

  const showSidebarContent = () => {
    if (sidebarState.currentNavTab === "LessonSessionPlan") {
      return (
        <div className="text-center mt-5">
          <div
            className="m-2"
            onClick={() => {
              dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
            }}
          >
            <a
              href={`/app/trainings/training-content/${classroomState.training_id}`}
              target={props.parentComponent == "classroom" ? `courseContent${classroomState.training_id}` : "_self"}
              className={`white-text ${StylesB.class_content_link}`}
            >
              Class Content
            </a>
          </div>
        </div>
      )
    } else if (["Resource", "Poll"].includes(sidebarState.currentNavTab)) {
      return (
        <React.Fragment>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 pb-5">
            <div className="p-3">
              <ResourceSearchBar setLoaderforSearch={setLoaderforSearch} setSearchValueInParent={setSearchValue} />
            </div>
            <HorizontalScroll scrollId="resources-nav-tabs" className={`${StylesB.resource_nav_section}`}>
              {showResourceTopNav()}
            </HorizontalScroll>
            <div className={`${StylesB.resource_item_section}`}>
              {sidebarState.currentSelectedResourcesType === "Documents" ? (
                <FilterDropDown value={docType} listItems={DocumentTypes} onClick={setDocType} />
              ) : (
                ""
              )}
              {showResourcesSection()}
            </div>
          </Col>
          {showAddContentButton()}
        </React.Fragment>
      )
    } else if (sidebarState.currentNavTab === "Participants") {
      return <ClassroomParticipantsSection setParticipantData={props.setParticipantData} participantData={props?.participantData} />
    } else {
      return null
    }
  }

  // To refetch resources after user uploads and closes the modal.
  const handleUploadResourceModalClose = () => {
    if (sidebarState.currentSelectedResourcesType) {
      fetchAllResources(true)
    }
    setShowResourceModal(false)
  }

  const handlePollClose = () => {
    dispatch({ type: SET_SHOW_POLL_MODAL, payload: false })
    dispatch({ type: SET_DATA_TO_CREATE_RESOURCE, payload: null })
  }

  const handlePopquizClose = () => {
    dispatch({ type: SET_SHOW_POPQUIZ_MODAL, payload: false })
    dispatch({ type: SET_DATA_TO_CREATE_RESOURCE, payload: null })
  }

  return (
    <React.Fragment>
      {sidebarState.showPollModal ? (
        <ClassRoomPoll
          path="dashboard"
          create_new_poll={createNewPoll}
          reset_create_poll={() => setCreateNewPoll(false)}
          live_classroom={false}
          poll={""}
          showPollResult={false}
          fetchAllResources={(arg) => {
            fetchAllResources(arg)
          }}
          closeModal={() => handlePollClose()}
          show={sidebarState.showPollModal}
          dataToCreateResource={sidebarState.dataToCreateResource}
        />
      ) : null}
      {sidebarState.showPopquizModal ? (
        <ClassroomPopquiz
          path="dashboard"
          create_new_popquiz={createNewPopquiz}
          reset_create_popquiz={() => setCreateNewPopquiz(false)}
          live_classroom={false}
          popquiz={""}
          showPopquizResult={false}
          show={sidebarState.showPopquizModal}
          closeModal={() => handlePopquizClose()}
          fetchAllResources={(arg) => {
            fetchAllResources(arg)
          }}
          dataToCreateResource={sidebarState.dataToCreateResource}
        />
      ) : null}
      {showResourceModal ? (
        <UploadResource show={showResourceModal} closeModal={() => handleUploadResourceModalClose()} showSection={uploadFileModalSection} />
      ) : null}
      {showSidebarContent()}
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  // To identity from which page this component is accessed.
  parentComponent: PropTypes.string.isRequired,
}

export default SidebarContent
