import React, { useState, useRef, useEffect, useCallback } from "react"
import _ from "lodash"

import Loader from "../custom/Loader"

import Dropdown from "react-bootstrap/Dropdown"
import Styles from "../../modularscss/styles.module.scss"
import AcivityWorkSpaceStyle from "./acitivity-workspace.module.scss"
import SearchIcon from "../../images/svgs/search.svg"
import muralIcon from "../../images/svgs/mural-icon.svg"
import editSvg from "../../images/svgs/edit.svg"
import linkIcon from "../../images/svgs/link.svg"
import checkMark from "../../images/svgs/check-white.svg"

import MuralAuthWarning from "../mural/mural-warning-popup"
import { putRequest } from "../../services/rest_service"
import config from "../../components/mural/config"

import validateMuralUrl from "../../components/mural/validateMuralUrl"
import { copyToClipboard } from "../../services/common"
import muralWidgestData from "../mural/muralWidgestFetching"

const ActivityWokSpace = (props) => {
  const searchInputRef = useRef(null)
  const [showSelectExisting, setShowSelectExisting] = useState(false)
  const [showAddNewActivityWorkSpace, setAddNewActivityWorkSpace] = useState(false)
  const [showExistingActivityWorkSpace, setShowExistingActivityWorkSpace] = useState(false)

  const [showMuralAuthWarning, setShowMuralAuthWarning] = useState(false)
  const [muralWidgest, setMuralWidgest] = useState([])
  const [filteredMuralWidgets, setFilteredMuralWidgets] = useState([])
  const [isCopied, setIsCopied] = useState(null)
  const delayedQuery = useCallback(
    _.debounce((value, type) => {
      if (type === "add_activity_workspace") {
        setActivityWorkSpaceData({ id: null, link: value, title: "" })
      } else if (type === "filter_activity") {
        if (validateMuralUrl(value) && muralWidgest?.length < 1) {
          getMuralData(value)
        } else {
          setLoader(false)
          let result = muralWidgest.filter((o) => o.title.toLowerCase().includes(value.toLowerCase()))
          setFilteredMuralWidgets(result)
        }
      }
    }, 1000),
    [muralWidgest]
  )

  const [loader, setLoader] = useState(false)

  const existingRef = useRef()
  const addNewRef = useRef()
  const showExistingRef = useRef()

  const handleClickOutside = (e) => {
    if (!existingRef?.current?.contains(e.target)) {
      setShowSelectExisting(false)
    }
    if (!addNewRef?.current?.contains(e.target)) {
      setAddNewActivityWorkSpace(false)
    }
    if (!showExistingRef?.current?.contains(e.target)) {
      setShowExistingActivityWorkSpace(false)
    }
  }

  const getMuralData = async (value) => {
    const tempMuralWidgest = []
    let muralId = null
    muralId = value.split("/").length > 6 ? `${value.split("/")[6]}.${value.split("/")[7]}` : null
    if (validateMuralUrl(value) && muralId) {
      try {
        let data = await muralWidgestData(muralId)
        if (data?.length > 0) {
          data.forEach((element) => {
            if (element.title && element?.presentationIndex > -1) {
              tempMuralWidgest.push({
                id: element.id,
                link: value.includes("?") ? `${value}&wid=${element.id}` : `${value}?wid=${element.id}`,
                title: element.title,
              })
            }
          })
        }
        if (tempMuralWidgest.length > 0) {
          setMuralWidgest(tempMuralWidgest)
          setFilteredMuralWidgets(tempMuralWidgest)
          searchInputRef.current.value = null
        }
        setLoader(false)
      } catch (error) {
        setLoader(true)
      }
    }
  }

  const checkValidateMuralUser = async () => {
    setShowSelectExisting(true)
    if (!localStorage.getItem(config.accessTokenKey)) {
      setShowMuralAuthWarning(true)
      return
    }
    let widgestData = []
    props.breakoutGroupList.forEach((element, index) => {
      if (index === 0) {
        Object.keys(element.workspace_widgets).forEach((item) => {
          widgestData.push({
            id: element.workspace_widgets[item],
            link: element.workspace_link,
            title: item,
          })
        })
      }

      if (Object.keys(element.workspace_widgets).length > 0 && index === 0) {
        setMuralWidgest(widgestData)
        setFilteredMuralWidgets(widgestData)
      }
    })
    setAddNewActivityWorkSpace(false)
    setShowExistingActivityWorkSpace(false)
  }

  const viewOrCopyAcivity = async (item, index) => {
    const newItems = [...filteredMuralWidgets]
    newItems[index]["show"] = true
    setFilteredMuralWidgets(newItems)
    var url = new URL(item.link)
    url.searchParams.append("wid", item.id)

    try {
      const toCopy = url.href
      await navigator.clipboard.writeText(toCopy)
      setTimeout(() => {
        const newItems = [...filteredMuralWidgets]
        newItems[index]["show"] = false
        setFilteredMuralWidgets(newItems)
      }, 3000)
    } catch (err) {}
  }

  async function copyTextToClipboard(e, item, index) {
    e.stopPropagation()
    let url = new URL(item.link)
    url.searchParams.append("wid", item.id)
    const text = url.href

    copyToClipboard(e, text, () => {
      setIsCopied(index)
      setTimeout(() => {
        setIsCopied(null)
      }, 1500)
    })
  }

  useEffect(() => {
    if (document.getElementById(`select-existing-${props.activityId}`)) {
      document.getElementById(`select-existing-${props.activityId}`).scrollIntoView({ behavior: "smooth", block: "end" })
    }
  })

  const setActivityWorkSpaceData = async (item) => {
    if (!loader) {
      setLoader(true)
    }
    let data = await putRequest(`/breakout-activity/${props.activityId}/`, {
      activity_workspace: item,
      breakout_session: props.sessionId,
    })
    if (data.success) {
      setLoader(false)
      if (props.sessionId) {
        let dummy = { ...props.modulesData }
        dummy[`module_` + props.sessionId].topics.forEach((element) => {
          if (element.id === props.activityId) {
            element.activity_workspace = item
          }
        })
        props.setModulesData(dummy)
      }
      setAddNewActivityWorkSpace(false)
      setShowSelectExisting(false)
      setShowExistingActivityWorkSpace(false)
      props?.sendWS && props.sendWS(item, props.sessionId, props.activityId)
    } else {
      setLoader(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  })

  return (
    <React.Fragment>
      <div style={{ position: "relative", marginLeft: "auto" }}>
        {props?.activity_workspace?.link ? (
          <div
            className={`border-0 px-1 py-1 d-inline-flex align-items-center ${AcivityWorkSpaceStyle.btn_focus} ${AcivityWorkSpaceStyle.existing_activity_button}`}
            style={{ fontSize: "14px", backgroundColor: "transparent", marginLeft: "auto", marginRight: 0 }}
            onClick={(e) => {
              setAddNewActivityWorkSpace(false)
              setShowSelectExisting(false)
              setShowExistingActivityWorkSpace(true)
            }}
          >
            <img
              src={props?.activity_workspace?.link?.includes("app.mural") ? muralIcon : linkIcon}
              alt={`mural button`}
              className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
            />

            <span style={{ padding: "0 7px" }}>{"Activity Workspace"}</span>

            <div style={{ whiteSpace: "nowrap", overflow: "hidden", opacity: "0.6", width: props?.activity_workspace?.title ? "240px" : "214px" }}>
              {props?.activity_workspace?.title ? props?.activity_workspace?.title : props?.activity_workspace?.link}
            </div>
            <img
              alt="edit"
              src={editSvg}
              className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon}`}
              onClick={(e) => {
                setAddNewActivityWorkSpace(false)
                setShowSelectExisting(false)
                setShowExistingActivityWorkSpace(true)
              }}
            />
          </div>
        ) : (
          ""
        )}

        {!props?.activity_workspace?.link ? (
          <div
            className={`border-0 px-1 py-1 d-inline-flex align-items-center ${AcivityWorkSpaceStyle.btn_focus} ${!props?.isPermitted && "disable-content"}`}
            style={{ fontSize: "14px", backgroundColor: "transparent", marginLeft: "auto", marginRight: 0 }}
          >
            <div
              className={`${AcivityWorkSpaceStyle.activity_workspace_title} ${AcivityWorkSpaceStyle.activity_workspace_title_active}`}
              onClick={(e) => {
                checkValidateMuralUser()
              }}
            >
              Select from existing workspace
            </div>
            <span className={`${AcivityWorkSpaceStyle.activity_workspace_or_title}`}>Or</span>
            <div
              className={`${AcivityWorkSpaceStyle.activity_workspace_title}`}
              onClick={(e) => {
                setAddNewActivityWorkSpace(true)
                setShowSelectExisting(false)
                setShowExistingActivityWorkSpace(false)
              }}
            >
              Add Activity Workspace
            </div>
          </div>
        ) : (
          ""
        )}

        <React.Fragment>
          {showSelectExisting ? (
            <Dropdown.Menu
              id={`select-existing-${props.activityId}`}
              ref={existingRef}
              style={{ marginRight: 0 }}
              show={showSelectExisting}
              className={`py-0 ${Styles.font_12} ${AcivityWorkSpaceStyle.activity_workspace_dropdown} ${
                AcivityWorkSpaceStyle.activity_workspace_dropdown_height
              } 
              ${showSelectExisting ? AcivityWorkSpaceStyle.active_dropdown : ""}`}
            >
              <div className={`col-12 d-inline-flex py-1`}>
                {loader ? (
                  <Loader />
                ) : (
                  <img src={SearchIcon} alt={`search button`} className={`my-auto`} style={{ objectFit: `contain`, width: `16px`, height: `16px` }} />
                )}

                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    setLoader(true)
                    e.preventDefault()
                    delayedQuery(e.target.value, "filter_activity")
                  }}
                  placeholder={"Search Workspace activity Outline"}
                  className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                  required
                  ref={searchInputRef}
                />

                <div
                  className={`flex-shrink-0 ${AcivityWorkSpaceStyle.activity_workspace_title}`}
                  onClick={(e) => {
                    setAddNewActivityWorkSpace(true)
                    setShowSelectExisting(false)
                  }}
                >
                  Add Activity Workspace
                </div>
              </div>

              {filteredMuralWidgets.length < 1 && !loader ? (
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>Data Not Available</div>
              ) : (
                ""
              )}
              {filteredMuralWidgets.map((item, index) => {
                return item.title ? (
                  <React.Fragment key={`${props.activityId}-menu-${index}`}>
                    <div className={`col-12 ${AcivityWorkSpaceStyle.activity_menu_item}`}>
                      <div className="col-12 d-inline-flex">
                        <img src={muralIcon} alt={`mural button`} className={`my-auto`} style={{ objectFit: `contain`, width: `25px`, height: `25px` }} />

                        <span
                          className={`${AcivityWorkSpaceStyle.activity_menu_item_title}`}
                          onClick={(event) => {
                            setShowSelectExisting(false)
                            setActivityWorkSpaceData(item)
                          }}
                        >
                          {item.title}
                        </span>
                        <span
                          className={`${AcivityWorkSpaceStyle.activity_workspace_title} ${AcivityWorkSpaceStyle.align_right_end}`}
                          onClick={(event) => {
                            copyTextToClipboard(event, item, index)
                          }}
                        >
                          {isCopied === index ? "Copied" : "Copy Link"}
                        </span>
                      </div>

                      {item.show ? (
                        <React.Fragment>
                          <div
                            className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.selected_menu_link} ${AcivityWorkSpaceStyle.view_copy_link}`}
                            key={`widgest-${index}`}
                          >
                            {item.link}
                          </div>
                          <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.copy_link_message}`} key={`widgest-copy-message-${index}`}>
                            <img
                              className={`mb-0 ${AcivityWorkSpaceStyle.activity_workspace_edit_icon} ${AcivityWorkSpaceStyle.align_right_end}`}
                              src={checkMark}
                              alt={`Copy Icon`}
                              style={{ objectFit: `contain`, width: `20px`, height: `20px` }}
                            />
                            <span className={`${AcivityWorkSpaceStyle.copy_link_message_text}`}>Link copied</span>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className={`${AcivityWorkSpaceStyle.activity_menu_devider}`} />
                  </React.Fragment>
                ) : (
                  ""
                )
              })}
            </Dropdown.Menu>
          ) : (
            ""
          )}
          {showAddNewActivityWorkSpace ? (
            <Dropdown.Menu
              id={`new-${props.activityId}`}
              ref={addNewRef}
              style={{ marginRight: 0 }}
              align="end"
              show={showAddNewActivityWorkSpace}
              className={`py-0 ${Styles.font_12} ${AcivityWorkSpaceStyle.activity_workspace_dropdown} 
              ${showAddNewActivityWorkSpace ? AcivityWorkSpaceStyle.active_dropdown : ""}`}
            >
              <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item}`}>
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    setLoader(true)
                    e.preventDefault()
                    delayedQuery(e.target.value, "add_activity_workspace")
                  }}
                  placeholder={"Add activity workspace link (optional)"}
                  className={`${AcivityWorkSpaceStyle.activity_search_input} form-control border-0`}
                  required
                />
                {loader ? <Loader /> : ""}
              </div>
            </Dropdown.Menu>
          ) : (
            ""
          )}

          {showExistingActivityWorkSpace ? (
            <Dropdown.Menu
              id={`existing-${props.activityId}`}
              ref={showExistingRef}
              align="end"
              show={showExistingActivityWorkSpace}
              className={`py-0 ${Styles.font_12} ${AcivityWorkSpaceStyle.activity_workspace_dropdown} 
              ${showExistingActivityWorkSpace ? AcivityWorkSpaceStyle.active_dropdown : ""}`}
            >
              <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.selected_activity_workspace}`}>
                <img
                  src={props?.activity_workspace?.link?.includes("app.mural") ? muralIcon : linkIcon}
                  alt={`mural button`}
                  className={`my-auto ${AcivityWorkSpaceStyle.existing_activity_button_icon}`}
                />
                <span className={`ml-auto flex-shrink-0 pl-2 d-flex align-items-center ml-1 mr-1`}>{props?.activity_workspace?.title}</span>
                <span className={`d-flex align-items-center m-1 border-0 ellipsis`}>{props?.activity_workspace?.link}</span>
                <span
                  className={`underline pointer ml-auto flex-shrink-0 pl-2 d-flex align-items-center`}
                  onClick={(event) => {
                    copyTextToClipboard(event, props?.activity_workspace, -1)
                  }}
                >
                  {isCopied === -1 ? "Copied" : "Copy Link"}
                </span>
              </div>
              {props?.isPermitted && (
                <div className={`col-12 d-inline-flex ${AcivityWorkSpaceStyle.activity_menu_item} ${AcivityWorkSpaceStyle.activity_last_menu}`}>
                  <span
                    className={`${AcivityWorkSpaceStyle.activity_workspace_title}`}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setLoader(true)
                      setActivityWorkSpaceData({})
                    }}
                  >
                    Remove Link
                  </span>
                  {loader ? <Loader /> : ""}

                  <span
                    className={`${AcivityWorkSpaceStyle.activity_workspace_title} ${AcivityWorkSpaceStyle.align_right_end}`}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      checkValidateMuralUser()
                    }}
                  >
                    Select from existing workspace
                  </span>
                </div>
              )}
            </Dropdown.Menu>
          ) : (
            ""
          )}
        </React.Fragment>

        <MuralAuthWarning
          showMuralAuthWarning={showMuralAuthWarning}
          setShowMuralAuthWarning={setShowMuralAuthWarning}
          defaultOpenAfterRedirect={"BreakoutSession"}
        />
      </div>
    </React.Fragment>
  )
}

export default ActivityWokSpace
