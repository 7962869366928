export const LeftNavItems = [
  "LessonSessionPlan",
  "Resource",
  "BreakoutGroup",
  "BreakoutSession",
  "Poll",
  "DiscussionBoard",
  "Participants",
  "Break",
  "Report An Issue",
]

export const ResourceNavTabs = {
  Resource: ["Videos", "Blogs", "Documents", "Images", "Links"],
  Poll: ["Case Studies", "Checklists", "MTFs", "Polls", "Pop Quizzes", "Quizzes"],
}

export const ResourcesFetchUrl = {
  Videos: "basic-videos-list",
  Blogs: "basic-blogs-list",
  Documents: "basic-images-list",
  Images: "basic-images-list",
  Links: "basic-links-list",
  Quizzes: "basic-quizzes-list",
  MTFs: "match-the-followings",
  Polls: "basic-quizzes-list",
  "Pop Quizzes": "basic-quizzes-list",
  Checklists: "checklist",
  "Case Studies": "case-study",
}

export const ViewAllResourcesUrl = {
  Videos: "/app/resource-dashboard/",
  Blogs: "/app/resource-dashboard/",
  Documents: "/app/resource-dashboard/",
  Images: "/app/resource-dashboard/",
  Links: "/app/resource-dashboard/",
  Quizzes: "/app/quizzes/",
  MTFs: "/app/match-the-following/",
  Polls: "/app/polls/",
  "Pop Quizzes": "/app/pop-quizzes/",
  Checklists: "/app/checklists/",
  "Case Studies": "/app/case-study/",
}

export const PublishResourcesUrl = {
  Quizzes: "/app/quizzes/{id}/publish",
  MTFs: "/app/match-the-following/edit/{id}/",
  Polls: "/app/polls/{id}/edit/",
  "Pop Quizzes": "/app/pop-quizzes/{id}/edit/",
  Checklists: "/app/checklist/edit/{id}/",
  "Case Studies": "/app/case-study/edit/{id}/",
}

export const ResourceNameForUrl = {
  Videos: "videos",
  Blogs: "blogs",
  Documents: "files",
  Images: "files",
  Links: "links",
  Quizzes: "quizzes",
  MTFs: "match-the-following",
  Polls: "polls",
  "Pop Quizzes": "pop-quizzes",
  Checklists: "checklist",
  "Case Studies": "case-study",
}
export const ResourceModelName = {
  Videos: "video",
  Blogs: "blog",
  Documents: "turientimage",
  Images: "turientimage",
  Links: "link",
  Quizzes: "quiz",
  MTFs: "matchthefollowing",
  Polls: "quiz",
  "Pop Quizzes": "quiz",
  Checklists: "checklist",
  "Case Studies": "casestudy",
}
export const DocumentTypes = ["All Documents", "PDF", "DOCS", "EXCEL", "PPT"]
